import React from 'react'
import { Col, Typography, Space, Divider } from 'antd'

const { Title, Text } = Typography

export default function Language(props) {
  const { data } = props
  return (
    <React.Fragment>
      <Col span={24}>
        <Space direction='vertical' size={16} style={{ width: '100%', textAlign: 'justify', textJustify: 'inter-word' }}>
          <Space direction='vertical' size={0} style={{ width: '100%' }}>
            <Title level={4} style={{ margin: 0 }}>LANGUAGE</Title>
            <Divider style={{ margin: 0, borderTop: '1px solid black' }} />
          </Space>
        </Space>
      </Col>
      {
        data.listLanguage.length > 0 ?
          <Col span={24}>
            <Space direction='vertical' size={0} style={{ width: '100%', textAlign: 'justify', textJustify: 'inter-word' }}>
              <Text>
                ● Native Language: {data.listLanguage.find(item => item.isNative === true) ? data.listLanguage.find(item => item.isNative === true).name : 'N/A'}
              </Text>
              <Text>
                ● Other Language: {
                  data.listLanguage.filter(val => val.isNative === false).length > 0 ?
                    data.listLanguage.filter(val => val.isNative === false).map(item => item.name).toString().replace(/,/g, ', ')
                  : 
                    'N/A'
              }
              </Text>
            </Space>
          </Col>
        :
          <Col span={24}>
            <Text>N/A</Text>
          </Col>
      }
    </React.Fragment>
  )
}
