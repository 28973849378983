import React, { Component } from 'react';
import update from 'react-addons-update';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Loading } from '../../../../components';
import { createClient } from '../../../../redux/actions/client/clientAction';
import { BankOutlined, UploadOutlined, DeleteOutlined, CloseOutlined } from '@ant-design/icons';
import { cityByProvince } from '../../../../redux/actions/master/cities/citiesAction';
import { provinceByCountry } from '../../../../redux/actions/master/provinces/provincesAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/master/countries/countriesAction';
import { masterJobIndustries, unmountMasterJobIndustries } from '../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { Card, Row, Col, PageHeader, Form, Input, Select, Upload, Button, Space, Breadcrumb, Image, Typography, message } from 'antd';
import { bytesToSize } from '../../../../utils/convertBytes';
import IconVideo from '../../../../assets/img/icon-video.png';
import CButton from '../../../../components/Button';
const { Dragger } = Upload;
const { Text } = Typography;

class CreateClient extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      submitLoading: false,
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null,
      callingCode: "62",
      dataProvince: [],
      dataCity: [],
      fileSlider: [],
      fileImage: [],
      fileVideo: []
    }
  }

  componentDidMount(){
    const { actionGetJobIndustries, actionGetCountry, actionGetProvince } = this.props;
    actionGetProvince(99, (response) => {
      this.setState({ dataProvince: response.data })
    })
    actionGetJobIndustries()
    actionGetCountry()
  }

  handleChange = (value, fileUrl) => {
    const values = {
      file: value,
      isCover: this.state.avatar.length === 0 ? true : false,
      fileUrl: fileUrl
    }
    return this.setState({
      avatar: update(this.state.avatar, {
        $push: [values]
      })
    })
  }

  handleChangeCallingCode = (_, index) => {
    this.setState({ callingCode: index.children.replace(/^[+]+/, '') })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    });
  }

  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
      cityId: null,
    });
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null
    });
    if(value !== undefined){
      return actionGetCity(value, (response) => {
        this.setState({ dataCity: response.data })
      })
    }else {
      this.setState({ dataCity: [] })
    }
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleUploadSlider(){
    const { fileSlider } = this.state
    return {
      withCredentials: true,
      accept: ".jpg, .jpeg, .png",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
        }else{
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            this.getBase64(file, (url) => {
              fileSlider.push(
                {
                  uid: file.uid,
                  name: file.name,
                  date: moment().format('llll'),
                  size: file.size,
                  url: url,
                  file: file
                }
              )
              this.setState({ fileSlider })
            })
            message.info('Upload Success!')
          }
        }
      }
    }
  }

  handleRemoveSlider = (e) => {
    const { fileSlider } = this.state
    fileSlider.forEach((item,i) => {
      if(item?.uid === e.uid){
        fileSlider.splice(i, 1);
      }
    })
    this.setState({ fileSlider })
  }

  handleUploadImage(){
    const { fileImage } = this.state
    return {
      withCredentials: true,
      accept: ".jpg, .jpeg, .png",
      beforeUpload: file => {
        const validateSize = file.size >= 1000000;
        if (validateSize) {
          message.error('Max file size is 1 MB!');
        }else{
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            this.getBase64(file, (url) => {
              fileImage.push(
                {
                  uid: file.uid,
                  name: file.name,
                  date: moment().format('llll'),
                  size: file.size,
                  url: url,
                  file: file
                }
              )
              this.setState({ fileImage })
            })
            message.info('Upload Success!')
          }
        }
      }
    }
  }

  handleRemoveImage = (e) => {
    const { fileImage } = this.state
    fileImage.forEach((item,i) => {
      if(item?.uid === e.uid){
        fileImage.splice(i, 1);
      }
    })
    this.setState({ fileImage })
  }

  handleUploadVideo(){
    const { fileVideo } = this.state
    return {
      withCredentials: true,
      accept: ".mp4",
      beforeUpload: file => {
        const validateSize = file.size >= 30000000;
        if (validateSize) {
          message.error('Max file size is 30 MB!');
        }else{
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            this.getBase64(file, (url) => {
              fileVideo.push(
                {
                  uid: file.uid,
                  name: file.name,
                  date: moment().format('llll'),
                  size: file.size,
                  url: url,
                  file: file
                }
              )
              this.setState({ fileVideo })
            })
            message.info('Upload Success!')
          }
        }
      }
    }
  }

  handleRemoveVideo = (e) => {
    const { fileVideo } = this.state
    fileVideo.forEach((item,i) => {
      if(item?.uid === e.uid){
        fileVideo.splice(i, 1);
      }
    })
    this.setState({ fileVideo })
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    const { callingCode, editAvatar, avatar, fileSlider, fileImage, fileVideo } = this.state;
    values.mobilePhone = `${callingCode}${values.mobilePhone.replace(/^0+/, '')}`
    values.jobIndustryId = values.jobIndustryId ? values.jobIndustryId : ''
    values.provinceId = values.provinceId ? values.provinceId : ''
    values.cityId = values.cityId ? values.cityId : ''
    values.fileSlider = fileSlider
    values.fileImage = fileImage
    values.fileVideo = fileVideo
    if(editAvatar){
      values.logoFile = avatar
    }
    this.setState({ submitLoading: true })
    return actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/agency/clients')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { getJobIndustries, getCountry } = this.props;
    const { submitLoading, editAvatar, onPreviewAvatar, dataProvince, dataCity, fileSlider, fileImage, fileVideo } = this.state;

    if(getJobIndustries.loading || getCountry.loading){
      return <Loading />
    }
    
    const mobileNumber = (
      <Form.Item 
        noStyle
        name="callingCodeId" 
        initialValue={99} 
        rules={[ {required: true, message: 'Please input your calling code'} ]}
      >
        <Select 
          showSearch
          className="prefix-selector"
          optionFilterProp="children"
          onChange={this.handleChangeCallingCode}
        >
          {
            getCountry?.data.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {`+${item.callingCode}`}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>
    );

    return (
      <React.Fragment>
        <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><BankOutlined /> Clients</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/agency/clients">Clients List</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            {/* Page Header */}
            <Col span={24}>
              <PageHeader 
                  className="site-page-header" 
                  title="Create a Client"
                  extra={[
                    <CButton key="2" className="btn-save-primary" type="default" action={() => window.history.back()} title="Cancel"/>,
                    <CButton key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title="Create"/>
                  ]}
                />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {/* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      label="Client Name" 
                      name="name"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: 'Employer Name is too long. Maximum is 50 characters' },
                        // { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                      ]}
                      >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Client Email" 
                      name="email" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { type: 'email', message: 'The input is not valid E-mail' }, 
                      ]}
                    >
                      <Input type="email" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person" 
                      name="picName"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },               
                        { max: 50, message: 'Employer Name is too long. Maximum is 50 characters' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed!' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person Mobile Number" 
                      name="mobilePhone"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 4, message: 'Your mobile number is invalid' },
                        { max: 13, message: 'Your mobile number is invalid' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input addonBefore={mobileNumber} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person Email" 
                      name="picEmail" 
                      initialValue=''
                      rules={[
                        { type: 'email', message: 'Your email address is invalid' }
                      ]}
                    >
                      <Input type="email" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Industry"
                      name="jobIndustryId">
                      <Select
                        showSearch
                        placeholder="Select an Industry"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getJobIndustries?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.title}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>


                  <Col span={12}>
                    <Form.Item 
                      label="Specialities (niche or specific industry of the employer)" 
                      name="picEmail" 
                      initialValue=''
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Office Phone Number" 
                      name="officePhone" 
                      validateFirst
                      initialValue=''
                      rules={[
                        { min: 4, message: 'Your mobile number is invalid' },
                        { max: 13, message: 'Your mobile number is invalid' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },  
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Company Size" name="totalEmployee" initialValue=''>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Select.Option value="1 - 20 employees">1 - 20 employees</Select.Option>
                        <Select.Option value="21 - 100 employees">21 - 100 employees</Select.Option>
                        <Select.Option value="100 - 250 employees">100 - 250 employees</Select.Option>
                        <Select.Option value="250 - 500 employees">250 - 500 employees</Select.Option>
                        <Select.Option value="500 - 1000 employees">500 - 1000 employees</Select.Option>
                        <Select.Option value="> 1000 employees">{`> 1000 employees`}</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Website" 
                      name="website"
                      validateFirst
                      initialValue=''
                      rules={[
                        { max: 100, message: 'Maximum is 100 characters' },
                        { pattern: new RegExp(/^\S*$/), message: 'Invalid website url' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Country" name="countryId" initialValue={99}>
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a country"
                        optionFilterProp="children"
                        onChange={this.handleChangeCountry}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getCountry?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Province" name="provinceId">
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a province"
                        onChange={this.handleChangeProvince}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataProvince.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="City" name="cityId">
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a city"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataCity.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Street Address" 
                      name="address"
                      initialValue=''
                      rules={[
                        { max: 100, message: 'Address is too long. Maximum is 100 characters' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Postal Code" 
                      name="zipCode"
                      validateFirst
                      initialValue=''
                      rules={[
                        { max: 11, message: 'Maximum postal code is 11 characters' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Logo">
                      {
                        editAvatar ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                        </Form.Item>
                        :
                        null
                      }
                      <Space>
                        <Upload {...this.handleUpload()}>
                          <Button icon={<UploadOutlined />}>{editAvatar ? 'Change File' : 'Upload a File'}</Button>
                        </Upload>
                        {
                          editAvatar ?
                            <Button icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                          : null
                        }
                      </Space>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Overview" 
                      name="about"
                      initialValue=''
                      rules={[
                        { max: 2000, message: 'Maximum is 2000 characters' },
                      ]}
                    >
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ marginBottom: 24 }}>
                    <Card style={{ border: 'none', backgroundColor: '#fafafa', paddingLeft: 32, paddingRight: 32 }}>
                      <Row gutter={[32, 0]}>
                        <Col span={10}>
                          <Space direction='vertical' size={32} style={{ width: '100%', textAlign: 'center' }}>
                            <Text strong>Slider banner</Text>
                            <Dragger {...this.handleUploadSlider()} showUploadList={false} disabled={fileSlider.length >= 5}>
                              <Space direction='vertical' size={16} style={{ marginTop: 32, marginBottom: 32 }}>
                                <Space direction='vertical' size={0}>
                                  <p className="ant-upload-hint">480 x 1400px recommended</p>
                                  <p className="ant-upload-hint">.jpg, .jpeg, .png</p>
                                  <p className="ant-upload-hint">max 500kb</p>
                                </Space>
                                <Space direction='vertical'>
                                  <Button disabled={fileSlider.length >= 5} style={{ backgroundColor: '#C7E9D8', borderRadius: '6px', border: 'none', boxShadow: '0 2px 4px 0 rgba(198, 198, 198, 0.5)', color: '#37845D' }}>
                                    Upload or Drag
                                  </Button>
                                </Space>
                              </Space>
                            </Dragger>
                            {
                              <Space direction='vertical'>
                                {`${fileSlider.length} / 5`}
                              </Space>
                            }
                          </Space>
                        </Col>
                        <Col span={14}>
                          {
                            fileSlider.length > 0 ?
                              fileSlider.map((item,i) => 
                                <Row key={i} justify="space-around" align="middle" style={{ marginBottom: 8 }}>
                                  <Col span={23}>
                                    <Space size={32}>
                                      <Image 
                                        width={60}
                                        height={60}
                                        src={item.url}
                                        preview={false}
                                      />
                                      <Text>{item.name} <Text type='secondary'>{`(Uploaded ${item.date}, ${bytesToSize(item.size)})`}</Text></Text>
                                    </Space>
                                  </Col>
                                  <Col span={1} style={{ verticalAlign: 'center' }}>
                                    <CloseOutlined onClick={() => this.handleRemoveSlider(item)} style={{ float: 'right', fontSize: 18, color: '#CCCCCC', cursor: 'pointer' }} />
                                  </Col>
                                </Row>
                              )
                            : null
                          }
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                  
                  <Col span={24}>
                    <Card style={{ border: 'none', backgroundColor: '#fafafa', paddingLeft: 32, paddingRight: 32 }}>
                      <Row gutter={[32, 0]}>
                        <Col span={10} style={{ textAlign: 'center' }}>
                          <Row gutter={[16, 0]}>
                            <Col span={24} style={{ marginBottom: 32 }}>
                              <Text strong>Gallery</Text>
                            </Col>
                            <Col span={12}>
                              <Space direction='vertical' size={32} style={{ width: '100%', textAlign: 'center' }}>
                                <Dragger {...this.handleUploadImage()} showUploadList={false} disabled={fileImage.length >= 4}>
                                  <Space direction='vertical' size={16} style={{ marginTop: 32, marginBottom: 32 }}>
                                    <Space direction='vertical' size={0}>
                                      <p className="ant-upload-hint">700 x 700px recommended</p>
                                      <p className="ant-upload-hint">.jpg, .jpeg, .png</p>
                                      <p className="ant-upload-hint">max 1mb</p>
                                    </Space>
                                    <Space direction='vertical'>
                                      <Button disabled={fileImage.length >= 4} style={{ backgroundColor: '#C7E9D8', borderRadius: '6px', border: 'none', boxShadow: '0 2px 4px 0 rgba(198, 198, 198, 0.5)', color: '#37845D' }}>
                                        Upload or Drag
                                      </Button>
                                    </Space>
                                  </Space>
                                </Dragger>
                                {
                                  <Space direction='vertical'>
                                    {`${fileImage.length} / 4`}
                                  </Space>
                                }
                              </Space>
                            </Col>
                            <Col span={12}>
                              <Space direction='vertical' size={32} style={{ width: '100%', textAlign: 'center' }}>
                                <Dragger {...this.handleUploadVideo()} showUploadList={false} disabled={fileVideo.length >= 1}>
                                  <Space direction='vertical' size={16} style={{ marginTop: 32, marginBottom: 32, padding: 11 }}>
                                    <Space direction='vertical' size={0}>
                                      <p className="ant-upload-hint">Format .mp4</p>
                                      <p className="ant-upload-hint">max 30mb</p>
                                    </Space>
                                    <Space direction='vertical'>
                                      <Button disabled={fileVideo.length >= 5} style={{ backgroundColor: '#C7E9D8', borderRadius: '6px', border: 'none', boxShadow: '0 2px 4px 0 rgba(198, 198, 198, 0.5)', color: '#37845D' }}>
                                        Upload or Drag
                                      </Button>
                                    </Space>
                                  </Space>
                                </Dragger>
                                {
                                  <Space direction='vertical'>
                                    {`${fileVideo.length} / 1`}
                                  </Space>
                                }
                              </Space>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={14}>
                          {
                            fileImage.length > 0 ?
                              fileImage.map((item,i) => 
                                <Row key={i} justify="space-around" align="middle" style={{ marginBottom: 8 }}>
                                  <Col span={23}>
                                    <Space size={32}>
                                      <Image 
                                        width={60}
                                        height={60}
                                        src={item.url}
                                        preview={false}
                                      />
                                      <Text>{item.name} <Text type='secondary'>{`(Uploaded ${item.date}, ${bytesToSize(item.size)})`}</Text></Text>
                                    </Space>
                                  </Col>
                                  <Col span={1} style={{ verticalAlign: 'center' }}>
                                    <CloseOutlined onClick={() => this.handleRemoveImage(item)} style={{ float: 'right', fontSize: 18, color: '#CCCCCC', cursor: 'pointer' }} />
                                  </Col>
                                </Row>
                              )
                            : null
                          }
                          {
                            fileVideo.length > 0 ?
                              fileVideo.map((item,i) => 
                                <Row key={i} justify="space-around" align="middle" style={{ marginBottom: 8 }}>
                                  <Col span={23}>
                                    <Space size={32}>
                                      <Image 
                                        width={60}
                                        height={60}
                                        src={IconVideo}
                                        preview={false}
                                      />
                                      <Text>{item.name} <Text type='secondary'>{`(Uploaded ${item.date}, ${bytesToSize(item.size)})`}</Text></Text>
                                    </Space>
                                  </Col>
                                  <Col span={1} style={{ verticalAlign: 'center' }}>
                                    <CloseOutlined onClick={() => this.handleRemoveVideo(item)} style={{ float: 'right', fontSize: 18, color: '#CCCCCC', cursor: 'pointer' }} />
                                  </Col>
                                </Row>
                              )
                            : null
                          }
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetJobIndustries, unmountGetCountry } = this.props;
    unmountGetCountry()
    unmountGetJobIndustries()
  }
}

const mapStateToProps = (state) => ({
  getJobIndustries  : state.jobIndustries.master,
  getCountry        : state.countries.master,
})

const mapDispatchToProps = {
  actionCreate            : createClient,
  actionGetJobIndustries  : masterJobIndustries,
  actionGetCountry        : masterCountries,
  actionGetProvince       : provinceByCountry,
  actionGetCity           : cityByProvince,
  unmountGetCountry       : unmountMasterCountries,
  unmountGetJobIndustries : unmountMasterJobIndustries
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateClient)
