import React, { Component } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { SnippetsOutlined, DeleteOutlined, MinusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Row, Col, PageHeader, Form, Input, Select, Upload, Button, Space, Breadcrumb, Image, message, DatePicker, Typography, TimePicker, InputNumber, Checkbox, Modal} from 'antd';
import ReactQuill from 'react-quill';
import update from 'react-addons-update';
import { masterOrganizer, unmountMasterOrganizer } from '../../../../redux/actions/organizer/organizerAction';
import { masterCategory, unmountMasterCategory } from '../../../../redux/actions/category/categoryAction';
import { createTraining } from '../../../../redux/actions/training/trainingAction';
import CButton from '../../../../components/Button';
const { Dragger } = Upload;
const { Text } = Typography;
const { confirm } = Modal

class CreateTraining extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      saveLoading: false,
      submitLoading: false,
      editAvatar: false,
      onPreviewAvatar: null,
      callingCode: "62",
      dataProvince: [],
      dataCity: [],
      isDate: false,
      current: false,
      priceType: 'FIX_PRICE',
      audience: false,
      startDate: moment(),
      avatar: [],
      statusSubmit: 'INACTIVE',
      valueForm: null,
    }
  }

  componentDidMount()  {
    const { 
      actionGetCategory,
      actionGetOrganizer
    } = this.props;
    actionGetCategory()
    actionGetOrganizer()
  }

  onChangeStartDate = (current) => {
    this.formRef.current.setFieldsValue({
      endDate: null,
    });
    this.setState({ startDate: current })
  }

  disabledStartDate = (current) => {
    let today = moment()
    return !current || current.isBefore(today)
  }

  disabledEndDate = (current) => {
    let today = moment()
    return !current || current.isBefore(today)
  }

  handleChangeFixPrice = (value) => {
    this.setState({ priceType: value })
    this.formRef.current.setFieldsValue({
      price: null,
      maxPrice: null
    });
  }

  handleChecked = (e) => {
    this.setState({ audience: e.target.checked })
    if(e.target.checked){
      this.formRef.current.setFieldsValue({
        audience: null
      })
    }
  }

  handleChange = (value, fileUrl) => {
    const values = {
      file: value,
      isCover: this.state.avatar.length === 0 ? true : false,
      fileUrl: fileUrl
    }
    return this.setState({
      avatar: update(this.state.avatar, {
        $push: [values]
      })
    })
  }

  handleUpload(){
    return {
      multiple: true,
      showUploadList: false,
      withCredentials: true,
      accept:".png,.jpg,.jpeg",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.handleChange(file, e.target.result)
          this.setState({ 
            editAvatar: true,
          })
        }
        return false;
      },
    }
  }

  onDeletePhoto = (index, value) => {
    this.setState({
      avatar: update(this.state.avatar, {
        $splice: [[index, 1]]
      })
    }, () => {
      if(value.isCover === true){
        var arr = [];
        this.state.avatar.map((res, i) => {
          if(index === 0){
            res.isCover = i === 0 ? true : false
          }else{
            res.isCover = i === index-1 ? true : false
          }
          return arr.push(res)
        })
        return this.setState({
          avatar: arr
        })
      }
    })
  }

  handleClick = (type) => {
    this.setState({ statusSubmit: type })
  }

  onClickPhoto = (e) => {
    var arr = [];
    this.state.avatar.map((res, i) => {
      res.isCover = e === i ? true : false
      return arr.push(res)
    })
    return this.setState({
      avatar: arr
    })
  }

  onSave = (values) => {
    const { avatar, audience } = this.state;
    const { actionCreate, history } = this.props;
    values = values.getFieldsValue();
    values.startDate = values.startDate ? moment(values.startDate).format('DD/MM/YYYY') : null
    values.endDate = values.endDate ? moment(values.endDate).format('DD/MM/YYYY') : null
    values.startTime = values.startTime ? moment(values.startTime).format('HH:mm:ss') : null
    values.endTime = values.endTime ? moment(values.endTime).format('HH:mm:ss') : null
    // values.slug = values.title
    values.status = 'DRAFT'
    values.isAudience = audience

    if(values.studentBenefit[0] === ''){
      delete values.studentBenefit
    }

    if(values.outcomes[0] === ''){
      delete values.outcomes
    }

    if(values.for[0] === ''){
      delete values.for
    }

    // avatar.push(values)
    avatar.push = {
      ...values,
      avatar,
    };
      
      return actionCreate(avatar, (res) => {
        this.setState({ saveLoading: false }, () => {
          history.push(`/training-and-certification`)
          message.success('Data created successfully')
        })
      }, (err) => {
        this.setState({ saveLoading: false }, () => message.error(err))
      })
  }

  onFinish = (values) => {
    const { avatar, statusSubmit, audience } = this.state;
    const { actionCreate, history } = this.props;
    values.startDate = values.startDate ? moment(values.startDate).format('DD/MM/YYYY') : null
    values.endDate = values.endDate ? moment(values.endDate).format('DD/MM/YYYY') : null
    values.startTime = moment(values.startTime).format('HH:mm:ss')
    values.endTime = moment(values.endTime).format('HH:mm:ss')
    // values.slug = values.title
    values.status = statusSubmit
    values.isAudience = audience

    if(avatar.length < 1){
      return message.error('Please Upload Slider Image at least one')
    }

    avatar.push = {
      ...values,
      avatar,
    };
    // avatar.push(values)
    if(statusSubmit === 'INACTIVE'){
      this.setState({ submitLoading: true })
      
      return actionCreate(avatar, (res) => {
        this.setState({ submitLoading: false }, () => {
          confirm({
            title: `This Training and Certification status 'Inactive'`,
            icon: <ExclamationCircleOutlined />,
            content: `If you want to change it to active you must create at least one ticket. You can click "Okay" and it will be direct to form create ticket.`,
            onOk() {
              history.push(`/training-and-certification/detail/${res.data.id}`)
            },
            onCancel() {
              history.push(`/training-and-certification/create`)
            },
            width: 500
          });
          // Modal.info({
          //   title: `This Training and Certification status 'Inactive'`,
          //   content: `If you want to change it to active you must create at least one ticket. You can click "Okay" and it will be direct to form create ticket.`,
          //   onOk() {
          //     history.push(`/training-and-certification/detail/${res.data.id}`)
          //   },
          //   width: 500
          // })
          // message.success('Data created successfully')
        })
      }, (err) => {
        this.setState({ submitLoading: false }, () => message.error(err))
      })
    }else{
      this.setState({ saveLoading: true })
      
      return actionCreate(avatar, (res) => {
        this.setState({ saveLoading: false }, () => {
          // Modal.info({
          //   title: `This Training and Certification status 'Inactive'`,
          //   content: `If want to change it to active you must create at least one ticket. You can click "Okay" and it will be direct to form create ticket.`,
          //   onOk() {
          //     history.push(`/training-and-certification/detail/${res.data.id}`)
          //   },
          //   width: 500
          // })
          history.push(`/training-and-certification`)
          message.success('Data created successfully')
        })
      }, (err) => {
        this.setState({ saveLoading: false }, () => message.error(err))
      })
    }
  }

  handleChangeValue = (value) => {
    if (value.title && value.categoryId && value.trainingType ) {
      this.setState({ valueForm: value })
    }
  }


  render() {
    const { saveLoading, submitLoading, current, audience, avatar, valueForm } = this.state;
    const { getCategory, getOrganizer } = this.props;

    if(getCategory.loading || getOrganizer.loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} initialValues={{ studentBenefit: [""], outcomes: [""], for: [""], syllabus: [""] }} scrollToFirstError={true}
          onValuesChange={(_, value) => {
            this.handleChangeValue(value)
          }}>
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><SnippetsOutlined /> Training & Certification</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/training-and-certification">Training & Certification List</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            {/* Page Header */}
            <Col span={24}>
              <PageHeader 
                title="Create a Training & Certification" 
                className="site-page-header"
                extra={[
                  <CButton key="3" style={{ width: 'auto' }} className="btn-radius" type="default" action={() => window.history.back()} title="Cancel"/>,
                  <CButton key="2" style={{ width: 'auto' }} className="btn-radius" type="primary" loading={saveLoading} action={() => this.onSave(this.formRef.current)} disabled={valueForm !== null ? false : true } ghost title="Save as Draft"/>,
                  <CButton key="1" style={{ width: 'auto' }} className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title="Submit"/>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {/* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={24} style={{ marginBottom: 30 }}>
                    <Typography.Text style={{ fontSize: 18 }}>General Information</Typography.Text>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Title" 
                      name="title"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Title must be at least 3 characters' },
                        { max: 50, message: '50 characters only' },
                        // { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                      ]}
                      >
                      <Input />
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Type" 
                      name="trainingType" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Select.Option value="TRAINING">Training</Select.Option>
                        <Select.Option value="CERTIFICATION">Certification</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Category" 
                      name="categoryId" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getCategory?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item 
                      label="Start Date" 
                      name="startDate"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker 
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }} 
                        disabledDate={this.disabledStartDate} 
                        onChange={this.onChangeStartDate}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item 
                      label="End Date" 
                      name="endDate"
                      initialValue={null}
                      rules={[
                        { required: !current, message: 'This is a required field' },
                      ]}
                    >
                      {
                        current ?
                        <Text className="fs-12" type="secondary">Present</Text>
                        :
                        <DatePicker 
                          format={'DD/MM/YYYY'}
                          style={{ width: '100%' }} 
                          disabledDate={this.disabledEndDate} 
                          allowClear={false}
                        />
                      }
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Target Number of Audiences" 
                      name="audience" 
                      validateFirst
                      rules={[
                        { required: !audience, message: 'This is a required field' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' }
                      ]}
                    >
                      <InputNumber maxLength={4} disabled={audience}/>
                    </Form.Item>
                  </Col>
                  
                  <Col span={6}>
                    <Form.Item 
                      label="Start Time"
                      name="startTime"
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <TimePicker style={{ width: '100%' }} format="HH:mm" />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item 
                      label="End Time"
                      name="endTime"
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <TimePicker style={{ width: '100%' }} format="HH:mm" />
                    </Form.Item>
                  </Col>
                  
                  <Col span={12}>
                    <Form.Item>
                      <Checkbox onChange={(e) => this.handleChecked(e)}>Unlimited Audience</Checkbox>
                    </Form.Item>
                  </Col>

                  {/* <Col span={12}>
                    <Form.Item 
                      label="Price Type" 
                      name="priceType" 
                      initialValue='FIX_PRICE'
                      rules={[
                        { required: priceType, message: 'This is a required field' }
                      ]}
                    >
                      <Radio.Group onChange={(e) => this.handleChangeFixPrice(e.target.value)}>
                        <Radio value='FIX_PRICE'>Fix Price</Radio>
                        <Radio value='RANGE_PRICE'>Range Price</Radio>
                        <Radio value='FREE'>This is free training & certification</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col> */}
                  
                  <Col span={12}>
                    <Form.Item 
                      label="Issuing Organization" 
                      name="organizerId" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getOrganizer.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  
                  {/* {
                    priceType === 'FIX_PRICE' || priceType === 'FREE' ?
                    <Col span={12}>
                      <Form.Item 
                        label="Displayed Price" 
                        name="price" 
                        validateFirst
                        initialValue={null}
                        rules={[
                          { required: priceType === 'FIX_PRICE', message: 'This is a required field' },
                        ]}
                      >
                        <InputNumber
                          disabled={priceType === 'FREE'}
                          maxLength={10}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        />
                      </Form.Item>
                    </Col>
                    :
                    <React.Fragment>
                      <Col span={6}>
                        <Form.Item 
                          label="Min Price" 
                          name="price" 
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <InputNumber
                            maxLength={12}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Col>

                      <Col span={6}>
                        <Form.Item 
                          label="Max Price" 
                          name="maxPrice" 
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                          ]}
                        >
                          <InputNumber
                            maxLength={12}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Col>
                    </React.Fragment>
                  } */}

                  <Col span={24}>
                    <Form.Item 
                      label="Trainer Name" 
                      name="trainerName" 
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Trainer Name must be at least 3 characters long' },
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ marginBottom: 30 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Detail Information</Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Form.List
                      name="studentBenefit"
                      style={{ width: '100%' }}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              label={index === 0 ? 'Student Benefit' : ''}
                              required={true}
                              key={field.key}
                              style={{ marginBottom: 0 }}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  { required: true, message: "This field is required"},
                                  { min: 5, message: 'at least 5 characters' },
                                  { max: 100, message: '100 characters only' },
                                ]}
                              >
                                <Input placeholder="Contoh: Mendapatkan kredensial ynag diakui secara internasional" style={{ width: fields.length === 1 ? '100%' : '95%' }} />
                              </Form.Item>
                              {fields.length > 1 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <CButton type="dashed" block action={() => add()} icon="PlusOutlined" title="Add another"/>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>

                  <Col span={24}>
                    <Form.List
                      name="outcomes"
                      style={{ width: '100%' }}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              label={index === 0 ? 'Outcomes' : ''}
                              required={true}
                              key={field.key}
                              style={{ marginBottom: 0 }}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  { required: true, message: "This field is required"},
                                  { min: 5, message: 'at least 5 characters' },
                                  { max: 100, message: '100 characters only' },
                                ]}
                              >
                                <Input placeholder="Contoh: Meningkatkan skill dalam menginisiasi, merencanakan, melaksanakan, mengontrol proyek agar mencapai goal" style={{ width: fields.length === 1 ? '100%' : '95%' }} />
                              </Form.Item>
                              {fields.length > 1 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <CButton type="dashed" block action={() => add()} icon="PlusOutlined" title="Add another"/>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>

                  <Col span={24}>
                    <Form.List
                      name="for"
                      style={{ width: '100%' }}
                    >
                      {(fields, { add, remove }, { errors }) => (
                        <>
                          {fields.map((field, index) => (
                            <Form.Item
                              label={index === 0 ? 'Who is This Training or Certification for?' : ''}
                              required={true}
                              key={field.key}
                              style={{ marginBottom: 0 }}
                            >
                              <Form.Item
                                {...field}
                                validateTrigger={['onChange', 'onBlur']}
                                rules={[
                                  { required: true, message: "This field is required"},
                                  { min: 5, message: 'at least 5 characters' },
                                  { max: 50, message: '50 characters only' },
                                ]}
                              >
                                <Input placeholder="Contoh: Manajer proyek" style={{ width: fields.length === 1 ? '100%' : '95%' }} />
                              </Form.Item>
                              {fields.length > 1 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => remove(field.name)}
                                />
                              ) : null}
                            </Form.Item>
                          ))}
                          <Form.Item>
                            <CButton type="dashed" block action={() => add()} icon="PlusOutlined" title="Add another"/>
                            <Form.ErrorList errors={errors} />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Training or Certitication Description" 
                      name="xpertDetail" 
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 5, message: 'at least 5 characters' },
                        { max: 1000, message: '1,000 characters only' },
                      ]}
                    >
                      <ReactQuill placeholder="Training & Certification Description" />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Space direction="vertical" style={{ width: '100%' }}>
                      <Typography.Text>Syllabus</Typography.Text>
                      <Form.List 
                        name="syllabus"
                        style={{ width: '100%' }}
                      >
                        {(fields, { add, remove }) => (
                        <>
                          {fields.map((key, name, ...restField) => (
                            <Row key={key} style={{ marginBottom: 16 }}>
                              <Card style={{ width: fields.length === 1 ? '100%' : '95%' }}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'title']}
                                  label="Title" 
                                >
                                  <Input placeholder="Contoh: Modul 1: Penjelasan secara terperinci mengenai manajemen produk" />
                                </Form.Item>
                                <Form.Item 
                                  {...restField}
                                  name={[name, 'detail']}
                                  label="Description" 
                                  initialValue={null}
                                  rules={[
                                    { max: 8000, message: '8,000 characters only' },
                                  ]}
                                >
                                  <ReactQuill 
                                    placeholder="Contoh: 
- Definisi dan karakteristik manajemen proyek
- Cakupan manajemen proyek
- Pihak yang terlibat dalam manajemen proyek" 
                                  />
                                </Form.Item>
                              </Card>
                              {fields.length > 1 ? (
                                <MinusCircleOutlined
                                  className="dynamic-delete-button2"
                                  onClick={() => remove(name)}
                                />
                              ) : null}
                            </Row>
                          ))}
                          <Form.Item>
                            <CButton type="dashed" block action={() => add()} icon="PlusOutlined" title=" Add another"/>
                          </Form.Item>
                        </>
                      )}
                      </Form.List>
                    </Space>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Skills Coverage"
                      name="tags"
                      initialValue={[]}
                      rules={[
                        // { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select mode="tags" tokenSeparators={[',']} placeholder="Skills Coverage" dropdownStyle={{ display: 'none' }} />
                    </Form.Item>
                  </Col>

                  <Col span={24} style={{ marginBottom: 30 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Slider Images</Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Card style={{ border: 'none' }}>
                      <Row gutter={[16, 24]}>
                        <Col span={24}>
                          <Dragger 
                            {...this.handleUpload()} 
                            disabled={avatar.length < 5 ? false : true}
                            style={{ 
                              backgroundColor: '#fafafa', 
                              boxShadow: ' 0 2px 4px 0 rgba(198, 198, 198, 0.5)',
                              border: 'solid 1px #f3f3f3',
                              padding: 25 
                            }}
                          >
                            <Space direction="vertical">
                              <Button 
                                disabled={avatar.length < 5 ? false : true}
                                style={{ 
                                  backgroundColor: '#c7e9d8',
                                  width: '499px',
                                  height: '38px',
                                  margin: '0px 0px 19px',
                                  borderRadius: '6px',
                                  border: 'none',
                                  boxShadow: '0 2px 4px 0 rgba(198, 198, 198, 0.5)',
                                  color: '#32ab6d',
                                  fontSize: '16px'
                                }}
                              >
                                Select Photos
                              </Button>
                              <Text type="secondary" style={{ fontSize: '16px' }}>
                                or drag photos here (ideally width= 1200px, height= 500px)
                                up to 5 photos
                              </Text>
                            </Space>
                          </Dragger>
                        </Col>
                        <Col span={24}>
                          <div className="list-slider-photo">
                            {
                              avatar.map((item, i) => (
                                <div key={i} style={{ position: 'relative' }}>
                                  <Card className={item.isCover ? 'cover-photo' : 'not-cover-photo'} onClick={() => this.onClickPhoto(i)}>
                                    {
                                      item.isCover ?
                                      <div style={{ width: '100%', marginBottom: 15 }}>
                                        <Text style={{ color: ' #706f6f', fontSize: 18 }}>COVER</Text>
                                      </div>
                                      :
                                      null
                                    }
                                    <Row gutter={[16, 24]}>
                                      <Col span={24}>
                                        <div>
                                          <Image src={item.fileUrl} preview={false} width={300} /> 
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card>
                                  <div style={{ position: 'absolute', top: 0, right: 15 }}>
                                    <Button 
                                      shape="circle" 
                                      type="danger"
                                      icon={<DeleteOutlined style={{ color: 'white' }} />} 
                                      onClick={() => this.onDeletePhoto(i, item)}
                                    />
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetOrganizer, unmountGetCategory } = this.props;
    unmountGetOrganizer()
    unmountGetCategory()
  }
}

const mapStateToProps = (state) => ({
  getCategory   : state.category.master,
  getOrganizer : state.organizer.master
})

const mapDispatchToProps = {
  actionCreate           : createTraining,
  actionGetOrganizer     : masterOrganizer,
  actionGetCategory      : masterCategory,
  unmountGetOrganizer    : unmountMasterOrganizer,
  unmountGetCategory     : unmountMasterCategory
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateTraining)