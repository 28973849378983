import React, { Component } from 'react';
import EditSkill from './Edit';
import svg from '../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addSkill, deleteSkill, detailCandidate, unmountDetailCandidate, enableCandidate } from '../../../../redux/actions/candidate/candidateAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Space, Avatar, Typography, Descriptions, Empty, Modal, Tag, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Menu from '../Menu';
import CButton from '../../../Button';
const { confirm } = Modal;
const { Text } = Typography;
const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return <Component {...rest} />
};
class CandidateDetailSkills extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      name: null
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  showModal = () => {
    return this.setState({ visible: true })
  }

  onCancel = () => {
    const { actionGetDetail, match: { params } } = this.props;
    return this.setState({ visible: false }, () => {
      actionGetDetail(params.id)
    })
  }

  onChangeName = (e) => {
    this.setState({ name: e.target.value })
  }

  handleDelete = (id) => {
    const { actionDelete, actionGetDetail, match: { params } } = this.props;
    return actionDelete(id, () => {
      message.success('Data deleted successfully')
      return actionGetDetail(params.id)
    }, (err) => message.error(err))
  }

  onFinish = () => {
    const { actionAdd, actionGetDetail, match: { params } } = this.props
    this.formRef.current.validateFields().then(values => {
      values.candidateId = Number(params.id)
      return actionAdd(values, () => {
        this.formRef.current.setFieldsValue({
          name: null,
        });
        this.setState({ name: null })
        message.success('Data updated successfully')
        return actionGetDetail(params.id)
      }, (err) => message.error(err))
    })
  }
  
  showConfirmActive = () => {
    const { actionEnable, actionGetDetail ,match: { params } } = this.props;
    confirm({
      title: 'Unable to edit profile due to candidate status is inactive. Do you want to change candidate status to active?',
      icon: <ExclamationCircleOutlined />,
      width: 700,
      onOk() {
        return actionEnable(params.id, () => {
          message.success('Successfully changed to active')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
      okText: 'Change to Active',
      onCancel() {},
    });
  };

  render() {
    const { visible } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type={this.props.icon} />
                {
                  this.props.url.split('/')[1] === 'all-candidate' ?
                    ' All Candidates'
                  : this.props.url.split('/')[1] === 'new-candidate' ?
                    ' New Candidates'
                  : this.props.url.split('/')[1] === 'counseling-process' ?
                    ' Counseling Process'
                  : this.props.url.split('/')[1] === 'assigned-candidate' ?
                    ' Assigned Candidates'
                  :
                    ' Candidates'
                }
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to={`/${this.props.url.split('/')[1]}`}>
                  {
                    this.props.url.split('/')[1] === 'all-candidate' ?
                      'All Candidates List'
                    : this.props.url.split('/')[1] === 'new-candidate' ?
                      'New Candidates List'
                    : this.props.url.split('/')[1] === 'counseling-process' ?
                      'Counseling Process List'
                    : this.props.url.split('/')[1] === 'assigned-candidate' ?
                      'Assigned Candidates List'
                    :
                      'Candidates List'
                  }
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                      {
                        data.enabled ? 
                          <Tag color="success">Active</Tag>
                        :
                          <Tag color="error">Inactive</Tag>
                      }
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="skills" id={data.id} email={data.email}/>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Skills</Text>} 
              extra={<CButton key="1" className="btn-edit-candidate" type="primary-icon" icon='EditOutlined' action={data.enabled ? this.showModal : this.showConfirmActive} title="Edit"/>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {
                    data.listSkill.length > 0 ?
                    <React.Fragment>
                      <Descriptions layout="vertical" size="small" column={2}>
                        {
                          data.listSkill.map((item, i) => (
                          <Descriptions.Item key={i}>
                            <Space direction="vertical" size="small">
                              <Text>{item.name}</Text>
                              {
                                item.skillLevel ?
                                <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                                  {item.skillLevel === 'BASIC' && 'Basic'}
                                  {item.skillLevel === 'INTERMEDIATE' && 'Intermediate'}
                                  {item.skillLevel === 'ADVANCE' && 'Advance'}
                                  {item.skillLevel === 'EXPERT' && 'Expert'}
                                </Text>
                                :
                                'N/A'
                              }
                            </Space>
                          </Descriptions.Item>
                          ))
                        }
                      </Descriptions>
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Empty description={<Text>No Data Skills</Text>} />
                    </React.Fragment>
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          width={1000}
          footer={false}
          visible={visible}
          onCancel={this.onCancel}
          title={<Text strong>Edit Skill</Text>}
        >
          <EditSkill 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onFinish={this.onFinish} 
            onChangeName={this.onChangeName}
            handleDelete={this.handleDelete}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
})

const mapDispatchToProps = {
  actionAdd         : addSkill,
  actionDelete      : deleteSkill,
  actionGetDetail   : detailCandidate,
  actionEnable      : enableCandidate,
  unmountGetDetail  : unmountDetailCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailSkills)
