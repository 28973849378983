import React, { Component } from 'react';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Form, Row, Col, Skeleton, Select, Input, Upload, Image, Space, Button, message } from 'antd'
import CButton from '../../../../components/Button';
export default class EditAbout extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null,
    }
  }
  
  componentDidMount() {
    const { actionGetJobIndustry } = this.props;
    actionGetJobIndustry()
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  onFinish = (values) => {
    const { actionUpdateAbout, handleCancel } = this.props;
    values.jobIndustryId = values.jobIndustryId ? values.jobIndustryId : ''
    if(this.state.editAvatar){
      values.logoFile = this.state.avatar
    }
    this.setState({ submitLoading: true })
    return actionUpdateAbout(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        return handleCancel()
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  onCancel = () => {
    const { handleCancel } = this.props;
    return handleCancel()
  }
  
  render() {
    const { getDetailAbout: { loading, data }, getJobIndustry } = this.props;
    const { editAvatar, onPreviewAvatar, submitLoading } = this.state;
    if(loading || getJobIndustry.loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Form layout="vertical" onFinish={this.onFinish}>
          <Row gutter={16}>
            
          <Col span={12}>
            <Form.Item 
              label="Industry" 
              name="jobIndustryId"
              initialValue={data.jobIndustryId ? data.jobIndustryId : null}
              rules={[
                { required: true, message: 'This is a required field' },
              ]}
            >
              <Select
                showSearch
                placeholder="Select an Industry"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {
                  getJobIndustry?.data.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.title}
                    </Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>

            <Col span={12}>
              <Form.Item 
                label="Specialties (niche or specific industry of the employer)" 
                name="specialties" 
                initialValue={data.specialties ? data.specialties : ''}
                rules={[
                  { max: 50, message: '50 characters only' },
                ]}
              >
                <Input placeholder="e.g. Recruiting, HR Software" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                label="Company Size" 
                name="totalEmployee"
                initialValue={data.totalEmployee ? data.totalEmployee : ''}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option value="1 - 20 employees">1 - 20 employees</Select.Option>
                  <Select.Option value="21 - 100 employees">21 - 100 employees</Select.Option>
                  <Select.Option value="100 - 250 employees">100 - 250 employees</Select.Option>
                  <Select.Option value="250 - 500 employees">250 - 500 employees</Select.Option>
                  <Select.Option value="500 - 1000 employees">500 - 1000 employees</Select.Option>
                  <Select.Option value="> 1000 employees">{`> 1000 employees`}</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                label="Website" 
                name="website"
                validateFirst
                initialValue={data.website ? data.website : ''}
                rules={[
                  { max: 100, message: 'Maximum is 100 characters' },
                  { pattern: new RegExp(/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi), message: 'Invalid website url' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                label="Overview" 
                name="about"
                initialValue={data.about ? data.about : ''}
                rules={[
                  { max: 2000, message: 'Maximum is 2000 characters' },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Logo">
                {
                  data.logoUrl ?
                  <Form.Item>
                    <Image width={100} src={editAvatar ? onPreviewAvatar : data.logoUrl ? data.logoUrl : null} />
                  </Form.Item>
                  :
                  editAvatar ?
                  <Form.Item>
                    <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                  </Form.Item>
                  :
                  null
                }
                <Space>
                  <Upload {...this.handleUpload()}>
                    <Button icon={<UploadOutlined />}>{data.logoUrl ? 'Change File' : editAvatar ? 'Change File' : 'Upload a File'}</Button>
                  </Upload>
                  {
                    editAvatar ? 
                    <Button icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                    : null
                  }
                </Space>
              </Form.Item>
            </Col>

            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Space>
                <CButton className="btn-cancel-danger" type="danger" action={this.onCancel} title="Cancel"/>
                <CButton className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title="Update"/>
              </Space>
            </Col>

          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
