import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { createFaq } from '../../../../redux/actions/faq/faqAction';
import { Col, Form, Row, Input, PageHeader, message, Card, Breadcrumb } from 'antd';
import CButton from '../../../../components/Button';

class CreateFaqEmployer extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false
    }
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    this.setState({ submitLoading: true })
    actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/employer/faq')
      })
    }, (err) => {
      message.error(err.message)
      this.setState({ submitLoading: false })
    })
  }

  render() {
    const { submitLoading } = this.state;
    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><QuestionCircleOutlined /> FAQ</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/employer/faq">
                    FAQ List
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create FAQ</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title="Create an FAQ"
                extra={[
                  <CButton key="2" className="btn-save-primary" type="default" action={() => window.history.back()} title="Cancel"/>,
                  <CButton key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title="Create"/>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item 
                      label="Question" 
                      name="question" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { max: 1000, message: '1000 characters only' },
                        { min: 10, message: 'At least 10 characters long' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item 
                      label="Answer" 
                      name="answer"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { max: 1000, message: '1000 characters only' },
                        { min: 10, message: 'At least 10 characters long' },
                      ]}
                    >
                      <Input.TextArea rows={3} />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {
  actionCreate: createFaq,
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateFaqEmployer)
