import React, { Component } from 'react';
import { Form, Row, Col, Skeleton, Select, Input, Space, message } from 'antd'
import CButton from '../../../../components/Button';

export default class EditLocation extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      dataProvince: [],
      dataCity: []
    }
  }

  componentDidMount() {
    const { actionGetLocation, actionGetCountry, actionGetProvince, actionGetCity } = this.props;
    actionGetLocation((response) => {
      actionGetProvince(response.data.countryId, (response) => {
        this.setState({ dataProvince: response.data })
      })
      if(response.data.provinceId){
        actionGetCity(response.data.provinceId, (response) => {
          this.setState({ dataCity: response.data })
        })
      }
    })
    actionGetCountry()
  }

  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
      cityId: null,
    });
    this.setState({ dataCity: [] })
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null
    });
    return actionGetCity(value, (response) => {
      this.setState({ dataCity: response.data })
    })
  }

  onFinish = (values) => {
    const { actionUpdateLocation, handleCancel } = this.props;
    this.setState({ submitLoading: true })
    return actionUpdateLocation(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        return handleCancel()
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  onCancel = () => {
    const { handleCancel } = this.props;
    return handleCancel()
  }
  
  render() {
    const { submitLoading, dataProvince, dataCity } = this.state;
    const { getDetailLocation: { loading, data }, getCountry } = this.props;
    if(loading || getCountry.loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Form layout="vertical" onFinish={this.onFinish} ref={this.formRef}>
          <Row gutter={16}>

            <Col span={12}>
              <Form.Item 
                label="Country" 
                name="countryId" 
                initialValue={data.countryId ? data.countryId : null}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select a country"
                  optionFilterProp="children"
                  onChange={this.handleChangeCountry}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    getCountry?.data.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                label="Province" 
                name="provinceId"
                initialValue={data.provinceId ? data.provinceId : null}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select a province"
                  onChange={this.handleChangeProvince}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    dataProvince.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                label="City" 
                name="cityId"
                initialValue={data.cityId ? data.cityId : null}
              >
                <Select
                  showSearch
                  allowClear
                  placeholder="Select a city"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {
                    dataCity.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.name}
                      </Select.Option>
                    ))
                  }
                </Select>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                label="Postal Code" 
                name="zipCode"
                validateFirst
                initialValue={data.zipCode ? data.zipCode : ''}
                rules={[
                  { max: 11, message: 'Maximum postal code is 11 characters' },
                  { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                label="Street Address" 
                name="address"
                initialValue={data.address ? data.address : ''}
                rules={[
                  { max: 100, message: 'Address is too long. Maximum is 100 characters' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
              
            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Space>
                <CButton className="btn-cancel-danger" type="danger" action={this.onCancel} title="Cancel"/>
                <CButton className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title="Update"/>
              </Space>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
