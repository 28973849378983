import React, { Component } from 'react';
import moment from 'moment';
import AddResultTest from './Add';
import EditResultTest from './Edit';
import svg from '../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import { addCandidateResultTest, deleteCandidateResultTest, listCandidateResultTest, unmountListCandidate, updateCandidateResultTest,detailCandidate, unmountDetailCandidate, enableCandidate } from '../../../../redux/actions/candidate/candidateAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Avatar, Card, Space, Typography, Descriptions, Divider, Tooltip, Empty, Modal, Tag, message } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Menu from '../Menu';
import CButton from '../../../Button';
const { Text } = Typography;
const { confirm } = Modal;
const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return <Component {...rest} />
};
class CandidateDetailResultTest extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      dataCandidateResultTest: null,
      submitLoading: false,
      loading: false,
      visibleAdd: false,
      visibleEdit: false,
    }
  }

  componentDidMount() {
    const { actionGetList, actionGetDetail, match: { params } } = this.props;
    actionGetList(params.id)
    actionGetDetail(params.id)
  }

  showModal = (type, item) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: true })
    }
    if(type === 'edit'){
      return this.setState({ 
        visibleEdit: true, 
        dataCandidateResultTest: item, 
      })
    }
  }

  onCancel = (type) => {
    if(type === 'add'){
      return this.setState({ 
        visibleAdd: false,
      })
    }
    if(type === 'edit'){
      return this.setState({ 
        dataCandidateResultTest: null,
        visibleEdit: false,
      })
    }
  }

  onChangeIssueDate = (date, dateString) => {
    const { expDate } = this.state
    this.setState({ issueDate: dateString })
    if(expDate <= dateString){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }
  
  onChangeExpDate = (date, dateString) => {
    const { issueDate } = this.state
    this.setState({ expDate: dateString
    })
    if(dateString <= issueDate){
      this.setState({ isDate: true
      })
    }else{
      this.setState({ isDate: false })
    }
  }
  
  handleChecked = (e) => {
    this.setState({ isExp: e.target.checked })
    if(e.target.checked){
      this.setState({ expDate: null })
      this.formRef.current.setFieldsValue({
        expDate: null
      })
    }
  }

  handleDelete = (id) => {
    const { actionDelete, actionGetList, match: { params } } = this.props;
    confirm({
      title: 'Remove Data',
      okText: 'Yes',
      cancelText: 'No',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to remove this data?',
      onOk() {
        return actionDelete(id, () => {
          message.success('Data deleted successfully')
          return actionGetList(params.id)
        }, (err) => message.error(err))
      },
    });
  }

  onSubmitAdd = () => {
    const { actionAdd, actionGetList, match: { params } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.candidateId = Number(params.id)
      values.testDate =  values.testDate ? moment(values.testDate, 'DD/MM/YYYY').format('DD/MM/YYYY') : null
      this.setState({ submitLoading: true })
      return actionAdd(values, (response) => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleAdd: false }, () => {
            message.success('Data add successfully')
            return actionGetList(params.id)
           })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  onSubmitEdit = () => {
    const { dataCandidateResultTest } = this.state;
    const { actionUpdate, actionGetList, match: { params } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.id = dataCandidateResultTest.id
      values.candidateId = Number(params.id)
      values.testDate =  values.testDate ? moment(values.testDate, 'DD/MM/YYYY').format('DD/MM/YYYY') : null
      return actionUpdate(values, (response) => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleEdit: false }, () => {
            message.success('Data updated successfully')
            return actionGetList(params.id)
           })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }
  
  showConfirmActive = () => {
    const { actionEnable, actionGetDetail ,match: { params } } = this.props;
    confirm({
      title: 'Unable to edit profile due to candidate status is inactive. Do you want to change candidate status to active?',
      icon: <ExclamationCircleOutlined />,
      width: 700,
      onOk() {
        return actionEnable(params.id, () => {
          message.success('Successfully changed to active')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
      okText: 'Change to Active',
      onCancel() {},
    });
  };

  render() {
    const { visibleAdd, visibleEdit, submitLoading } = this.state;
    const { getDetailCandidate: { loading, data }, getListCandidate } = this.props;
    
    if(getListCandidate.loading || loading){
      return <Skeleton />
    }
    
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type={this.props.icon} />
                {
                  this.props.url.split('/')[1] === 'all-candidate' ?
                    ' All Candidates'
                  : this.props.url.split('/')[1] === 'new-candidate' ?
                    ' New Candidates'
                  : this.props.url.split('/')[1] === 'counseling-process' ?
                    ' Counseling Process'
                  : this.props.url.split('/')[1] === 'assigned-candidate' ?
                    ' Assigned Candidates'
                  :
                    ' Candidates'
                }
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to={`/${this.props.url.split('/')[1]}`}>
                  {
                    this.props.url.split('/')[1] === 'all-candidate' ?
                      'All Candidates List'
                    : this.props.url.split('/')[1] === 'new-candidate' ?
                      'New Candidates List'
                    : this.props.url.split('/')[1] === 'counseling-process' ?
                      'Counseling Process List'
                    : this.props.url.split('/')[1] === 'assigned-candidate' ?
                      'Assigned Candidates List'
                    :
                      'Candidates List'
                  }
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                      {
                        data.enabled ? 
                          <Tag color="success">Active</Tag>
                        :
                          <Tag color="error">Inactive</Tag>
                      }
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="candidateResultTest" id={data.id} email={data.email} />
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Candidate Result Test</Text>} 
              extra={
                Cookie.get('role') === "ROLE_ADMIN_RECRUITMENT" ?
                  <CButton key="1" className="btn-save-primary" type="primary-icon" icon="PlusOutlined" action={data.enabled ? () => this.showModal('add') : this.showConfirmActive} title="Add"/>
                : null
            } 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {
                    getListCandidate.data.length > 0 ?
                    <React.Fragment>
                      {
                        getListCandidate.data.map((item, i) => (
                          <React.Fragment key={i}>
                            <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                              <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                                {item.testDate ? item.testDate : 'N/A'}
                              </Text>
                               {
                                Cookie.get('role') === "ROLE_ADMIN_RECRUITMENT" ?
                                  <Space> 
                                    <Tooltip title="Edit">
                                      <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={data.enabled ? () => this.showModal('edit', item) : this.showConfirmActive} />
                                    </Tooltip>
                                    <Tooltip title="Remove">
                                      <DeleteOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#d8675a' }} onClick={data.enabled ? () => this.handleDelete(item.id) : this.showConfirmActive} />
                                    </Tooltip>
                                  </Space>  
                                : null
                               }
                               
                            </div>
                            <Descriptions layout="vertical" size="small" column={2}>
                              <Descriptions.Item label="Test Category Name">
                                {item.name ? item.name : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Score">
                                {item.score ? item.score : 'N/A'}
                              </Descriptions.Item>
                            </Descriptions>
                            <Divider />
                          </React.Fragment>
                        ))
                      }
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Empty description={<Text>No Data Candidate Result Test</Text>} />
                    </React.Fragment>
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          key="1"
          centered
          width={1000}
          destroyOnClose
          visible={visibleAdd}
          onCancel={() => this.onCancel('add')}
          title={<Text strong>Add Candidate Result Test</Text>}
          footer={[
            <React.Fragment key="1">
              <CButton key="back" className="btn-save-primary" type="default" action={() => this.onCancel('add')} title="Cancel"/>
              <CButton key="submit" className="btn-save-primary" type="primary" action={this.onSubmitAdd} loading={submitLoading} title="Save"/>
            </React.Fragment>
          ]}
        >
          <AddResultTest 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitAdd={this.onSubmitAdd} 
            handleChecked={this.handleChecked}
            onChangeExpDate={this.onChangeExpDate}
            onChangeIssueDate={this.onChangeIssueDate}
          />
        </Modal>
        <Modal
          key="2"
          centered
          width={1000}
          destroyOnClose
          visible={visibleEdit}
          onCancel={() => this.onCancel('edit')}
          title={<Text strong>Edit Candidate Result Test</Text>}
          footer={[
            <React.Fragment key="1">
              <CButton key="back" className="btn-save-primary" type="default" action={() => this.onCancel('edit')} title="Cancel"/>
              <CButton key="submit" className="btn-save-primary" type="primary" action={this.onSubmitEdit} title="Save"/>
            </React.Fragment>
          ]}
        >
          <EditResultTest 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitEdit={this.onSubmitEdit} 
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetList, unmountGetDetail } = this.props;
    unmountGetList()
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getListCandidate  : state.candidateResultTest.list,
  getDetailCandidate  : state.candidate.detail,
})

const mapDispatchToProps = {
  actionAdd         : addCandidateResultTest,
  actionUpdate      : updateCandidateResultTest,
  actionDelete      : deleteCandidateResultTest,
  actionGetList     : listCandidateResultTest,
  actionGetDetail   : detailCandidate,
  actionEnable      : enableCandidate,
  unmountGetDetail  : unmountDetailCandidate,
  unmountGetList    : unmountListCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailResultTest)
