import React, { Component } from 'react';
import moment from 'moment';
import AddAchievement from './Add';
import EditAchievement from './Edit';
import svg from '../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { addAchievement, deleteAchievement, detailCandidate, unmountDetailCandidate, updateAchievement, enableCandidate } from '../../../../redux/actions/candidate/candidateAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Space, Avatar, Typography, Descriptions, Divider, Tooltip, Empty, Modal, Tag, message } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Menu from '../Menu';
import CButton from '../../../Button'
const { Text } = Typography;
const { confirm } = Modal;
const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return <Component {...rest} />
};
class CandidateDetailAchievements extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      dataAchievement: null,
      submitLoading: false,
      visibleAdd: false,
      visibleEdit: false,
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  showModal = (type, item) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: true })
    }
    if(type === 'edit'){
      return this.setState({ 
        visibleEdit: true, 
        dataAchievement: item, 
      })
    }
  }

  onCancel = (type) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: false })
    }
    if(type === 'edit'){
      return this.setState({ 
        dataAchievement: null,
        visibleEdit: false,
      })
    }
  }

  handleDelete = (id) => {
    const { actionDelete, actionGetDetail, match: { params } } = this.props;
    confirm({
      title: 'Remove Data',
      okText: 'Yes',
      cancelText: 'No',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to remove this data?',
      onOk() {
        return actionDelete(id, () => {
          message.success('Data deleted successfully')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
    });
  }

  onSubmitAdd = () => {
    const { actionAdd, actionGetDetail, match: { params } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.candidateId = Number(params.id)
      values.year = values.year ? moment(values.year, 'YYYY').format('YYYY') : null
      return actionAdd(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleAdd: false }, () => {
            message.success('Data created successfully')
            actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  onSubmitEdit = () => {
    const { dataAchievement } = this.state;
    const { actionUpdate, actionGetDetail, match: { params } } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.id = dataAchievement.id
      values.candidateId = Number(params.id)
      values.year = values.year ? moment(values.year, 'YYYY').format('YYYY') : null
      return actionUpdate(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleEdit: false }, () => {
            message.success('Data updated successfully')
            actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }
  
  showConfirmActive = () => {
    const { actionEnable, actionGetDetail ,match: { params } } = this.props;
    confirm({
      title: 'Unable to edit profile due to candidate status is inactive. Do you want to change candidate status to active?',
      icon: <ExclamationCircleOutlined />,
      width: 700,
      onOk() {
        return actionEnable(params.id, () => {
          message.success('Successfully changed to active')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
      okText: 'Change to Active',
      onCancel() {},
    });
  };

  render() {
    const { visibleAdd, visibleEdit, submitLoading } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type={this.props.icon} />
                {
                  this.props.url.split('/')[1] === 'all-candidate' ?
                    ' All Candidates'
                  : this.props.url.split('/')[1] === 'new-candidate' ?
                    ' New Candidates'
                  : this.props.url.split('/')[1] === 'counseling-process' ?
                    ' Counseling Process'
                  : this.props.url.split('/')[1] === 'assigned-candidate' ?
                    ' Assigned Candidates'
                  :
                    ' Candidates'
                }
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to={`/${this.props.url.split('/')[1]}`}>
                  {
                    this.props.url.split('/')[1] === 'all-candidate' ?
                      'All Candidates List'
                    : this.props.url.split('/')[1] === 'new-candidate' ?
                      'New Candidates List'
                    : this.props.url.split('/')[1] === 'counseling-process' ?
                      'Counseling Process List'
                    : this.props.url.split('/')[1] === 'assigned-candidate' ?
                      'Assigned Candidates List'
                    :
                      'Candidates List'
                  }
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                      {
                        data.enabled ? 
                          <Tag color="success">Active</Tag>
                        :
                          <Tag color="error">Inactive</Tag>
                      }
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="achievements" id={data.id} email={data.email}/>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Achievements</Text>} 
              // extra={<Button key="1" className="btn-save-primary" type="primary" icon={<PlusOutlined />} onClick={data.enabled ? () => this.showModal('add') : this.showConfirmActive}>Add</Button>} 
              extra={<CButton key="1" className="btn-save-primary" type="primary-icon" icon='PlusOutlined' action={data.enabled ? () => this.showModal('add') : this.showConfirmActive} title="Add"/>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {
                    data.listAchievement.length > 0 ?
                    <React.Fragment>
                      {
                        data.listAchievement.map((item, i) => (
                          <React.Fragment key={i}>
                            <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                              <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                                {item.year ? item.year : 'N/A'}
                              </Text>
                              <Space> 
                                <Tooltip title="Edit">
                                  <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={data.enabled ? () => this.showModal('edit', item) : this.showConfirmActive} />
                                </Tooltip>
                                <Tooltip title="Remove">
                                  <DeleteOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#d8675a' }} onClick={data.enabled ? () => this.handleDelete(item.id) : this.showConfirmActive} />
                                </Tooltip>
                              </Space>
                            </div>
                            <Descriptions layout="vertical" size="small" column={2}>
                              <Descriptions.Item label="Name">
                                {item.name}
                              </Descriptions.Item>
                              <Descriptions.Item label="Issuing Organization">
                                {item.organization}
                              </Descriptions.Item>
                            </Descriptions>
                            <Divider />
                          </React.Fragment>
                        ))
                      }
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Empty description={<Text>No Data Achievements</Text>} />
                    </React.Fragment>
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          key="1"
          width={1000}
          destroyOnClose
          visible={visibleAdd}
          onCancel={() => this.onCancel('add')}
          title={<Text strong>Add Achievement</Text>}
          footer={[
            <React.Fragment key="1">
              <CButton key="back" className="btn-save-primary" type="default" action={() => this.onCancel('add')} title="Cancel"/>
              <CButton key="submit" className="btn-save-primary" type="primary" action={this.onSubmitAdd} loading={submitLoading} title="Save"/>
            </React.Fragment>
          ]}
        >
          <AddAchievement 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitAdd={this.onSubmitAdd} 
          />
        </Modal>
        <Modal
          key="2"
          centered
          width={1000}
          destroyOnClose
          visible={visibleEdit}
          onCancel={() => this.onCancel('edit')}
          title={<Text strong>Edit Achievement</Text>}
          footer={[
            <React.Fragment key="1">
              <CButton key="back" className="btn-save-primary" type="default" action={() => this.onCancel('edit')} title="Cancel"/>
              <CButton key="submit" className="btn-save-primary" type="primary" action={this.onSubmitEdit} title="Save"/>
            </React.Fragment>
          ]}
        >
          <EditAchievement 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitEdit={this.onSubmitEdit} 
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
})

const mapDispatchToProps = {
  actionAdd         : addAchievement,
  actionUpdate      : updateAchievement,
  actionDelete      : deleteAchievement,
  actionGetDetail   : detailCandidate,
  actionEnable      : enableCandidate,
  unmountGetDetail  : unmountDetailCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailAchievements)
