import React from 'react'
import { Col, Typography, Space, Divider } from 'antd'

const { Title, Text } = Typography

export default function Skill(props) {
  const { data } = props
  return (
    <React.Fragment>
      <Col span={24}>
        <Space direction='vertical' size={16} style={{ width: '100%', textAlign: 'justify', textJustify: 'inter-word' }}>
          <Space direction='vertical' size={0} style={{ width: '100%' }}>
            <Title level={4} style={{ margin: 0 }}>SKILLS</Title>
            <Divider style={{ margin: 0, borderTop: '1px solid black' }} />
          </Space>
        </Space>
      </Col>
      {
        data.listSkill.length > 0 ?
          data.listSkill.map((item, i) => (
            <Col key={i} span={24}>
              <Space direction='vertical' size={0} style={{ width: '100%', textAlign: 'justify', textJustify: 'inter-word', marginBottom: data.listSkill.length > i+1 ? -24 : 0 }}>
                <Text>
                  ● {item.name} (
                    {item.skillLevel === 'BASIC' && 'Basic'}
                    {item.skillLevel === 'INTERMEDIATE' && 'Intermediate'}
                    {item.skillLevel === 'ADVANCE' && 'Advance'}
                    {item.skillLevel === 'EXPERT' && 'Expert'}
                  )
                </Text>
              </Space>
            </Col>
          ))
        :
          <Col span={24}>
            <Text>N/A</Text>
          </Col>
      }
    </React.Fragment>
  )
}
