import React, { Component } from 'react';
import moment from 'moment';
import AddWorkExperience from './Add';
import debounce from 'lodash/debounce';
import EditWorkExperience from './Edit';
import svg from '../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { listCities, unmountListCities } from '../../../../redux/actions/master/cities/citiesAction';
import { indexBusinessSector, unmountIndexBusinessSector } from '../../../../redux/actions/master/businessSector/businessSectorAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Space, Avatar, Typography, Descriptions, Divider, Tooltip, Empty, Modal, Tag, message } from 'antd';
import { addWorkExperience, updateWorkExperience, deleteWorkExperience, detailCandidate, unmountDetailCandidate, enableCandidate } from '../../../../redux/actions/candidate/candidateAction';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Menu from '../Menu';
import CButton from '../../../Button';
const { Text } = Typography;
const { confirm } = Modal;
const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return <Component {...rest} />
};
class CandidateDetailWorkExperience extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      dataWorkExperience: null,
      submitLoading: false,
      visibleAdd: false,
      visibleEdit: false,
      startDate: null,
      endDate: null,
      current: false,
      isDate: false,
      setSelectedDate: null,
      metaCity: {
        page: 1,
        perpage: 10,
        search: ''
      }
    }
    this.onSearchCity = debounce(this.onSearchCity.bind(this), 800)
  }

  componentDidMount() {
    const { actionGetDetail, actionMasterBusinessSector, match: { params } } = this.props;
    actionGetDetail(params.id)
    actionMasterBusinessSector()
  }

  showModal = (type, item) => {
    if(type === 'add'){
      return this.setState({ visibleAdd: true })
    }
    if(type === 'edit'){
      const { metaCity } = this.state;
      const { actionGetCity } = this.props;
      metaCity.search = item.cityName
      return this.setState({ 
        visibleEdit: true, 
        dataWorkExperience: item, 
        current: item.current 
      }, () => {
        return actionGetCity(metaCity)
      })
    }
  }

  onCancel = (type) => {
    const { metaCity } = this.state;
    if(type === 'add'){
      metaCity.page = 1
      metaCity.perpage = 10
      metaCity.search = ''
      return this.setState({ 
        visibleAdd: false,
        startDate: null,
        endDate: null,
        current: false,
        isDate: false,
      })
    }
    if(type === 'edit'){
      metaCity.page = 1
      metaCity.perpage = 10
      metaCity.search = ''
      return this.setState({ 
        dataWorkExperience: null,
        visibleEdit: false,
        startDate: null,
        endDate: null,
        current: false,
        isDate: false,
      })
    }
  }

  onSearchCity = (value) => {
    const { metaCity } = this.state;
    const { actionGetCity } = this.props;
    metaCity.page = 1
    metaCity.perpage = 10
    metaCity.search = value
    return actionGetCity(metaCity)
  }

  onFocusCity = () => {
    const { metaCity } = this.state;
    const { actionGetCity } = this.props;
    return actionGetCity(metaCity)
  }

  onChangeStartDate = (date, dateString) => {
    
    const { endDate } = this.state
    this.setState({ startDate: dateString })
   
    if(endDate <= dateString){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }


  onChangeEndDate = (date, dateString) => {
    const { startDate } = this.state
    this.setState({ endDate: dateString })
    if(dateString <= startDate){
      this.setState({ isDate: true })
    }else{
      this.setState({ isDate: false })
    }
  }

  handleChecked = (e) => {
    this.setState({ current: e.target.checked })
    if(e.target.checked){
      this.setState({ endDate: null })
      this.formRef.current.setFieldsValue({
        endDate: null
      })
    }
  }

  handleDelete = (id) => {
    const { actionDelete, actionGetDetail, match: { params } } = this.props;
    confirm({
      title: 'Remove Data',
      okText: 'Yes',
      cancelText: 'No',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to remove this data?',
      onOk() {
        return actionDelete(id, () => {
          message.success('Data deleted successfully')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
    });
  }

  onSubmitAdd = () => {
    const { current } = this.state;
    const { actionAdd, actionGetDetail, match: { params } } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.current = current
      values.candidateId = Number(params.id)
      values.startDate = values.startDate ? moment(values.startDate).format('YYYY-MM') : null
      values.endDate = values.endDate ? moment(values.endDate).format('YYYY-MM') : null
      return actionAdd(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleAdd: false }, () => {
            message.success('Data created successfully')
            return actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  onSubmitEdit = () => {
    const { current, dataWorkExperience } = this.state;
    const { actionUpdate, actionGetDetail, match: { params } } = this.props;
    this.setState({ submitLoading: true })
    this.formRef.current.validateFields().then(values => {
      values.id = dataWorkExperience.id
      values.current = current
      values.candidateId = Number(params.id)
      values.startDate = values.startDate ? moment(values.startDate).format('YYYY-MM') : null
      values.endDate = values.endDate ? moment(values.endDate).format('YYYY-MM') : null
      return actionUpdate(values, () => {
        return this.setState({ submitLoading: false }, () => {
          return this.setState({ visibleEdit: false }, () => {
            message.success('Data updated successfully')
            return actionGetDetail(params.id)
          })
        })
      }, (err) => {
        return this.setState({ submitLoading: false }, () => message.error(err))
      })
    })
  }

  showConfirmActive = () => {
    const { actionEnable, actionGetDetail ,match: { params } } = this.props;
    confirm({
      title: 'Unable to edit profile due to candidate status is inactive. Do you want to change candidate status to active?',
      icon: <ExclamationCircleOutlined />,
      width: 700,
      onOk() {
        return actionEnable(params.id, () => {
          message.success('Successfully changed to active')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
      okText: 'Change to Active',
      onCancel() {},
    });
  };
  
  render() {
    const { visibleAdd, visibleEdit } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type={this.props.icon} />
                {
                  this.props.url.split('/')[1] === 'all-candidate' ?
                    ' All Candidates'
                  : this.props.url.split('/')[1] === 'new-candidate' ?
                    ' New Candidates'
                  : this.props.url.split('/')[1] === 'counseling-process' ?
                    ' Counseling Process'
                  : this.props.url.split('/')[1] === 'assigned-candidate' ?
                    ' Assigned Candidates'
                  :
                    ' Candidates'
                }
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to={`/${this.props.url.split('/')[1]}`}>
                  {
                    this.props.url.split('/')[1] === 'all-candidate' ?
                      'All Candidates List'
                    : this.props.url.split('/')[1] === 'new-candidate' ?
                      'New Candidates List'
                    : this.props.url.split('/')[1] === 'counseling-process' ?
                      'Counseling Process List'
                    : this.props.url.split('/')[1] === 'assigned-candidate' ?
                      'Assigned Candidates List'
                    :
                      'Candidates List'
                  }
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                      {
                        data.enabled ? 
                          <Tag color="success">Active</Tag>
                        :
                          <Tag color="error">Inactive</Tag>
                      }
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="workExperience" id={data.id} email={data.email} />
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Work Experience</Text>} 
              extra={<CButton key="1" className="btn-save-primary" type="primary-icon" icon="PlusOutlined" action={data.enabled ? () => this.showModal('add') : this.showConfirmActive} title="Add"/>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  {
                    data.listWorkExperience.length > 0 ?
                    <React.Fragment>
                      {
                        data.listWorkExperience.map((item, i) => (
                          <React.Fragment key={i}>
                            <div style={{ marginBottom: 11, display: 'flex', justifyContent: 'space-between' }}>
                              <Text style={{ color: '#32ab6d', fontSize: 14 }}>
                                {moment(item.startDate, 'YYYY-MM').format('MMMM YYYY')} - {item.endDate ? moment(item.endDate, 'YYYY-MM').format('MMMM YYYY') : 'Present'}
                              </Text>
                              <Space> 
                                <Tooltip title="Edit">
                                  <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={data.enabled ? () => this.showModal('edit', item) : this.showConfirmActive} />
                                </Tooltip>
                                <Tooltip title="Remove">
                                  <DeleteOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#d8675a' }} onClick={data.enabled ? () => this.handleDelete(item.id) :  this.showConfirmActive} />
                                </Tooltip>
                              </Space>
                            </div>
                            <Descriptions layout="vertical" size="small" column={2}>
                              <Descriptions.Item label="Occupation or Position Held">
                                {item.title ? item.title : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Company">
                                {item.company ? item.company : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Employment Type">
                                {
                                  item.employmentType ?
                                  <Text>
                                    {item.employmentType === "FULL_TIME" && 'Full Time'}
                                    {item.employmentType === "PART_TIME" && 'Part Time'}
                                    {item.employmentType === "FREELANCE" && 'Freelance'}
                                    {item.employmentType === "INTERNSHIP" && 'Internship'}
                                    {item.employmentType === "VOLUNTEER" && 'Volunteer'}
                                  </Text>
                                  :
                                  'N/A'
                                }
                              </Descriptions.Item>
                              <Descriptions.Item label="Location">
                                {item.cityName ? item.cityName : 'N/A'}, {item.countryName ? item.countryName : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Business or Sector">
                                {item.businessSectorName ? item.businessSectorName : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Department">
                                {item.department ? item.department : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Company Email">
                                {item.companyEmail ? item.companyEmail : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Company Website">
                                {item.website ? item.website : 'N/A'}
                              </Descriptions.Item>
                              <Descriptions.Item label="Job Description">
                                {item.jobDescription ? item.jobDescription : 'N/A'}
                              </Descriptions.Item>
                            </Descriptions>
                            <Divider />
                          </React.Fragment>
                        ))
                      }
                    </React.Fragment>
                    :
                    <React.Fragment>
                      <Empty description={<Text>No Data Work Experience</Text>} />
                    </React.Fragment>
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          key="1"
          centered
          width={1000}
          destroyOnClose
          visible={visibleAdd}
          onCancel={() => this.onCancel('add')}
          title={<Text strong>Add Work Experience</Text>}
          footer={[
            <React.Fragment key="1">
              <CButton key="back" className="btn-save-primary" type="default" action={() => this.onCancel('add')} title="Cancel"/>
              <CButton key="submit" className="btn-save-primary" type="primary" action={this.onSubmitAdd} title="Save"/>
            </React.Fragment>
          ]}
        >
          <AddWorkExperience 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onSubmitAdd={this.onSubmitAdd} 
            onFocusCity={this.onFocusCity}
            onSearchCity={this.onSearchCity}
            handleChecked={this.handleChecked}
            onChangeEndDate={this.onChangeEndDate}
            onChangeStartDate={this.onChangeStartDate}
          />
        </Modal>
        <Modal
          key="2"
          centered
          width={1000}
          destroyOnClose
          visible={visibleEdit}
          onCancel={() => this.onCancel('edit')}
          title={<Text strong>Edit Work Experience</Text>}
          footer={[
            <React.Fragment key="1">
              <CButton key="back" className="btn-save-primary" type="default" action={() => this.onCancel('edit')} title="Cancel"/>
              <CButton key="submit" className="btn-save-primary" type="primary" action={this.onSubmitEdit} title="Save"/>
            </React.Fragment>
          ]}
        >
          <EditWorkExperience 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            onFocusCity={this.onFocusCity}
            onSearchCity={this.onSearchCity}
            onSubmitEdit={this.onSubmitEdit} 
            handleChecked={this.handleChecked}
            onChangeEndDate={this.onChangeEndDate}
            onChangeStartDate={this.onChangeStartDate}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetCity } = this.props;
    unmountGetDetail()
    unmountGetCity()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
  getCity             : state.cities.list,
  masterBusinessSector: state.businessSector.master
})

const mapDispatchToProps = {
  actionAdd         : addWorkExperience,
  actionUpdate      : updateWorkExperience,
  actionDelete      : deleteWorkExperience,
  actionGetDetail   : detailCandidate,
  actionGetCity     : listCities,
  actionMasterBusinessSector: indexBusinessSector,
  actionEnable      : enableCandidate,
  unmountGetDetail  : unmountDetailCandidate,
  unmountGetCity    : unmountListCities,
  unmountMasterBusinessSector    : unmountIndexBusinessSector
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailWorkExperience)
