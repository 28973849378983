import React from 'react'
import { Col, Typography, Space, Divider } from 'antd'

const { Title, Text } = Typography

export default function Education(props) {
  const { data } = props
  return (
    <React.Fragment>
      <Col span={24}>
        <Space direction='vertical' size={16} style={{ width: '100%', textAlign: 'justify', textJustify: 'inter-word' }}>
          <Space direction='vertical' size={0} style={{ width: '100%' }}>
            <Title level={4} style={{ margin: 0 }}>EDUCATION</Title>
            <Divider style={{ margin: 0, borderTop: '1px solid black' }} />
          </Space>
        </Space>
      </Col>
      {
        data.listEducation.length > 0 ?
          data.listEducation.map((item, i) => (
            <Col key={i} span={24}>
              <Space direction='vertical' size={0} style={{ width: '100%', textAlign: 'justify', textJustify: 'inter-word' }}>
                <Space size={4}>
                  <Text strong>{item.name ? item.name : 'N/A'}</Text>
                  <Text italic>
                    {
                      item.startYear && item.current ?
                        `(${item.startYear} - Present)`
                      : item.startYear && item.endYear ?
                        `(${item.startYear} - ${item.endYear})`
                      : item.startYear || item.endYear
                    }
                  </Text>
                </Space>
                {
                  item.fieldOfStudy ?
                    <Text>{item.fieldOfStudy}</Text>
                  : null
                }
                {
                  item.gpa ?
                    <Text>{item.gpa}</Text>
                  : null
                }
                {
                  item.website ?
                    <a href={item.website} target='_blank' rel='noreferrer'>
                      <Text underline style={{ color: '#0842a0' }}>{item.website}</Text>
                    </a>
                  : null
                }
                {
                  item.experience ?
                    <Text>{item.experience}</Text>
                  : null
                }
              </Space>
            </Col>
          ))
        :
          <Col span={24}>
            <Text>N/A</Text>
          </Col>
      }
    </React.Fragment>
  )
}
