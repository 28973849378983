import React, { Component } from 'react';
import moment from 'moment';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import debounce from 'lodash/debounce';
import update from 'react-addons-update';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import BlankImage from '../../../../assets/img/blank.png';
import { Form, Card, Row, Col, Breadcrumb, PageHeader, Typography, Button, Input, Select, Radio, DatePicker, Upload, Modal, AutoComplete, InputNumber, Spin, Space, Badge, Image, Tooltip, TimePicker, message } from 'antd';
import { SoundOutlined, InfoCircleOutlined, ExclamationCircleOutlined, MinusCircleOutlined, CameraOutlined, VideoCameraOutlined, EditOutlined, DeleteOutlined, UploadOutlined, EyeOutlined } from '@ant-design/icons';
import { createJob, saveJob } from '../../../../redux/actions/jobs/jobsAction';
import { listSkills, unmountListSkill } from '../../../../redux/actions/master/skills/skillsAction';
import { masterBenefit, unmountMasterBenefit } from '../../../../redux/actions/benefit/benefitAction';
import { positionByIndustry } from '../../../../redux/actions/master/jobPositions/jobPositionsAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/master/countries/countriesAction';
import { listFieldOfStudy, unmountListFieldOfStudy } from '../../../../redux/actions/master/fieldOfStudy/fieldOfStudyAction';
import { masterJobIndustries, unmountMasterJobIndustries } from '../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import { masterEducationLevel, unmountMasterEducationLevel } from '../../../../redux/actions/master/educationLevel/educationLevelAction';
import { masterJobPostingType, unmountMasterJobPostingType } from '../../../../redux/actions/master/jobPostingtype/jobPostingTypeAction';
import { listJobDestinations, unmountListJobDestinations } from '../../../../redux/actions/master/jobDestinations/jobDestinationsAction';
import { masterDestinationCountry, unmountMasterDestinationCountry } from '../../../../redux/actions/master/destinationCountry/destinationCountryAction';
import { jobType } from '../../../../utils/constant/job';
import CButton from '../../../../components/Button';
// import { Player, BigPlayButton  } from 'video-react'
const { confirm } = Modal;
const { Text } = Typography;

class CreateEmployerJobs extends Component {
  formRef = React.createRef();
  formRefEvent = React.createRef();
  constructor(props) {
    super(props)

    this.state = {
      benefit: [],
      allowance: [],
      requirement: [],
      valueForm: null,
      isExternalJob: false,
      isFixSalary: true,
      dataBenefit: null,
      placementType: null,
      dataJobPosition: [],
      startDate: moment(),
      submitLoading: false,
      saveLoading: false,
      loadingSelect: false,
      url: 'job_employers',
      isDisplaySalary: true,
      fileList: [],
      previewImage: '',
      videoSrc: [],
      listJobStory: [],
      dataEvent: [],
      editEvent: null,
      isEditEvent: false,
      viewEvent: null,
      jobEventType: null,
      editThumbnail: false,
      onPreviewThumbnail: null,
      editBanner: false,
      onPreviewBanner: null,
      destinationCountryId: null,
      finalValues: [],
      metaFieldOfStudy: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaJobDestinations: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaSkills: {
        page: 1,
        perpage: 10,
        search: ''
      },
      metaDestinationCountry: {
        page: 1,
        perpage: 10,
        search: ''
      }
    }
    this.onSearchSkills = debounce(this.onSearchSkills.bind(this), 800)
    this.onSearchFieldOfStudy = debounce(this.onSearchFieldOfStudy.bind(this), 800)
    this.onSearchJobDestinations = debounce(this.onSearchJobDestinations.bind(this), 800)
    this.onSearchDestinationCountry = debounce(this.onSearchDestinationCountry.bind(this), 800)
  }

  componentDidMount() {
    const {
      actionGetBenefit,
      actionGetCountry,
      actionGetEducation,
      actionGetJobIndustries,
      actionGetDestinationCountry,
      actionGetJobPostingType,
    } = this.props;
    actionGetJobIndustries()
    actionGetCountry()
    actionGetEducation()
    actionGetBenefit()
    actionGetDestinationCountry()
    actionGetJobPostingType()
  }

  handleChangeJobPostingType = (_, val) => {
    this.setState({ isExternalJob: val.children === "External Job" ? true : false })
  }

  onChangeStartDate = (current) => {
    this.formRef.current.setFieldsValue({
      targetDate: null,
      // eventDate: null
    });
    this.setState({ startDate: current })
  }

  disabledDate = (current) => {
    let yesterday = moment().subtract(1, 'days');
    return !current || current.isBefore(yesterday);
  }

  disabledEndDate = (current) => {
    const { startDate } = this.state;
    return current < startDate
  }

  // Handle Data Job Destination
  onSearchJobDestinations = (value) => {
    const { metaJobDestinations } = this.state;
    const { actionGetJobDestinations } = this.props;
    metaJobDestinations.page = 1
    metaJobDestinations.perpage = 10
    metaJobDestinations.search = value
    this.setState({ loadingSelect: true })
    return actionGetJobDestinations(metaJobDestinations, () => {
      return this.setState({ loadingSelect: false })
    })
  }

  onSearchDestinationCountry = (value) => {
    const { metaDestinationCountry } = this.state;
    const { actionGetDestinationCountry } = this.props;
    metaDestinationCountry.page = 1
    metaDestinationCountry.perpage = 10
    metaDestinationCountry.search = value
    this.setState({ loadingSelect: true })
    return actionGetDestinationCountry(metaDestinationCountry, () => {
      return this.setState({ loadingSelect: false })
    })
  }

  onBlurJobDestinations = () => {
    const { unmountGetJobDestinantions } = this.props;
    return unmountGetJobDestinantions()
  }

  onBlurDestinationCountry = () => {
    const { unmountGetDestinationCountry } = this.props;
    return unmountGetDestinationCountry()
  }

  // Handle Data Field Of Study
  onSearchFieldOfStudy = (value) => {
    const { metaFieldOfStudy } = this.state;
    const { actionGetFieldOfStudy } = this.props;
    metaFieldOfStudy.page = 1
    metaFieldOfStudy.perpage = 10
    metaFieldOfStudy.search = value
    return actionGetFieldOfStudy(metaFieldOfStudy)
  }

  onFocusFieldOfStudy = () => {
    const { metaFieldOfStudy } = this.state;
    const { actionGetFieldOfStudy } = this.props;
    return actionGetFieldOfStudy(metaFieldOfStudy)
  }

  // Handle Data Skills
  onSearchSkills = (value) => {
    const { metaSkills } = this.state;
    const { actionGetSkill } = this.props;
    metaSkills.page = 1
    metaSkills.perpage = 10
    metaSkills.search = value
    return actionGetSkill(metaSkills)
  }

  onBlurSkills = () => {
    const { unmountGetSkill } = this.props;
    return unmountGetSkill()
  }

  // Handle Data Job Position
  handleChangeJobPosition = (value) => {
    const { actionGetJobPosition } = this.props;
    this.formRef.current.setFieldsValue({
      jobPositionId: null,
    });
    return actionGetJobPosition(value, (response) => {
      this.setState({ dataJobPosition: response })
    })
  }

  handleDestinationCountry = (_, val) => {
    this.setState({ destinationCountryId: Number(val.key) })
  }

  handlePlacementType = (value) => {
    this.setState({
      benefit: [],
      requirement: [],
      placementType: value
    })
    this.formRef.current.setFieldsValue({
      jobRequirement: null,
      benefit: null,
      durationOfContract: null,
      isContractExtendable: true,
      annualLeave: null,
      isPaidAnnualLeave: true,
      workingHour: null,
      workingDay: null,
      probationPeriod: null,
      isWorkingOvertime: true,
      otherWorkingSystem: null,
      listBenefit: [],
      gender: 'BOTH',
      ageStart: null,
      ageEnd: null,
      educationLevelId: null,
      fieldOfStudy: null,
      skill: [],
      otherRequirements: [],
      minExperience: null,
      certification: null,
      minHeight: null,
      minWeight: null,
      notes: null
    });
  }

  handleDisplaySalary = (value) => {
    this.setState({ isDisplaySalary: value })
  }

  handleChangeFixSalary = (value) => {
    this.setState({ isFixSalary: value })
    this.formRef.current.setFieldsValue({
      salary: null,
      maxSalary: null
    });
  }

  addBenefit = () => {
    const { dataBenefit } = this.state;
    this.setState({
      benefit: update(this.state.benefit, {
        $push: [dataBenefit]
      }),
      dataBenefit: null
    })
  }

  handleChangeBenefit = (value) => {
    this.setState({ dataBenefit: value })
  }

  handleRemoveBenefit = (index) => {
    this.setState({
      benefit: update(this.state.benefit, {
        $splice: [[index, 1]]
      })
    })
  }

  handleChangeRequirement = (_, value) => {
    this.setState({ requirement: value })
  }

  handleChangeAllowance = (value, index) => {
    const { allowance } = this.state;
    const field = this.formRef.current.getFieldValue('listBenefit')
    Object.assign(field[index], { 'allowance': null })
    this.formRef.current.setFieldsValue({
      field
    });
    allowance[index] = value
    this.setState({ allowance })
  }

  handleChangeValue = (value) => {
    if (value.title && value.dateOpened && value.targetDate) {
      this.setState({ valueForm: value })
    }
  }

  // FUNCTION JOB STORY
  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handleUploadImage() {
    const { fileList, listJobStory } = this.state
    return {
      withCredentials: true,
      accept: ".jpg, .jpeg, .png",
      beforeUpload: file => {
        const validateSize = file.size >= 1000000;
        if (validateSize) {
          message.error('Max file size is 1 MB!');
          return false
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            this.getBase64(file, (url) => {
              fileList.push(
                {
                  uid: file.uid,
                  name: file.name,
                  url: url,
                }
              )
              listJobStory.push({ file: file })
              this.setState({ fileList, listJobStory })
            })
            message.info('Upload Success!')
          }
        }
      }
    }
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  }

  handleUploadVideo() {
    const { videoSrc, listJobStory } = this.state
    return {
      withCredentials: true,
      accept: ".mp4",
      beforeUpload: file => {
        const validateSize = file.size >= 30000000;
        if (validateSize) {
          message.error('Max file size is 30 MB!');
          return false
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            this.getBase64(file, (url) => {
              videoSrc.push(
                {
                  uid: file.uid,
                  name: file.name,
                  url: url,
                }
              )
              listJobStory.splice(0, 0, { file: file })
              this.setState({ videoSrc, listJobStory })
            })
            message.info('Upload Success!')
          }
        }
      }
    }
  }

  handleRemoveFile = (e) => {
    const { fileList, videoSrc, listJobStory } = this.state
    fileList.forEach((item, i) => {
      if (item?.uid === e.uid) {
        fileList.splice(i, 1);
      }
    })
    videoSrc.forEach((item, i) => {
      if (item?.uid === e.uid) {
        videoSrc.splice(i, 1);
      }
    })
    listJobStory.forEach((item, i) => {
      if (item?.uid === e.uid || item?.file?.uid === e.uid) {
        listJobStory.splice(i, 1);
      }
    })
    this.setState({ fileList, videoSrc, listJobStory })
  }

  // FUNCTION EVENT
  showModal = (type, item, i) => {
    if (type === 'add') {
      this.setState({ visible: true })
    }
    if (type === 'edit') {
      this.setState({
        visible: true,
        editEvent: item,
        isEditEvent: true,
        indexEvent: i,
        jobEventType: item.jobEventType
      })
    }
    if (type === 'viewEvent') {
      this.setState({ visibleView: true, editEvent: item })
    }
  }

  handleChangeType = (value) => {
    this.setState({
      jobEventType: value
    })
  }

  handleChangeImage = typeImage => {
    this.setState({ isUploadImage: typeImage })
  }

  handleUpload() {
    return {
      withCredentials: true,
      accept: ".jpg, .jpeg, .png",
      beforeUpload: file => {
        const validateSize = file.size >= 1000000;
        if (validateSize) {
          message.error('Max file size 1 MB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const { isUploadImage } = this.state
          switch (isUploadImage) {
            case 'thumbnail':
              return this.setState({ thumbnailImageFile: file, isUploadImage: null, editThumbnail: true, onPreviewThumbnail: e.target.result })
            case 'banner':
              return this.setState({ bannerImageFile: file, isUploadImage: null, editBanner: true, onPreviewBanner: e.target.result })
            default:
              return null
          }
        }
        message.info('Upload Success!');
        return false;
      },
    }
  }

  handleDeleteImage = (isUploadImage) => {
    switch (isUploadImage) {
      case 'thumbnail':
        return this.setState({ thumbnailImageFile: null, isUploadImage: null, editThumbnail: null, onPreviewThumbnail: null })
      case 'banner':
        return this.setState({ bannerImageFile: null, isUploadImage: null, editBanner: null, onPreviewBanner: null })
      default:
        message.info('Upload Success!');
    }
  }

  handleDeleteEvent = (index) => {
    const { dataEvent } = this.state
    dataEvent[index] = null
    dataEvent.splice(index, 1);
    return this.setState({ dataEvent })
  }

  onFinishEvent = (values) => {
    const { dataEvent, isEditEvent, indexEvent, bannerImageFile, onPreviewBanner, thumbnailImageFile, onPreviewThumbnail } = this.state
    values.eventDate = moment(values.eventDate).format('DD/MM/YYYY')
    values.startTime = moment(values.startTime).format('HH:mm:ss')
    values.endTime = moment(values.endTime).format('HH:mm:ss')
    values.bannerImageFile = bannerImageFile
    values.bannerImageUrl = onPreviewBanner
    values.thumbnailImageFile = thumbnailImageFile
    values.thumbnailImageUrl = onPreviewThumbnail

    if (isEditEvent) {
      dataEvent[indexEvent] = values
    } else {
      dataEvent.push(values)
    }
    this.setState({ dataEvent, visible: false, isEditEvent: false, editEvent: null, thumbnailImageFile: null, editThumbnail: false, onPreviewThumbnail: null, bannerImageFile: null, editBanner: false, onPreviewBanner: null })
  }

  handleCancel = () => {
    this.setState({
      previewVisible: false,
      visibleView: false,
      visible: false,
      isEditEvent: false,
      editEvent: null,
      thumbnailImageFile: null,
      editThumbnail: false,
      onPreviewThumbnail: null,
      bannerImageFile: null,
      editBanner: false,
      onPreviewBanner: null,
    })
  }

  onSave = (values) => {
    const { actionSave, history } = this.props;
    const { url, destinationCountryId, listJobStory, dataEvent, finalValues } = this.state;
    values = values.getFieldsValue();
    values.dateOpened = values.dateOpened ? moment(values.dateOpened).format('DD/MM/YYYY') : null
    values.targetDate = values.targetDate ? moment(values.targetDate).format('DD/MM/YYYY') : null
    values.jobCountryId = destinationCountryId
    values.listJobStory = listJobStory
    values.listJobEvent = dataEvent
    finalValues.push(values)
    this.setState({ saveLoading: true })
    return actionSave(finalValues, url, () => {
      this.setState({ saveLoading: false }, () => {
        message.success('Successfully saved')
        history.push('/employer/jobs')
      })
    }, (err) => {
      this.setState({ saveLoading: false }, () => message.error(err))
    })
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    const { url, destinationCountryId, listJobStory, dataEvent, finalValues } = this.state
    values.dateOpened = values.dateOpened ? moment(values.dateOpened).format('DD/MM/YYYY') : null
    values.targetDate = values.targetDate ? moment(values.targetDate).format('DD/MM/YYYY') : null
    values.jobCountryId = destinationCountryId
    values.listJobStory = listJobStory
    values.listJobEvent = dataEvent
    finalValues.push(values)

    this.setState({ submitLoading: true })
    return actionCreate(finalValues, url, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/employer/jobs')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  onCancel = () => {
    const { history } = this.props;
    confirm({
      title: 'Are you sure?',
      icon: <ExclamationCircleOutlined />,
      content: 'By doing this, you will lose all of your ‘create a job’ data and you can’t undo this action.',
      onOk() {
        return history.push('/employer/jobs')
      }
    });
  }

  render() {
    const {
      getSkill,
      getBenefit,
      getCountry,
      getEducation,
      getFieldOfStudy,
      getJobIndustries,
      getJobDestinations,
      getDestinationCountry,
      getJobPostingType
    } = this.props;

    const {
      allowance,
      valueForm,
      isExternalJob,
      isFixSalary,
      requirement,
      loadingSelect,
      placementType,
      submitLoading,
      saveLoading,
      dataJobPosition,
      isDisplaySalary,
      visible,
      visibleView,
      fileList,
      previewVisible,
      previewImage,
      videoSrc,
      dataEvent,
      editEvent,
      jobEventType,
      isEditEvent,
      editThumbnail,
      onPreviewThumbnail,
      editBanner,
      onPreviewBanner
    } = this.state;

    if (getJobIndustries.loading || getCountry.loading || getEducation.loading || getBenefit.loading || getJobPostingType.loading) {
      return <Loading />
    }

    const uploadButtonCamera = (
      <div>
        <CameraOutlined style={{ fontSize: 25 }} />
        <div className='ant-upload-text' style={{ fontSize: 12 }}>Add Image</div>
      </div>
    )

    const uploadButtonVideo = (
      <div>
        <VideoCameraOutlined style={{ fontSize: 20 }} />
        <div className='ant-upload-text' style={{ fontSize: 12 }} >Add Video</div>
      </div>
    )

    return (
      <React.Fragment>
        <Form
          layout="vertical"
          ref={this.formRef}
          scrollToFirstError={true}
          onFinish={this.onFinish}
          onValuesChange={(_, value) => {
            this.handleChangeValue(value)
          }}
        >
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><SoundOutlined /> Job Posting</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/employer/jobs">Job Posting List</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader
                className="site-page-header"
                title='Create a Job'
                extra={[
                  <CButton key="3" className="btn-save-primary" type="default" action={this.onCancel} title="Cancel"/>,
                  <CButton key="2" className="btn-radius" type="primary" action={() => this.onSave(this.formRef.current)} loading={saveLoading} disabled={valueForm !== null ? false : true} ghost title="Save as draft"/>,
                  <CButton key="1" className="btn-radius" type="primary" htmlType="submit" loading={submitLoading} title="Submit and Propose"/>,
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>

                  <Col span={24} style={{ marginBottom: 30 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Job Opening Information</Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Job Posting Title"
                      name="title"
                      initialValue={null}
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Title must be at least 3 characters long' },
                        { max: 100, message: 'Title is too long. Maximum is 100 characters' },
                        { pattern: new RegExp("^[a-zA-Z0-9/()--'& ]+$"), message: 'Only dash (-), slash (/), bracket ( () ), aposrrophe (  ), and ampersand ( & ) are allowed”' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={isExternalJob ? 6 : 12}>
                    <Form.Item
                      label="Job Posting Type"
                      name="jobPostingTypeId"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This field is mandatory' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select an job posting type"
                        optionFilterProp="children"
                        onChange={this.handleChangeJobPostingType}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getJobPostingType?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  
                  {
                    isExternalJob ?
                      <Col span={6}>
                        <Form.Item
                          label="URL"
                          name="externalLink"
                          initialValue={null}
                          rules={[
                            { required: true, message: 'This is a required field' },
                            { pattern: /^\S*$/, message: 'No spaces allowed' },
                            { pattern: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/, message: 'Invalid url' }
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    : null
                  }

                  <Col span={12}>
                    <Form.Item
                      label="Job Industry"
                      name="jobIndustryId"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select an industry"
                        optionFilterProp="children"
                        onChange={this.handleChangeJobPosition}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getJobIndustries?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.title}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Job Type"
                      name="jobType"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select placeholder='Select a type'>
                        {
                          jobType.map(item =>
                            <Select.Option key={item.id} value={item.value}>{item.name}</Select.Option>
                          )
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Job Position"
                      name="jobPositionId"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a position"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataJobPosition?.map(item =>
                            <Select.Option key={item.id} value={item.id}>
                              {item.title}
                            </Select.Option>
                          )
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Placement Type"
                      name="placementType"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select placeholder="Select a placement type" onChange={this.handlePlacementType}>
                        <Select.Option key='1' value="DOMESTIC">Domestic</Select.Option>
                        <Select.Option key='2' value="INTERNATIONAL">International</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item
                      label="Job Placement"
                      name="jobCountryId"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        optionFilterProp="children"
                        placeholder="Select destination country" 
                        onChange={this.handleDestinationCountry}
                      >
                        {
                          getDestinationCountry.data && getDestinationCountry.data.map(item => (
                            <Select.Option key={item.id} value={item.name}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item
                      name="jobDestinationId"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                      style={{ marginTop: 30 }}
                    >
                      <Select
                        showSearch
                        filterOption={false}
                        loading={loadingSelect}
                        placeholder="Select a location"
                        onBlur={this.onBlurJobDestinations}
                        onSearch={this.onSearchJobDestinations}
                        notFoundContent={loadingSelect ? <Spin size="small" /> : null}
                      >
                        {
                          getJobDestinations.data && getJobDestinations.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Is it possible to work from home (remote)?"
                      name="isRemote"
                      initialValue={false}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Radio.Group>
                        <Radio key='1' value={true}>Yes</Radio>
                        <Radio key='2' value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Experience Level"
                      name="experienceLevel"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select placeholder='Select a level'>
                        <Select.Option key='1' value="ENTRY_LEVEL">Entry Level</Select.Option>
                        <Select.Option key='2' value="ASSOCIATE">Associate</Select.Option>
                        <Select.Option key='3' value="MID_SENIOR">Mid-Senior Level</Select.Option>
                        <Select.Option key='4' value="DIRECTOR">Director</Select.Option>
                        <Select.Option key='5' value="EXECUTIVE">Executive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Number of Position"
                      name="numberOfPosition"
                      validateFirst
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { pattern: /^[0-9]*$/, message: 'Number only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Date Opened"
                      name="dateOpened"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }}
                        disabledDate={this.disabledDate}
                        onChange={this.onChangeStartDate}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Display salary information in job listings?"
                      name="isDisplaySalary"
                      initialValue={true}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Radio.Group onChange={(e) => this.handleDisplaySalary(e.target.value)}>
                        <Radio key='1' value={true}>Yes</Radio>
                        <Radio key='2' value={false}>No</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Close Application Date"
                      name="targetDate"
                      initialValue={null}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <DatePicker
                        format={'DD/MM/YYYY'}
                        style={{ width: '100%' }}
                        disabledDate={this.disabledEndDate}
                        allowClear={false}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Payment Type"
                      name="paymentType"
                      initialValue={null}
                      rules={[
                        { required: isDisplaySalary, message: 'This is a required field' }
                      ]}
                    >
                      <Select placeholder='Select a payment type'>
                        <Select.Option key='1' value="DAILY">Per Day</Select.Option>
                        <Select.Option key='2' value="WEEKLY">Per Week</Select.Option>
                        <Select.Option key='3' value="MONTHLY">Per Month</Select.Option>
                        <Select.Option key='4' value="YEARLY">Per Year</Select.Option>
                        <Select.Option key='5' value="PROJECT">Per Project</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Salary Type"
                      name="isFixSalary"
                      initialValue={true}
                      rules={[
                        { required: isDisplaySalary, message: 'This is a required field' }
                      ]}
                    >
                      <Radio.Group onChange={(e) => this.handleChangeFixSalary(e.target.value)}>
                        <Radio key='1' value={true}>Fix Salary</Radio>
                        <Radio key='2' value={false}>Range</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item
                      label="Payroll Currency"
                      name="currencyId"
                      initialValue={null}
                      rules={[
                        { required: isDisplaySalary, message: 'This is a required field' }
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Select a currency"
                        optionFilterProp="children"
                      >
                        {
                          getCountry?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.currencyCode} - {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  {
                    isFixSalary ?
                      <Col span={12}>
                        <Form.Item
                          label="Salary"
                          name="salary"
                          validateFirst
                          initialValue={null}
                          rules={[
                            { required: isDisplaySalary, message: 'This is a required field' },
                          ]}
                        >
                          <InputNumber
                            maxLength={12}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          />
                        </Form.Item>
                      </Col>
                      :
                      <React.Fragment>
                        <Col span={6}>
                          <Form.Item
                            label="Min Salary"
                            name="salary"
                            initialValue={null}
                            rules={[
                              { required: isDisplaySalary, message: 'This is a required field' },
                            ]}
                          >
                            <InputNumber
                              maxLength={12}
                              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            label="Max Salary"
                            name="maxSalary"
                            initialValue={null}
                            rules={[
                              { required: isDisplaySalary, message: 'This is a required field' },
                            ]}
                          >
                            <InputNumber
                              maxLength={12}
                              formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                          </Form.Item>
                        </Col>
                      </React.Fragment>
                  }

                  <Col span={24} style={{ marginBottom: 30 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Description Information</Typography.Text>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Job Description"
                      name="jobDescription"
                      initialValue={null}
                      tooltip={{
                        title: 'Input job roles and responsibilities',
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { max: 8000, message: '8,000 characters only' },
                      ]}
                    >
                      <ReactQuill placeholder="Job Description" />
                    </Form.Item>
                  </Col>

                  {
                    placementType === 'DOMESTIC' ?
                      <React.Fragment>
                        <Col span={24}>
                          <Form.Item
                            label="Requirements"
                            name="jobRequirement"
                            initialValue={null}
                            tooltip={{
                              title: 'Input required and preferred job skills, and any specific candidate qualifications',
                              icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                            }}
                            rules={[
                              { max: 8000, message: '8,000 characters only' },
                            ]}
                          >
                            <ReactQuill placeholder="Requirements" />
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            label="Benefits"
                            name="benefit"
                            initialValue={null}
                            tooltip={{
                              title: 'Input company benefits details',
                              icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                            }}
                            rules={[
                              { max: 8000, message: '8,000 characters only' },
                            ]}
                          >
                            <ReactQuill placeholder="Benefits" />
                          </Form.Item>
                        </Col>
                      </React.Fragment>
                      :
                      null
                  }

                  {
                    placementType === 'INTERNATIONAL' ?
                      <React.Fragment>
                        <Col span={24} style={{ marginBottom: 30 }}>
                          <Typography.Text style={{ fontSize: 18 }}>Terms and Conditions</Typography.Text>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Duration of contract (years)"
                            name="durationOfContract"
                            validateFirst
                            initialValue={null}
                            rules={[
                              { required: true, message: 'This is a required field' },
                              { max: 2, message: '2 digits only' },
                              { pattern: /^[0-9]*$/, message: 'Number only' },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Contract is extendable"
                            name="isContractExtendable"
                            initialValue={true}
                            rules={[
                              { required: true, message: 'This is a required field' },
                            ]}
                          >
                            <Radio.Group>
                              <Radio key='1' value={true}>Yes</Radio>
                              <Radio key='2' value={false}>No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Annual Leave (days)"
                            name="annualLeave"
                            validateFirst
                            initialValue={null}
                            rules={[
                              { required: true, message: 'This is a required field' },
                              { max: 3, message: '3 digits only' },
                              { pattern: /^[0-9]*$/, message: 'Number only' },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Paid Annual Leave"
                            name="isPaidAnnualLeave"
                            initialValue={true}
                            rules={[
                              { required: true, message: 'This is a required field' },
                            ]}
                          >
                            <Radio.Group>
                              <Radio key='1' value={true}>Yes</Radio>
                              <Radio key='2' value={false}>No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Working Hours (per day)"
                            name="workingHour"
                            validateFirst
                            initialValue={null}
                            rules={[
                              { required: true, message: 'This is a required field' },
                              { max: 3, message: '3 digits only' },
                              { pattern: /^[0-9]*$/, message: 'Number only' },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Working Days (per week)"
                            name="workingDay"
                            validateFirst
                            initialValue={null}
                            rules={[
                              { required: true, message: 'This is a required field' },
                              { max: 1, message: '1 digits only' },
                              { pattern: /^[0-9]*$/, message: 'Number only' },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Probation Period (months)"
                            name="probationPeriod"
                            validateFirst
                            initialValue={null}
                            rules={[
                              { required: true, message: 'This is a required field' },
                              { max: 2, message: '2 digits only' },
                              { pattern: /^[0-9]*$/, message: 'Number only' },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Working Overtime"
                            name="isWorkingOvertime"
                            initialValue={true}
                            rules={[
                              { required: true, message: 'This is a required field' },
                            ]}
                          >
                            <Radio.Group>
                              <Radio key='1' value={true}>Yes</Radio>
                              <Radio key='2' value={false}>No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            label="Other Working System"
                            name="otherWorkingSystem"
                            initialValue={null}
                            rules={[
                              { max: 200, message: '200 characters only' },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={24} style={{ marginBottom: 30 }}>
                          <Typography.Text style={{ fontSize: 18 }}>Benefits</Typography.Text>
                        </Col>

                        <Form.List name="listBenefit">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map((field, index) => (
                                <React.Fragment key={field.fieldKey}>
                                  <Col span={7}>
                                    <Form.Item
                                      {...field}
                                      name={[index, 'name']}
                                      initialValue={null}
                                      rules={[
                                        { required: true, message: 'This is a required field' },
                                      ]}
                                    >
                                      <Select placeholder="Select a Benefit" onChange={this.handleChangeBenefit}>
                                        {
                                          getBenefit.data && getBenefit.data.map((res, i) => {
                                            const act = this.state.benefit.includes(res.name);
                                            return (
                                              <React.Fragment key={i}>
                                                {
                                                  !act &&
                                                  <Select.Option value={res.name}>
                                                    {res.name}
                                                  </Select.Option>
                                                }
                                              </React.Fragment>
                                            )
                                          })
                                        }
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                  <Col span={8}>
                                    <Form.Item
                                      {...field}
                                      noStyle
                                      name={[index, 'status']}
                                      fieldKey={[index, 'status']}
                                      initialValue={false}
                                      rules={[
                                        { required: true, message: 'This is a required field' },
                                      ]}
                                    >
                                      <Radio.Group onChange={(e) => this.handleChangeAllowance(e.target.value, index)}>
                                        <Radio key='1' value={false}>Provided, no amount</Radio>
                                        <Radio key='2' value={true}>Allowance</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  </Col>

                                  <Col span={8}>
                                    <Form.Item
                                      {...field}
                                      name={[index, 'allowance']}
                                      fieldKey={[index, 'allowance']}
                                      rules={[
                                        { required: allowance[index], message: 'This is a required field' },
                                      ]}
                                    >
                                      <InputNumber
                                        placeholder="Type an Allowance"
                                        maxLength={12}
                                        disabled={!allowance[index]}
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={allowance ? 1 : 1}>
                                    <Form.Item>
                                      <MinusCircleOutlined onClick={() => {
                                        remove(field.name);
                                        return this.handleRemoveBenefit(index)
                                      }} />
                                    </Form.Item>
                                  </Col>
                                </React.Fragment>
                              ))}
                              <Col span={24}>
                                <Form.Item>
                                  <CButton type="dashed" action={() => add()} block icon="PlusOutlined" title="Add Benefit"/>
                                </Form.Item>
                              </Col>
                            </>
                          )}
                        </Form.List>

                        <Col span={24} style={{ marginBottom: 30 }}>
                          <Typography.Text style={{ fontSize: 18 }}>Candidate Requirements</Typography.Text>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Gender"
                            name="gender"
                            initialValue="BOTH"
                            rules={[
                              { required: true, message: 'This is a required field' },
                            ]}
                          >
                            <Radio.Group>
                              <Radio key='1' value="BOTH">Male and Female</Radio>
                              <Radio key='2' value="MALE">Male</Radio>
                              <Radio key='3' value="FEMALE">Female</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            label="Start Age"
                            name="ageStart"
                            validateFirst
                            initialValue={null}
                            rules={[
                              { required: true, message: 'This is a required field' },
                              { max: 2, message: '2 digits only' },
                              { pattern: /^[0-9]*$/, message: 'Number only' },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            label="End Age"
                            name="ageEnd"
                            validateFirst
                            dependencies={['ageStart']}
                            initialValue={null}
                            rules={[
                              { required: true, message: 'This is a required field' },
                              { max: 2, message: '2 digits only' },
                              { pattern: /^[0-9]*$/, message: 'Number only' },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (!value || getFieldValue('ageStart') <= value) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error('You must enter above the age start'));
                                },
                              })
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Minimum Education Level"
                            name="educationLevelId"
                            initialValue={null}
                            rules={[
                              { required: true, message: 'This is a required field' },
                            ]}
                          >
                            <Select
                              showSearch
                              placeholder="Select an education level"
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {
                                getEducation?.data.map(item => (
                                  <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                  </Select.Option>
                                ))
                              }
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Fields of Study"
                            name="fieldOfStudy"
                            validateFirst
                            initialValue={null}
                            rules={[
                              { required: true, message: 'This is a required field' },
                              { max: 100, message: '100 characters only' }
                            ]}
                          >
                            <AutoComplete
                              style={{ width: '100%' }}
                              placeholder="Select a field of study"
                              onFocus={this.onFocusFieldOfStudy}
                              onSearch={this.onSearchFieldOfStudy}
                              notFoundContent={getFieldOfStudy.loading ? <Spin size="small" /> : null}
                              filterOption={false}
                            >
                              {
                                getFieldOfStudy.data && getFieldOfStudy.data.map((item, i) => (
                                  <AutoComplete.Option key={i} value={item.name}>
                                    {item.name}
                                  </AutoComplete.Option>
                                ))
                              }
                            </AutoComplete>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            label="Skills"
                            name="skill"
                            initialValue={[]}
                            getValueFromEvent={value => value.slice(0, 5)}
                            rules={[
                              { required: true, message: 'This is a required field' },
                            ]}
                          >
                            <Select
                              mode="tags"
                              showSearch
                              filterOption={false}
                              loading={loadingSelect}
                              onBlur={this.onBlurSkills}
                              onSearch={this.onSearchSkills}
                              notFoundContent={loadingSelect ? <Spin size="small" /> : null}
                            >
                              {
                                getSkill.data && getSkill.data.map(item => (
                                  <Select.Option key={item.id} value={item.name}>
                                    {item.name}
                                  </Select.Option>
                                ))
                              }
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            label="Other Requirements"
                            name="otherRequirements"
                            initialValue={[]}
                          >
                            <Select mode="multiple" onChange={this.handleChangeRequirement}>
                              <Select.Option key='1' value="minExperience">Minimum Experience</Select.Option>
                              <Select.Option key='2' value="certification">Certification</Select.Option>
                              <Select.Option key='3' value="minHeight">Minimum Body Height (cm)</Select.Option>
                              <Select.Option key='4' value="minWeight">Minimum Body Weight (kg)</Select.Option>
                              <Select.Option key='5' value="notes">Notes</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        {
                          requirement && requirement.map((item, i) => (
                            <React.Fragment key={i}>
                              {
                                item.value === 'minExperience' ?
                                  <Col span={12}>
                                    <Form.Item
                                      label="Minimum Experience (years)"
                                      name="minExperience"
                                      validateFirst
                                      initialValue={null}
                                      rules={[
                                        { required: true, message: 'This is a required field' },
                                        { max: 2, message: '2 digits only' },
                                        { pattern: /^[0-9]*$/, message: 'Number only' },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  :
                                  null
                              }
                              {
                                item.value === 'certification' ?
                                  <Col span={12}>
                                    <Form.Item
                                      label="Certification"
                                      name="certification"
                                      validateFirst
                                      initialValue={null}
                                      rules={[
                                        { required: true, message: 'This is a required field' },
                                        { max: 50, message: '50 characters only' },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  :
                                  null
                              }
                              {
                                item.value === 'minHeight' ?
                                  <Col span={12}>
                                    <Form.Item
                                      label="Minimum Body Height (cm)"
                                      name="minHeight"
                                      validateFirst
                                      initialValue={null}
                                      rules={[
                                        { required: true, message: 'This is a required field' },
                                        { pattern: /^[0-9]*$/, message: 'Number only' },
                                        { min: 3, message: '3 digits only' },
                                        { max: 3, message: '3 digits only' }
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  :
                                  null
                              }
                              {
                                item.value === 'minWeight' ?
                                  <Col span={12}>
                                    <Form.Item
                                      label="Minimum Body Weight (kg)"
                                      name="minWeight"
                                      validateFirst
                                      initialValue={null}
                                      rules={[
                                        { required: true, message: 'This is a required field' },
                                        { pattern: /^[0-9]*$/, message: 'Number only' },
                                        { min: 2, message: '2 digits only' },
                                        { max: 2, message: '2 digits only' },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  :
                                  null
                              }
                              {
                                item.value === 'notes' ?
                                  <Col span={24}>
                                    <Form.Item
                                      label="Notes"
                                      name="notes"
                                      initialValue={null}
                                      rules={[
                                        { required: true, message: 'This is a required field' },
                                        { max: 100, message: '100 characters only' },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  :
                                  null
                              }
                            </React.Fragment>
                          ))
                        }

                      </React.Fragment>
                      :
                      null
                  }

                  <Col span={24} style={{ marginBottom: 0 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Job Story</Typography.Text>
                  </Col>

                  <Col span={24} style={{ marginBottom: 30 }}>
                    <Typography.Text style={{ fontSize: 16 }} strong>Image</Typography.Text>
                  </Col>

                  <Col span={7} >
                    <Space direction='vertical' size={0}>
                      <Badge status='default' text='Format: .jpg .jpeg .png' style={{ color: '#8c8c8c' }} />
                      <Badge status='default' text='Minimal resolution is 300 x 300px' style={{ color: '#8c8c8c' }} />
                      <Badge status='default' text='Optimal resolution is 700 x 700px' style={{ color: '#8c8c8c' }} />
                      <Badge status='default' text='Size: Max 1Mb' style={{ color: '#8c8c8c' }} />
                    </Space>
                  </Col>

                  <Col span={17}>
                    <div style={{ height: 100, width: 100 }}>
                      <Upload
                        {...this.handleUploadImage()}
                        className='upload-job-story'
                        style={{ height: 100, width: 100 }}
                        listType='picture-card'
                        fileList={fileList}
                        onPreview={this.handlePreview}
                        onRemove={this.handleRemoveFile}
                      >
                        {
                          fileList.length < 5 && (
                            <Space direction='vertical'>
                              {fileList.length < 5 && uploadButtonCamera}
                              {fileList.length < 5 && `${fileList.length} / 5`}
                            </Space>
                          )
                        }
                      </Upload>
                      <Modal
                        centered
                        visible={previewVisible}
                        footer={null}
                        onCancel={this.handleCancel}
                      >
                        <Image src={previewImage} width={'100%'} style={{ objectFit: 'contain' }} />
                      </Modal>
                    </div>
                  </Col>

                  <Col span={24} style={{ marginTop: 30 }}>
                    <Typography.Text style={{ fontSize: 16 }} strong>Video</Typography.Text>
                  </Col>

                  <Col span={7} style={{ marginTop: 15 }}>
                    <Space direction='vertical'>
                      <Badge status='default' text='Format: .mp4' style={{ color: '#8c8c8c' }} />
                      <Badge status='default' text='Size: Max 30Mb' style={{ color: '#8c8c8c' }} />
                      <Badge status='default' text='Resolution should not exceed 1280x1280px' style={{ color: '#8c8c8c' }} />
                    </Space>
                  </Col>

                  <Col span={17}>
                    <div style={{ height: 100, width: 100 }}>
                      <Upload
                        {...this.handleUploadVideo()}
                        style={{ height: 100, width: 100 }}
                        listType='picture-card'
                        className='upload-video-job-story'
                        maxCount={1}
                        fileList={videoSrc}
                        onRemove={this.handleRemoveFile}
                      >
                        {videoSrc.length < 1 && (
                          <Space direction='vertical'>
                            {videoSrc.length < 1 && uploadButtonVideo}
                          </Space>
                        )}
                      </Upload>
                    </div>
                  </Col>

                  {/* <Divider/> */}

                  <Col span={24} style={{ marginTop: 30 }}>
                    <Typography.Text style={{ fontSize: 18 }}>Job Event</Typography.Text>
                  </Col>

                  <Col span={24}>
                    {
                      dataEvent.length > 0 ?
                        dataEvent.map((item, i) =>
                          <Row key={i} justify="space-around" align="middle" style={{ width: '100%' }}>
                            <Col span={20}>
                              <Space>
                                <Image
                                  width={50}
                                  height={50}
                                  src={item.thumbnailImageUrl ? item.thumbnailImageUrl : BlankImage}
                                  preview={false}
                                  style={{ objectFit: 'cover' }}
                                />
                                <Space direction="vertical" size={0}>
                                  <Text strong>{item.title}</Text>
                                  <Text>{item.eventDate} {item.startTime} {item.endTime}</Text>
                                  <Text>{item.jobEventType}</Text>
                                </Space>
                              </Space>
                            </Col>
                            <Col span={4} style={{ float: 'right' }}>
                              <Space style={{ float: 'right' }}>
                                <Tooltip title="View Detail">
                                  <EyeOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={() => this.showModal('viewEvent', item, i)} />
                                </Tooltip>
                                <Tooltip title="Edit">
                                  <EditOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={() => this.showModal('edit', item, i)} />
                                </Tooltip>
                                <Tooltip title="Remove">
                                  <DeleteOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#d8675a' }} onClick={() => this.handleDeleteEvent(i)} />
                                </Tooltip>
                              </Space>
                            </Col>
                          </Row>
                        )
                        : null
                    }
                  </Col>

                  {/* <Divider/> */}
                  <Col span={24} style={{ marginTop: 16 }}>
                    <Form.Item >
                      <CButton type="dashed" block icon="PlusOutlined" action={() => this.showModal('add', null, null)} title="Add Job Event"/>
                    </Form.Item>
                  </Col>

                  <Modal
                    title={isEditEvent ? "Update Job Event" : "Add Job Event"}
                    visible={visible}
                    footer={false}
                    onCancel={this.handleCancel}
                    destroyOnClose
                    width={750}
                  >
                    <Form layout="vertical" ref={this.formRefEvent} onFinish={this.onFinishEvent} >
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Form.Item
                            label="Organizer"
                            name="organizerName"
                            initialValue={editEvent?.organizerName}
                            validateFirst
                            rules={[
                              { required: true, message: 'This field is mandatory' },
                              { min: 5, message: 'At least 5 characters' },
                              { max: 50, message: '50 characters only' },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Event Type"
                            name="jobEventType"
                            initialValue={editEvent?.jobEventType}
                            rules={[
                              { required: true, message: 'This is a required field' },
                            ]}
                          >
                            <Select
                              placeholder="Select Event Type"
                              optionFilterProp="children"
                              onChange={this.handleChangeType}
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              <Select.Option key='1' value="ONLINE">Online</Select.Option>
                              <Select.Option key='2' value="OFFLINE">Offline</Select.Option>
                              <Select.Option key='3' value="HYBRID">Hybrid</Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            label="Title"
                            name="title"
                            initialValue={editEvent?.title}
                            rules={[
                              { required: true, message: 'This field is mandatory' },
                              { min: 5, message: 'At least 5 characters' },
                              { max: 250, message: '250 characters only' },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12}>
                          <Form.Item
                            label="Date"
                            name="eventDate"
                            initialValue={editEvent?.eventDate ? moment(editEvent?.eventDate, ('DD/MM/YYYY')) : null}
                            rules={[
                              { required: true, message: 'This is a required field' },
                            ]}
                          >
                            <DatePicker
                              format={'DD/MM/YYYY'}
                              style={{ width: '100%' }}
                              disabledDate={this.disabledEndDate}
                              allowClear={false}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            label="Start Time"
                            name="startTime"
                            placeholder="StartTime"
                            initialValue={editEvent?.startTime ? moment(editEvent?.startTime, 'HH:mm') : null}
                            rules={[
                              { required: true, message: 'This is a required field' },
                            ]}
                          >
                            <TimePicker style={{ width: '100%' }} format="HH:mm" />
                          </Form.Item>
                        </Col>

                        <Col span={6}>
                          <Form.Item
                            label="End Time"
                            name="endTime"
                            placeholder="EndTime"
                            initialValue={editEvent?.endTime ? moment(editEvent?.endTime, 'HH:mm') : null}
                          // rules={[
                          //   { required: true, message: 'This is a required field' },
                          // ]}
                          >
                            <TimePicker style={{ width: '100%' }} format="HH:mm" />
                          </Form.Item>
                        </Col>

                        <Col span={12} >
                          <Form.Item label="Thumbnail Image" name="thumbnailImageUrl">
                            {
                              editEvent?.thumbnailImageUrl ?
                                <Form.Item>
                                  <Image width={100} height={100} src={editThumbnail ? onPreviewThumbnail : editEvent?.thumbnailImageUrl ? editEvent?.thumbnailImageUrl : null} style={{ objectFit: 'cover' }} />
                                </Form.Item>
                                :
                                editThumbnail ?
                                  <Form.Item>
                                    <Image width={100} height={100} src={editThumbnail ? onPreviewThumbnail : null} style={{ objectFit: 'cover' }} />
                                  </Form.Item>
                                  :
                                  null
                            }
                            <Space>
                              <Upload {...this.handleUpload()} onChange={() => this.handleChangeImage('thumbnail')} showUploadList={false} >
                                <Button className="btn-radius" icon={<UploadOutlined />}>{editEvent?.thumbnailImageUrl ? 'Change Image' : editThumbnail ? 'Change Image' : 'Upload a Image'}</Button>
                              </Upload>
                              {
                                editThumbnail ?
                                  <Button className="btn-radius" icon={<DeleteOutlined />} type="danger" ghost onClick={() => this.handleDeleteImage('thumbnail')}>Delete</Button>
                                  : null
                              }
                            </Space>
                          </Form.Item>
                        </Col>

                        <Col span={12} >
                          <Form.Item label="Banner Image" name="bannerImageUrl">
                            {
                              editEvent?.bannerImageUrl ?
                                <Form.Item>
                                  <Image width={100} height={100} src={editBanner ? onPreviewBanner : editEvent?.bannerImageUrl ? editEvent?.bannerImageUrl : null} style={{ objectFit: 'cover' }} />
                                </Form.Item>
                                :
                                editBanner ?
                                  <Form.Item>
                                    <Image width={100} height={100} src={editBanner ? onPreviewBanner : null} style={{ objectFit: 'cover' }} />
                                  </Form.Item>
                                  :
                                  null
                            }
                            <Space>
                              <Upload {...this.handleUpload()} onChange={() => this.handleChangeImage('banner')} showUploadList={false}>
                                <Button className="btn-radius" icon={<UploadOutlined />}>{editEvent?.bannerImageUrl ? 'Change Image' : editBanner ? 'Change Image' : 'Upload a Image'}</Button>
                              </Upload>
                              {
                                editBanner ?
                                  <Button className="btn-radius" icon={<DeleteOutlined />} type="danger" ghost onClick={() => this.handleDeleteImage('banner')}>Delete</Button>
                                  : null
                              }
                            </Space>
                          </Form.Item>
                        </Col>

                        <Col span={24}>
                          <Form.Item
                            label="Detail"
                            name="detail"
                            initialValue={editEvent?.detail ? editEvent?.detail : null}
                            tooltip={{
                              icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                            }}
                            rules={[
                              { required: true, message: 'This field is mandatory' },
                              { min: 10, message: 'At least 10 characters' },
                              { max: 1000, message: '1,000 characters only' },
                            ]}
                          >
                            <ReactQuill placeholder="Job Description" />
                          </Form.Item>
                        </Col>

                        {
                          jobEventType === 'ONLINE' || jobEventType === 'HYBRID' ?
                            <Col span={24}>
                              <Form.Item
                                label="Event Link"
                                name="eventLink"
                                initialValue={editEvent?.eventLink}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            : null
                        }

                        {
                          jobEventType === 'OFFLINE' || jobEventType === 'HYBRID' ?
                            <Col span={24}>
                              <Form.Item
                                label="Event Address"
                                name="eventAddress"
                                initialValue={editEvent?.eventAddress}
                              >
                                <Input />
                              </Form.Item>
                            </Col>
                            : null
                        }

                        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                          <Form.Item>
                            <Space>
                              <CButton className="btn-save-primary" type="default" action={() => window.history.back()} title="Cancel"/>
                              <CButton className="btn-save-primary" type="primary" htmlType="submit" title="Save"/>
                            </Space>
                          </Form.Item>
                        </Col>

                      </Row>
                    </Form>
                  </Modal>

                  <Modal
                    title="Job Event Detail"
                    visible={visibleView}
                    footer={false}
                    onCancel={this.handleCancel}
                    destroyOnClose
                    width={750}
                  >
                    <Row gutter={[16, 16]} >
                      <Col span={12}>
                        <Space direction='vertical'>
                          <Text>Organizer</Text>
                          <Text strong>{editEvent?.organizerName}</Text>
                        </Space>
                      </Col>
                      <Col span={12}>
                        <Space direction='vertical'>
                          <Text>Event Type</Text>
                          <Text strong>{editEvent?.jobEventType}</Text>
                        </Space>
                      </Col>

                      <Col span={24}>
                        <Space direction='vertical'>
                          <Text>Title</Text>
                          <Text strong>{editEvent?.title}</Text>
                        </Space>
                      </Col>

                      <Col span={12}>
                        <Space direction='vertical'>
                          <Text>Date</Text>
                          <Text strong>{editEvent?.eventDate}</Text>
                        </Space>
                      </Col>

                      <Col span={6}>
                        <Space direction='vertical'>
                          <Text>Start Time</Text>
                          <Text strong>{editEvent?.startTime}</Text>
                        </Space>
                      </Col>

                      <Col span={6}>
                        <Space direction='vertical'>
                          <Text>End Time</Text>
                          <Text strong>{editEvent?.endTime}</Text>
                        </Space>
                      </Col>

                      <Col span={12} >
                        <Space direction='vertical'>
                          <Text>Thumbnail Image</Text>
                          {
                            editEvent?.thumbnailImageUrl ?
                              <Image width={100} height={100} src={editEvent?.thumbnailImageUrl} style={{ objectFit: 'cover' }} />
                              :
                              'N/A'
                          }
                        </Space>
                      </Col>

                      <Col span={12} >
                        <Space direction='vertical'>
                          <Text>Banner Image</Text>
                          {
                            editEvent?.bannerImageUrl ?
                              <Image width={100} height={100} src={editEvent?.bannerImageUrl} style={{ objectFit: 'cover' }} />
                              :
                              'N/A'
                          }
                        </Space>
                      </Col>

                      <Col span={24}>
                        <Space direction='vertical'>
                          <Text>Detail</Text>
                          <div dangerouslySetInnerHTML={{ __html: editEvent?.detail }} style={{ fontWeight: 'bold' }}></div>
                        </Space>
                      </Col>

                      {
                        jobEventType === 'ONLINE' || jobEventType === 'HYBRID' ?
                          <Col span={24}>
                            <Space direction='vertical'>
                              <Text>Event Link</Text>
                              <Text strong>{editEvent?.eventLink ? editEvent?.eventLink : 'N/A'}</Text>
                            </Space>
                          </Col>
                          : null
                      }

                      {
                        jobEventType === 'OFFLINE' || jobEventType === 'HYBRID' ?
                          <Col span={24}>
                            <Space direction='vertical'>
                              <Text>Event Address</Text>
                              <Text strong>{editEvent?.eventAddress ? editEvent?.eventAddress : 'N/A'}</Text>
                            </Space>
                          </Col>
                          : null
                      }
                    </Row>
                  </Modal>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const {
      unmountGetJobIndustries,
      unmountGetJobDestinantions,
      unmountGetCountry,
      unmountGetEducation,
      unmountGetFieldOfStudy,
      unmountGetSkill,
      unmountGetBenefit,
      unmountGetDestinationCountry,
      unmountGetJobPostingType
    } = this.props;
    unmountGetJobIndustries()
    unmountGetJobDestinantions()
    unmountGetCountry()
    unmountGetEducation()
    unmountGetFieldOfStudy()
    unmountGetSkill()
    unmountGetBenefit()
    unmountGetDestinationCountry()
    unmountGetJobPostingType()
  }
}

const mapStateToProps = (state) => ({
  getSkill: state.skills.list,
  getBenefit: state.benefits.master,
  getCountry: state.countries.master,
  getFieldOfStudy: state.fieldOfStudy.list,
  getJobDestinations: state.jobDestinations.list,
  getJobIndustries: state.jobIndustries.master,
  getEducation: state.educationLevel.master,
  getDestinationCountry: state.destinationCountry.master,
  getJobPostingType: state.jobPostingType.master


})

const mapDispatchToProps = {
  actionCreate: createJob,
  actionSave: saveJob,
  actionGetJobIndustries: masterJobIndustries,
  actionGetJobPosition: positionByIndustry,
  actionGetCountry: masterCountries,
  actionGetJobDestinations: listJobDestinations,
  actionGetBenefit: masterBenefit,
  actionGetEducation: masterEducationLevel,
  actionGetFieldOfStudy: listFieldOfStudy,
  actionGetSkill: listSkills,
  actionGetJobPostingType: masterJobPostingType,
  unmountGetJobIndustries: unmountMasterJobIndustries,
  unmountGetJobDestinantions: unmountListJobDestinations,
  unmountGetBenefit: unmountMasterBenefit,
  unmountGetCountry: unmountMasterCountries,
  unmountGetEducation: unmountMasterEducationLevel,
  unmountGetFieldOfStudy: unmountListFieldOfStudy,
  unmountGetSkill: unmountListSkill,
  actionGetDestinationCountry: masterDestinationCountry,
  unmountGetDestinationCountry: unmountMasterDestinationCountry,
  unmountGetJobPostingType: unmountMasterJobPostingType
}


export default connect(mapStateToProps, mapDispatchToProps)(CreateEmployerJobs)
