import React from 'react'
import moment from 'moment'
import { Col, Typography, Space, Divider } from 'antd'

const { Title, Text } = Typography

export default function Training(props) {
  const { data } = props
  return (
    <React.Fragment>
      <Col span={24}>
        <Space direction='vertical' size={16} style={{ width: '100%', textAlign: 'justify', textJustify: 'inter-word' }}>
          <Space direction='vertical' size={0} style={{ width: '100%' }}>
            <Title level={4} style={{ margin: 0 }}>TRAINING & CERTIFICATION</Title>
            <Divider style={{ margin: 0, borderTop: '1px solid black' }} />
          </Space>
        </Space>
      </Col>
      {
        data.listTraining.length > 0 ?
          data.listTraining.map((item, i) => (
            <Col key={i} span={24}>
              <Space direction='vertical' size={0} style={{ width: '100%', textAlign: 'justify', textJustify: 'inter-word' }}>
                <Text strong>{item.name} ({item.organization})</Text>
                {
                  item.issueDate && item.expDate ?
                    <Text>{moment(item.issueDate, 'YYYY-MM').format('MMMM YYYY')} - {moment(item.expDate, 'YYYY-MM').format('MMMM YYYY')}</Text>
                  : item.issueDate && item.isExp ?
                    <Text>{moment(item.issueDate, 'YYYY-MM').format('MMMM YYYY')} - No Expiration Date</Text>
                  : null
                }
                {
                  item.credentialId ?
                    <Text>{item.credentialId}</Text>
                  : null
                }
                {
                  item.credentialUrl ?
                    <a href={item.credentialUrl} target='_blank' rel='noreferrer'>
                      <Text underline style={{ color: '#0842a0' }}>{item.credentialUrl}</Text>
                    </a>
                  : null
                }
              </Space>
            </Col>
          ))
        :
          <Col span={24}>
            <Text>N/A</Text>
          </Col>
      }
    </React.Fragment>
  )
}
