import React, { Component } from 'react';
import { CameraOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Row, Col, Badge, Upload, Typography, Space, Image, Modal, message } from 'antd';
import CButton from '../../../../components/Button';
export default class EditGallery extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      fileList: [],
      previewImage: '',
      previewVisible: false,
      videoSrc: [],
      submitLoading: false
    }
  }

  componentDidMount() {
    const { actionGetGallery } = this.props;
    const { fileList } = this.state;
    return actionGetGallery((response) => {
      response.data.listMedia.forEach(item => {
        fileList.push(
          {
            uid: item.id,
            id: item.id,
            name: item.fileName,
            url: item.fileUrl,
            size: item.fileSize,
            extension: item.fileExtension
          }
        )
        this.setState({fileList})
      })
      this.setState({
        videoSrc: response.data.videoUrl ? [{id: response.data.employersId, name: response.data.video, url: response.data.videoUrl}] : []
      })
    })
  }

  handleChangeImage = typeImage => {
    this.setState({ isUploadImage: typeImage })
  }

  getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await this.getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  }

  handleUploadImage(){
    const { fileList } = this.state
    return {
      withCredentials: true,
      accept: ".jpg, .jpeg, .png",
      beforeUpload: file => {
        const validateSize = file.size >= 1000000;
        if (validateSize) {
          message.error('Max file size is 1 MB!');
        }else{
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            this.getBase64(file, (url) => {
              fileList.push(
                {
                  uid: file.uid,
                  name: file.name,
                  url: url,
                  file: file
                }
              )
              this.setState({ fileList })
            })
            message.info('Upload Success!')
          }
        }
      }
    }
  }

  handleUploadVideo(){
    const { videoSrc } = this.state
    return {
      withCredentials: true,
      accept: ".mp4",
      beforeUpload: file => {
        const validateSize = file.size >= 30000000;
        if (validateSize) {
          message.error('Max file size is 30 MB!');
        }else{
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            this.getBase64(file, (url) => {
              videoSrc.push(
                {
                  uid: file.uid,
                  name: file.name,
                  url: url,
                  file: file
                }
              )
              this.setState({ videoSrc  })
            })
            message.info('Upload Success!')
          }
        }
      }
    }
  }

  handleRemoveFile = (e) => {
    const { fileList, videoSrc } = this.state
    fileList.forEach((item,i) =>{
      if(item?.uid === e.uid){
        fileList.splice(i, 1);
      }
    })
    videoSrc.forEach((item,i) =>{
      if(item?.uid === e.uid){
        videoSrc.splice(i, 1);
      }
    })
    this.setState({ fileList, videoSrc })
  }

  handleCancelPreview = () => {
    this.setState({ visible: false, previewVisible: false, visibleView: false })
  }

  onSubmit = () => {
    const { fileList, videoSrc } = this.state;
    const { actionUpdateGallery, handleCancel } = this.props;
    const value = [{
      videoFile: videoSrc,
      listMedia: fileList
    }]
    if(fileList.length > 0 || videoSrc.length > 0){
      this.setState({ submitLoading: true })
      return actionUpdateGallery(value, () => {
        this.setState({ submitLoading: false }, () => {
          message.success('Data updated successfully')
          return handleCancel()
        })
      }, (err) => {
        this.setState({ submitLoading: false }, () => {
          return message.error(err)
        })
      })
    }else{
      message.error('at least add 1 photo or 1 video')
    }  
    
  }

  onCancel = () => {
    const { handleCancel } = this.props;
    return handleCancel()
  }

  render() {
    const { fileList, previewImage, previewVisible, videoSrc, submitLoading } = this.state;
    const uploadButtonCamera = (
      <div>
        <CameraOutlined style={{fontSize: 25}}/>
        <div className='ant-upload-text' style={{fontSize: 12}}>Add Image</div>
      </div>
    )

    const uploadButtonVideo = (
      <div>
        <VideoCameraOutlined style={{fontSize: 20}}/>
        <div className='ant-upload-text' style={{fontSize: 12}} >Add Video</div>
      </div>
    )

    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
        <Col span={24} style={{ marginBottom: 30}}>
          <Typography.Text style={{ fontSize: 16}} strong>Image</Typography.Text>
        </Col>

        <Col span={7} >
          <Space direction='vertical' size={0}>
            <Badge status='default' text='Format: .jpg .jpeg .png' style={{color: '#8c8c8c'}}/>
            <Badge status='default' text='Minimal resolution is 300 x 300px' style={{color: '#8c8c8c'}}/>
            <Badge status='default' text='Optimal resolution is 700 x 700px' style={{color: '#8c8c8c'}}/>
            <Badge status='default' text='Size: Max 1Mb' style={{color: '#8c8c8c'}}/>
          </Space>
        </Col>

        <Col span={17}>
          <div style={{height: 100, width: 100}}>
            <Upload 
              {...this.handleUploadImage()}
              className='upload-job-story'
              style={{ height: 100, width: 100 }}
              listType='picture-card'
              fileList={fileList}
              onPreview={this.handlePreview}
              onRemove={this.handleRemoveFile}
            >
              {fileList.length < 5 && ( 
                <Space direction='vertical'>
                  {fileList.length < 5 && uploadButtonCamera}
                  {fileList.length < 5 && `${fileList.length} / 5`}
                </Space>
              )}
            </Upload>
            <Modal 
              centered
              visible={previewVisible}
              footer= {null}
              onCancel={this.handleCancelPreview}
            >
              <Image src={previewImage} width={'100%'} style={{objectFit: 'contain'}}/>
            </Modal>  
          </div>
        </Col>

        <Col span={24} style={{ marginTop: 30}}>
          <Typography.Text style={{ fontSize: 16}} strong>Video</Typography.Text>
        </Col>

        <Col span={7} style={{marginTop: 15}}>
          <Space direction='vertical'>
            <Badge status='default' text='Format: .mp4' style={{color: '#8c8c8c'}}/>
            <Badge status='default' text='Size: Max 30Mb' style={{color: '#8c8c8c'}}/>
            <Badge status='default' text='Resolution should not exceed 1280x1280px' style={{color: '#8c8c8c'}}/>
          </Space>
        </Col>

        <Col span={17}>
          <div style={{height: 100, width: 100}}>
            <Upload
              {...this.handleUploadVideo()}
              style={{height: 100, width: 100}}
              listType='picture-card'
              className='upload-video-job-story'
              maxCount={1}
              fileList={videoSrc}
              onRemove={this.handleRemoveFile}
            >
              {videoSrc.length < 1 && ( 
                <Space direction='vertical'>
                  {videoSrc.length < 1 && uploadButtonVideo}
                </Space>
              )}
            </Upload>
          </div>
        </Col>
        
        <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Space>
            <CButton className="btn-cancel-danger" type="danger" action={this.onCancel} title="Cancel"/>
            <CButton className="btn-save-primary" type="primary" action={this.onSubmit} loading={submitLoading} title="Update"/>
          </Space>
        </Col>
        </Row>
      </React.Fragment>
    )
  }
}