import React from 'react'
import { Button } from 'antd'

export default function ComponentButton(props) {
  const Icon = ({ type, ...rest }) => {
    const icons = require(`@ant-design/icons`);
    const Component = icons[type];
    return <Component {...rest} />
  };
  const { className, htmlType, type, size, action, title, disabled, icon} = props
  return (
    <React.Fragment>
      {
        type === 'primary' ?
          <Button 
            className={`border-radius-6 ${className}`}
            htmlType={htmlType} 
            type="primary"
            size={size}
            onClick={action}
            disabled={disabled}
          >
            {title}
          </Button>
        : type === 'default' ?
          <Button 
            className={`border-radius-6 ${className}`}
            type="default" 
            size={size}
            onClick={action}
            disabled={disabled}
            // ghost
          >
           {title}
          </Button>
        : type === 'primary-icon' ?
          <Button 
            className={`border-radius-6 ${className}`}
            htmlType={htmlType} 
            type="primary"
            size={size}
            onClick={action}
          >
            <Icon type={icon}/> {title}
          </Button>
        : type === 'default-icon' ?
          <Button 
            className={`border-radius-6 ${className}`}
            type="default" 
            size={size}
            onClick={action}
            disabled={disabled}
            // ghost
          >
            <Icon type={icon}/> {title}
          </Button>
        : type === 'dashed' ?
          <Button 
            className={`border-radius-6 ${className}`}
            htmlType={htmlType} 
            type="dashed" 
            size={size}
            onClick={action}
            style={{width: '100%'}}
          >
            <Icon type={icon}/> {title}
          </Button>
        : type === 'danger' ?
          <Button 
            className={`border-radius-6 ${className}`}
            htmlType={htmlType} 
            type="default" 
            size={size}
            onClick={action}
            style={{ color:'red' }}
          >
            {title}
          </Button>
        :
          null
      }
    </React.Fragment>
  )
}
