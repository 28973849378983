import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { columns } from './columns';
import Profile from './Profile'
import { UserSwitchOutlined, SearchOutlined, FileExcelOutlined, SoundOutlined, FilterOutlined } from '@ant-design/icons';
import { PageHeader, Row, Col, Table, Breadcrumb, Pagination, Button, Modal, Form , Space, Timeline, Empty, Select, AutoComplete, Input, Popover, Checkbox, Tooltip, DatePicker, Typography, Tag, Divider, Spin, Radio, message } from 'antd';
import { listCounselingProcess, unmountListCounselingProcess, createNotes, updateStatusCounseling, assignToJob, exportData, updateStatusByFilter, assignToJobByFilter, exportDataByFilter } from '../../../redux/actions/counselingProcess/counselingProcessAction';
import { masterEducationLevel, unmountMasterEducationLevel } from '../../../redux/actions/master/educationLevel/educationLevelAction';
import { detailCandidate, unmountDetailCandidate } from '../../../redux/actions/candidate/candidateAction';
import { listJobs, unmountListJobs } from '../../../redux/actions/jobs/jobsAction';
import { download } from '../../../utils/document';
import CButton from '../../../components/Button';
const { RangePicker } = DatePicker;
const { Text } = Typography;
const { confirm, warning } = Modal;
const { TextArea } = Input;

class CounselingProcessList extends Component {
  formRef = React.createRef()
  formAssignRef = React.createRef()
  formBulkRef = React.createRef()
  formBulkRef2 = React.createRef()
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      visibleAction: false,
      visibleAction2: false,
      visibleAssignJob: false,
      selectedRows: [],
      selectedRowKeys: [],
      submitLoading: false,
      url: 'job_admin',
      meta: {
        page: 1,
        perpage: 20
      },
      metaJob: {
        page: 1,
        perpage: 10,
        search: '',
        status: 'ACTIVE'
      },
      candidateNotes: [],
      candidateId: null,
      candidateNumber: null, 
      candidateName: null,
      jobId: null,
      valuesBulk: null,
      educationLevel: null,
      optionExport: false
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
    this.onSearchJob = debounce(this.onSearchJob.bind(this), 800)
  }

  componentDidMount() {
    const { meta } = this.state
    const { actionGetData, actionGetEducationLevel } = this.props
    actionGetData(meta)
    actionGetEducationLevel()
  }

  onRegistered = value => {
    const { actionGetData } = this.props
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta)
  }

  handleRangePicker = (dates, dateStrings) => {
    const date = {
      'startDate': dateStrings[0],
      'endDate': dateStrings[1]
    }
    return this.onRegistered(date)
  }

  handleFilterClear = () => {
    const { actionGetData } = this.props
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    delete meta.educationLevelId
    delete meta.gender
    delete meta.age
    delete meta.counselingDate
    delete meta.experience
    delete meta.counselingStatus
    this.formRef.current.setFieldsValue({
      educationLevelId: null,
      gender: null,
      age: null,
      counselingDate: null,
      experience: null,
      counselingStatus: null
    })
    this.setState({ meta })
    return actionGetData(meta)
  }

  handleFilterEducation = value => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.educationLevelId = value
  }

  handleFilterGender = value => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.gender = value.toString()
  }

  handleFilterAge = value => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.age = value
  }

  handleFilterCounselingDate = value => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.counselingDate = moment(value).format('DD/MM/YYYY')
  }

  handleFilterExperience = value => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.experience = value
  }

  handleFilterStatus = value => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.counselingStatus = value.toString()
  }

  handleAddFilter = () => {
    const { actionGetData } = this.props
    const { meta } = this.state
    this.setState({ visible: false })
    return actionGetData(meta)
  }

  handleOpenChange = (newOpen) => {
    this.setState ({visible: newOpen});
  };

  handleCloseTag = (value) => {
    const { actionGetData } = this.props
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    delete meta[value]
    this.formRef.current.setFieldsValue({
      [value]: null
    })
    this.setState({ meta })
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta } = this.state
    const { actionGetData } = this.props
    meta.page = 1
    meta.perpage = 20
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  pagination = (page, perpage) => {
    const { actionGetData } = this.props
    const { meta } = this.state
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  onClickRow = (record) => {
    this.props.history.push(`/counseling-process/detail/${record.candidateId}/biodata`)
    localStorage.setItem("status", "counseling-process")
  }

  onChangeCheck = (selectedRowKeys_) => {
    const { selectedRowKeys } = this.state
    const data = selectedRowKeys.concat(selectedRowKeys_)
    const uniqueData = data.filter((val,id,array) => array.indexOf(val) === id)
    this.setState({ selectedRowKeys: uniqueData })
  }

  onSelectCheck = (record, selected) => {
    const { selectedRows, selectedRowKeys } = this.state
    if(selected){
      selectedRows.push({ id: record.candidateId, status: record.counselingStatus, candidateNumber: record.candidateNumber, candidateName: record.fullName })
    }else{
      const objWithIdIndex = selectedRows.findIndex((obj) => obj.id === record.candidateId)
      if(objWithIdIndex > -1) {
        selectedRows.splice(objWithIdIndex, 1)
        selectedRowKeys.splice(objWithIdIndex, 1)
      }
    }
    this.setState({ selectedRows, selectedRowKeys })
  }

  onSelectCheckAll = (selected, selectedRows_, changeRows) => {
    const { selectedRows, selectedRowKeys } = this.state
    if(selected){
      changeRows.forEach((item) => {
        selectedRows.push({ id: item.candidateId, status: item.counselingStatus, candidateNumber: item.candidateNumber, candidateName: item.fullName })
      })
    }else{
      changeRows.forEach((item) => {
        const objWithIdIndex = selectedRows.findIndex((obj) => obj.id === item.candidateId)
        if(objWithIdIndex > -1) {
          selectedRows.splice(objWithIdIndex, 1)
          selectedRowKeys.splice(objWithIdIndex, 1)
        }
      })
    }
    this.setState({ selectedRows, selectedRowKeys })
  }
  
  showModalNotes = (val, candidateId) => {
    this.setState({ visibleNotes: true, candidateNotes: val, candidateId: candidateId })
  };

  showModalAssignJob = (candidateId, candidateNumber, candidateName) => {
    this.setState({ visibleAssignJob: true, candidateId: candidateId, candidateNumber: candidateNumber, candidateName: candidateName })
  }

  showModalApplicant = (id) => {
    const { actionGetProfile } = this.props;
    const { candidateId } = this.state;
    this.setState({ visibleApplicant: true, visibleAssignJob : false })
    actionGetProfile(id ? id : candidateId)
  }

  showModalAction = () => {
    this.setState({ visibleAction: true })
  };
  
  handleCancel = (status) => {
    const { unmountGetProfile } = this.props
    if(status === 'profile'){
      this.setState({ visibleNotes: false, visibleAssignJob: true, visibleApplicant : false, visibleAction: false, visibleAction2: false })
      unmountGetProfile()
    }else{
      this.setState({ visibleNotes: false, visibleAssignJob: false, visibleApplicant : false, visibleAction: false, visibleAction2: false, candidateId: null, jobId: null })
    }
  }

  onFinishNotes = (value) => {
    const { actionCreateNotes, actionGetData } = this.props
    const { candidateId, meta} = this.state
    value.candidateId = candidateId
    return this.setState({ submitLoading: true }, () => {
      return actionCreateNotes(value, response => {
        if(response.code === '1000'){
          setTimeout(() => {
            this.setState({ submitLoading: false, visibleNotes: false })
            message.success('successfully added notes!')
          }, actionGetData(meta, response => {
          }), 1000)
        }else{
          return this.setState({ submitLoading: false, visibleNotes: false }, () => message.error(response.message))
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visibleNotes: false }, () => message.error(err))
      })
    })
  }

  modalSetOnProgress = (id) => {
    const self = this;
    const { meta, selectedRowKeys } = this.state;
    const { actionUpdateStatus, actionGetData } = this.props;
    confirm({
      title: 
        id ? 
          <Text>The candidate will be changed to ‘<Text strong style={{ color: '#4273B9' }}>Re-invite to counsel</Text>’</Text> 
        : 
          <Text>The status will be changed to ‘<Text strong style={{ color: '#4273B9' }}>Re-invite to counsel</Text>’ for the data you selected.</Text>,
      content: 'Are you sure?',
      width: id ? 545 : 525,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        const value = {
          counselingStatus: 'ON_PROGRESS',
          candidateId: id ? [id] : selectedRowKeys
        }
        return actionUpdateStatus(value, () => {
          message.success('Successfully update status to On Progress')
          actionGetData(meta)
          self.setState({ selectedRowKeys: [], selectedRows: [] })
        }, (err) => message.error(err))
      },
    });
  }

  modalSetReadyToAssign = (id) => {
    const self = this;
    const { meta, selectedRowKeys } = this.state;
    const { actionUpdateStatus, actionGetData } = this.props;
    confirm({
      title: 
        id ? 
          <Text>The candidate will be changed to ‘<Text strong style={{ color: '#32AB6D' }}>Ready to Assign</Text>’</Text> 
        : 
          <Text>The status will be changed to ‘<Text strong style={{ color: '#32AB6D' }}>Ready to Assign</Text>’ for the data you selected.</Text>,
      content: 'Are you sure?',
      width: id ? 545 : 525,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        const value = {
          counselingStatus: 'READY_TO_ASSIGN',
          candidateId: id ? [id] : selectedRowKeys
        }
        return actionUpdateStatus(value, () => {
          message.success('Successfully update status to Ready to Assign')
          actionGetData(meta)
          self.setState({ selectedRowKeys: [], selectedRows: [] })
        }, (err) => message.error(err))
      },
    });
  }

  modalSetRejected = (id) => {
    const self = this;
    const { meta, selectedRowKeys } = this.state;
    const { actionUpdateStatus, actionGetData } = this.props;
    confirm({
      title: 
        id ? 
          <Text>The candidate will be changed to ‘<Text strong style={{ color: '#F58181' }}>Rejected</Text>’</Text> 
        : 
          <Text>The status will be changed to ‘<Text strong style={{ color: '#F58181' }}>Rejected</Text>’ for the data you selected.</Text>,
      content: 'Are you sure?',
      width: id ? 500 : 480,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        const value = {
          counselingStatus: 'REJECTED',
          candidateId: id ? [id] : selectedRowKeys
        }
        return actionUpdateStatus(value, () => {
          message.success('Successfully update status to Rejected')
          actionGetData(meta)
          self.setState({ selectedRowKeys: [], selectedRows: [] })
        }, (err) => message.error(err))
      },
    });
  }

  modalExportData = (id) => {
    const self = this;
    const { selectedRowKeys } = this.state;
    const { actionExportData } = this.props;
    confirm({
      title: <Text>Candidates data will be exported to excel</Text>,
      content: 'Are you sure?',
      width: 480,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        const value = {
          candidateId: id ? id : selectedRowKeys.toString()
        }
        actionExportData(value, (response) => {
          return download(response, 'Counseling Process Data.xls')
        })
        self.setState({ selectedRowKeys: [], selectedRows: [] })
      },
    });
  }

  warningNoSelected = () => {
    warning({
      title: 'Please select a record',
    });
  };

  warningMoveToReadyToAssign = () => {
    warning({
      title: <Text>Please select record with status ‘On Progress’</Text>
    })
  };

  warningMoveToRejected = () => {
    warning({
      title: <Text>Please select record with status ‘On Progress’ or ‘Ready to Assign’</Text>
    })
  };

  warningReInviteToCounsel = () => {
    warning({
      title: <Text>Please select record with status ‘Rejected’</Text>
    })
  };

  warningMoveToAssignToJob = () => {
    warning({
      title: <Text>Please select record with status ‘Ready to Assign’</Text>
    });
  };

  onSearchJob = value => {
    const { metaJob, url } = this.state;
    const { actionGetJob } = this.props;

    metaJob.page = 1
    metaJob.perpage = 10
    metaJob.search = value

    return actionGetJob(metaJob, url)
  }

  onFocusJob = () => {
    const { metaJob, url } = this.state;
    const { actionGetJob } = this.props;
    return actionGetJob(metaJob, url)
  }

  onSelectJob = (_,res) => {
    this.setState({ jobId: res.key })
  }

  onFinishAssignJob = () => {
    const { jobId, candidateId, selectedRowKeys, valuesBulk, meta } = this.state
    const { actionAssignJob, actionAssignJobByFilter, actionGetData } = this.props
    this.formAssignRef.current.validateFields().then(values => {
      values.jobId = jobId
      if(valuesBulk){
        return actionAssignJobByFilter(values, valuesBulk, (response) => {
          if(response.code === '1000'){
            message.success(response.data)
            this.setState({ visibleAssignJob: false })
            return actionGetData(meta)
          }else if(response.code === '3170'){
            message.info(response.message)
            this.setState({ visibleAssignJob: false })
          }else{
            message.error(response.message)
            this.setState({ visibleAssignJob: false })
          }
        }, (err) => message.error(err))
      }else{
        values.candidateId = candidateId ? [candidateId] : selectedRowKeys
        return actionAssignJob(values, (response) => {
          if(response.code === '1000'){
            message.success('Successfully assign to Job')
            this.setState({ selectedRowKeys: [], selectedRows: [], visibleAssignJob: false })
            return actionGetData(meta)
          }else if(response.code === '3170'){
            message.info(response.message)
            this.setState({ selectedRowKeys: [], selectedRows: [], visibleAssignJob: false })
          }else{
            message.error(response.message)
            this.setState({ selectedRowKeys: [], selectedRows: [], visibleAssignJob: false })
          }
        }, (err) => message.error(err))
      }
    })
  }

  onFinishBulk = (values) => {
    this.setState({ visibleAction: false, visibleAction2: true, valuesBulk: values })
  }

  handleBulkEducation = (_,res) => {
    this.setState({ educationLevel: res.children })
  }

  onFinishBulk2 = (values) => {
    const { actionExportDataByFilter, actionUpdateStatusByFilter, actionGetData } = this.props
    const { meta, valuesBulk } = this.state
    if(valuesBulk.gender === 'ALL'){
      delete valuesBulk.gender
    }
    if(values.newStatus === 'EXPORT'){
      return this.setState({ visibleAction2: false, valuesBulk: null, educationLevel: null }, () => {
        return actionExportDataByFilter(valuesBulk, (response) => {
          return download(response, 'Counseling Process Data by Filter.xls')
        })
      })
    }else if(values.newStatus === 'ASSIGN_TO_JOB'){
      this.setState({ visibleAction2: false, visibleAssignJob: true })
    }else{
      const mergedValues = {...valuesBulk, ...values}
      return this.setState({ submitLoading: true }, () => {
        return actionUpdateStatusByFilter(mergedValues, (response) => {
          if(response.code === '1000'){
            message.success(response.data)
            this.setState({ submitLoading: false, visibleAction2: false, valuesBulk: null, educationLevel: null })
            return actionGetData(meta)
          }else{
            message.error(response.message)
            this.setState({ submitLoading: false, visibleAction2: false, valuesBulk: null, educationLevel: null })
          }
        }, (err) => this.setState({ submitLoading: false }, () => { 
          message.error(err)
        }))
      })
    }
  }

  render() {
    const { visibleNotes, visibleAssignJob, visibleApplicant, visibleAction, visibleAction2, selectedRowKeys, selectedRows, candidateNotes, meta, candidateId, candidateNumber, candidateName, valuesBulk, educationLevel, submitLoading, visible } = this.state
    const { getData: { data, loading , pagination }, getEducationLevel, getJob, tooltipDate } = this.props

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onChangeCheck,
      onSelect: this.onSelectCheck,
      onSelectAll: this.onSelectCheckAll
    }

    return (
      <React.Fragment>
        <Row gutter={[8, 0]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><UserSwitchOutlined /> Counseling Process</Breadcrumb.Item>
              <Breadcrumb.Item>Counseling Process List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidates Counseling Process' />
          </Col>
          {/* Filter */}
          <Col span={5}>
            <Tooltip title={tooltipDate}>
              <RangePicker
                onChange={this.handleRangePicker}
                format={'DD-MM-YYYY'}
                style={{ width: '100%' }}
                ranges={{
                  Today: [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                  'This Week': [moment().startOf('week'), moment().endOf('week')],
                  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }}
              />
            </Tooltip>
          </Col>
          <Col span={3} >
            <Popover
              placement="rightTop" 
              trigger="click"
              open={visible}
              onOpenChange={this.handleOpenChange}
              content={
                <Form ref={this.formRef} scrollToFirstError={true}>
                  <Row gutter={[0,8]} style={{ width: 300 }}>
                    <Col span={24}>
                      <Text className="link row-pointer" onClick={this.handleFilterClear} style={{ float: 'right' }}>Clear All</Text>
                    </Col>
                    <Col span={24}>
                      <Text strong>Education</Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="educationLevelId" style={{ margin: 0 }}>
                        <Select onChange={this.handleFilterEducation} placeholder="Select Level" style={{ width: '100%', float: 'right' }}>
                          {
                            getEducationLevel?.data?.map(item => 
                              <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                              )
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text strong>Gender</Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="gender" style={{ margin: 0 }}>
                        <Checkbox.Group onChange={(checkedValues) => this.handleFilterGender(checkedValues)} style={{ display: 'grid' }}>
                          <Checkbox key="1" value="MALE" style={{ marginLeft: 0 }}>Man</Checkbox>
                          <Checkbox key="2" value="FEMALE" style={{ marginLeft: 0 }}>Woman</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text strong>Age</Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="age" style={{ margin: 0 }}>
                        <Select onChange={this.handleFilterAge} placeholder="Select Age" style={{ width: '100%', float: 'right' }}>
                          <Select.Option key="1" value="<17">{`<17`}</Select.Option>
                          <Select.Option key="2" value="17-21">17-21</Select.Option>
                          <Select.Option key="3" value="22-26">22-26</Select.Option>
                          <Select.Option key="4" value="27-31">27-31</Select.Option>
                          <Select.Option key="5" value="32-36">32-36</Select.Option>
                          <Select.Option key="6" value="37-41">37-41</Select.Option>
                          <Select.Option key="7" value="42-46">42-46</Select.Option>
                          <Select.Option key="8" value=">46">{`>46`}</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text strong>Counseling Date</Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="counselingDate" style={{ margin: 0 }}>
                        <DatePicker inputReadOnly format='DD/MM/YYYY' onChange={this.handleFilterCounselingDate} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text strong>Experiences</Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="experience" style={{ margin: 0 }}>
                        <Select onChange={this.handleFilterExperience} placeholder="Select Level" style={{ width: '100%', float: 'right' }}>
                          <Select.Option key="1" value="<1">{`<1 years`}</Select.Option>
                          <Select.Option key="2" value="1-2">1-2 years</Select.Option>
                          <Select.Option key="3" value="3-5">3-5 years</Select.Option>
                          <Select.Option key="4" value=">5">{`>5 years`}</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text strong>Candidate Counseling Status</Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="counselingStatus" style={{ margin: 0 }}>
                        <Checkbox.Group onChange={(checkedValues) => this.handleFilterStatus(checkedValues)} style={{ display: 'grid' }}>
                          <Checkbox key="1" value="ON_PROGRESS" style={{ marginLeft: 0 }}>On Progress</Checkbox>
                          <Checkbox key="2" value="READY_TO_ASSIGN" style={{ marginLeft: 0 }}>Ready To Assign</Checkbox>
                          <Checkbox key="3" value="REJECTED" style={{ marginLeft: 0 }}>Rejected</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ marginTop: 8 }}>
                      <CButton type="primary" action={this.handleAddFilter} style={{ width: '100%' }} title="Add Filter"/>
                    </Col>
                  </Row>
                </Form>
              }
            >
              <Button style={{ width: '100%', borderRadius: 6 }}>
                Filter <FilterOutlined />
              </Button>

            </Popover>
          </Col>
          <Col span={12}>
            <AutoComplete 
              onSearch={this.onSearch} 
              style={{ width: '100%' }} 
              allowClear
            >
              <Input prefix={<SearchOutlined />} placeholder="Search by Name, Email"  />
            </AutoComplete>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Divider size="large" type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col span={3}>
            <Button type="primary" onClick={this.showModalAction} style={{ width: '100%', borderRadius: 6 }}>Action</Button>
          </Col>

          {/* Button */}
          {
            selectedRowKeys.length > 0 ? 
              <React.Fragment>
                <Col span={3} style={{ marginTop: 8 }}>
                  <Text>Total Data: <Text strong>{selectedRowKeys?.length}</Text></Text>
                </Col>
                <Col span={21} style={{ marginTop: 8 }}>
                  <Space>
                    <Button 
                      className="btn-color-green" 
                      size='small'
                      onClick={
                        selectedRowKeys.length > 0 ?
                          selectedRows.find(res => res.status !== 'ON_PROGRESS') ?
                            this.warningMoveToReadyToAssign
                          :
                            () => this.modalSetReadyToAssign(null)
                        :
                          this.warningNoSelected}
                    >
                      Set to “Ready to Assign”
                    </Button>
                    <Button 
                      className="btn-color-red" 
                      size='small'
                      onClick={
                        selectedRowKeys.length > 0 ?
                          selectedRows.find(res => res.status === 'REJECTED') ?
                            this.warningMoveToRejected
                          :
                            () => this.modalSetRejected(null)
                        :
                          this.warningNoSelected}
                    >
                      Set to “Rejected”
                    </Button>
                    <Button 
                      className="btn-color-blue" 
                      size='small'
                      onClick={
                        selectedRowKeys.length > 0 ?
                          selectedRows.find(res => res.status !== 'REJECTED') ?
                            this.warningReInviteToCounsel
                          :
                            () => this.modalSetOnProgress(null)
                        :
                          this.warningNoSelected}
                    >
                      Re-invite to counsel
                    </Button>
                    <Button 
                      className="btn-create-primary" 
                      size='small'
                      type="primary" 
                      ghost
                      onClick={
                        selectedRowKeys.length > 0 ? 
                          selectedRows.find(res => res.status !== 'READY_TO_ASSIGN') ?
                            this.warningMoveToAssignToJob
                          :
                            () => this.showModalAssignJob(null) 
                        : 
                          this.warningNoSelected}
                    >
                      Assign to a Job <SoundOutlined />
                    </Button>
                    <Button 
                      className="btn-create-primary" 
                      type="primary" 
                      size='small'
                      ghost
                      onClick={selectedRowKeys.length > 0 ? () => this.modalExportData(null) : this.warningNoSelected}
                    >
                      Export Data <FileExcelOutlined />
                    </Button>
                  </Space>
                </Col>
              </React.Fragment>
            : null
          }
          
          {/* Value Filter */}
          {
            meta.hasOwnProperty('educationLevelId') || meta.hasOwnProperty('gender') || meta.hasOwnProperty('age') || meta.hasOwnProperty('counselingDate') || meta.hasOwnProperty('experience') || meta.hasOwnProperty('counselingStatus') ?
              <Col span={24} style={{ paddingTop: 8 }}>
                <Space>
                  <Text>Filter Active:</Text>
                  <Space size={0}>
                    {
                      meta.hasOwnProperty('educationLevelId') ? 
                        <Tag
                          color="green"
                          closable
                          onClose={() => this.handleCloseTag('educationLevelId')}
                          style={{ padding: '4px 16px', borderRadius: 32 }}
                        >
                          Education
                        </Tag>
                      : null
                    }
                    {
                      meta.hasOwnProperty('gender') ? 
                        <Tag
                          color="green"
                          closable
                          onClose={() => this.handleCloseTag('gender')}
                          style={{ padding: '4px 16px', borderRadius: 32 }}
                        >
                          Gender
                        </Tag>
                      : null
                    }
                    {
                      meta.hasOwnProperty('age') ? 
                        <Tag
                          color="green"
                          closable
                          onClose={() => this.handleCloseTag('age')}
                          style={{ padding: '4px 16px', borderRadius: 32 }}
                        >
                          Age
                        </Tag>
                      : null
                    }
                    {
                      meta.hasOwnProperty('counselingDate') ? 
                        <Tag
                          color="green"
                          closable
                          onClose={() => this.handleCloseTag('counselingDate')}
                          style={{ padding: '4px 16px', borderRadius: 32 }}
                        >
                          Counseling Date
                        </Tag>
                      : null
                    }
                    {
                      meta.hasOwnProperty('experience') ? 
                        <Tag
                          color="green"
                          closable
                          onClose={() => this.handleCloseTag('experience')}
                          style={{ padding: '4px 16px', borderRadius: 32 }}
                        >
                          Experiences
                        </Tag>
                      : null
                    }
                    {
                      meta.hasOwnProperty('counselingStatus') ? 
                        <Tag
                          color="green"
                          closable
                          onClose={() => this.handleCloseTag('counselingStatus')}
                          style={{ padding: '4px 16px', borderRadius: 32 }}
                        >
                          Candidate Counseling Status
                        </Tag>
                      : null
                    }
                  </Space>
                  <Button type="text" onClick={this.handleFilterClear}>Clear All</Button>
                </Space>
              </Col>
            : null
          }

          {/* Table */}
          <Col span={24} style={{ marginTop: 8 }}>
            <Table 
              size="small" 
              rowKey={(i) => i.candidateId} 
              pagination={false}
              loading={loading} 
              scroll={{ x: 1500 }}
              rowSelection={rowSelection}
              dataSource={data} columns={columns(this.onClickRow, this.showModalNotes, this.modalSetOnProgress, this.modalSetReadyToAssign, this.modalSetRejected, this.showModalAssignJob, this.modalExportData, selectedRowKeys)}
            />
          </Col>
          {/* Pagination */}
          <Col span={24} style={{ marginTop: 8 }}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              pageSizeOptions={[20,50,100,200]}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
              size='small'
            />
          </Col>
        </Row>

        <Modal
          title= {`Recruitment Notes`}
          visible={visibleNotes}
          width={600}
          onCancel={() => this.handleCancel(null)}
          footer= {false}
          destroyOnClose
        >
          <Row>
            <Col span={24}>
              <Form layout='vertical' onFinish={this.onFinishNotes}>
                <Form.Item
                  name="note"
                  rules={[
                    { required: true, message: 'This is a required field' },
                    { max: 200, message: '200 characters only' },
                  ]}
                >
                  <TextArea rows={2} placeholder='write a notes'
                >
                  </TextArea>
                </Form.Item>
                
                <CButton 
                  className="btn-create-primary" 
                  type="primary" 
                  ghost 
                  style={{float : 'right'}}
                  htmlType = "submit"
                  title="Submit"
                />
              </Form>
            </Col>

            <Col span={24} style={{marginTop: 32}}>
              {
                candidateNotes?.length > 0 ? 
                  <Timeline mode='left' style={{ paddingRight: 264 }}>
                    {
                      candidateNotes.map((item,i) => 
                        <Timeline.Item key={i} label={<Text style={{ fontSize: 12 }}>{item.createdDate}</Text>}>
                          <Space direction="vertical" size={0} style={{ width: 388 }}>
                            <Text style={{ fontSize: 12 }}>{item.note}</Text>
                            <Text style={{ color: '#0076de', fontSize: 12 }}>{item.stage}</Text>
                          </Space>
                        </Timeline.Item>
                      )
                    }
                  </Timeline>
                : 
                  <Empty />
              }
            </Col>
          </Row>
        </Modal>
        
        <Modal
          title="Assign Job"
          width={800}
          visible={visibleAssignJob}
          destroyOnClose
          onCancel={() => this.handleCancel(null)}
          footer={[
            <React.Fragment key="assignJob">
              <CButton key="back" className="btn-save-primary" action={() => this.handleCancel(null)} title="Cancel"/>
              <CButton key="submit" className="btn-save-primary" type="primary" action={this.onFinishAssignJob} title="Assign"/>
            </React.Fragment>
          ]}
        >
          <Form ref={this.formAssignRef} onFinish={this.onFinishAssignJob} layout='vertical'>
            <Row gutter={32}>
              <Col span={12}>
                <Space direction='vertical'>
                  <Text>{selectedRowKeys.length > 0 ? `${selectedRowKeys.length} Candidates` : 'Candidates'}</Text>
                  {
                    valuesBulk ?
                      <Text style={{ color: '#4273B9' }}>
                        {
                          `Filter by: 
                          ${valuesBulk?.educationLevelId ? `Education(${educationLevel}), ` : ''}
                          ${valuesBulk?.gender === 'MALE' ? `Gender(Man), ` : valuesBulk?.gender === 'FEMALE' ? `Gender(Women), ` : ''}
                          ${valuesBulk?.age ? `Age(${valuesBulk?.age}), ` : ''}
                          ${valuesBulk?.experience ? `Experiences(${valuesBulk?.experience} years), ` : ''}
                          Candidate Status(${valuesBulk?.crmStatus === 'ON_PROGRESS' ? 'On Progress' : valuesBulk?.crmStatus === 'READY_TO_ASSIGN' ? 'Ready to Assign' : 'Rejected'})`
                        }
                      </Text>
                    :
                      candidateId ?
                        <Space size={0}>
                          <Text className='row-pointer' onClick={() => this.showModalApplicant(null)} style={{ color: '#0076de' }}>{candidateNumber}</Text>
                          <Text>-{candidateName}</Text>
                        </Space>
                      :
                        <div className="frame" style={{ height: selectedRowKeys.length > 4 ? 110 : selectedRowKeys.length > 3 ? 90 : selectedRowKeys.length > 2 ? 70 : selectedRowKeys.length > 1 ? 60 : 30, overflowY: 'auto' }}>
                          {
                            selectedRows.map((item, i) =>
                              <Space key={i} size={0}>
                                <Text className='row-pointer' onClick={() => this.showModalApplicant(item.id)} style={{ color: '#0076de' }}>{item.candidateNumber}</Text>
                                <Text>-{item.candidateName}</Text>
                              </Space>
                            )
                          }
                        </div>
                  }
                </Space>
              </Col>
              <Col span={12}>
                <Form.Item name="jobId" label="Assign to">
                  <Select
                    onFocus={this.onFocusJob}
                    onSearch={this.onSearchJob}
                    onSelect={this.onSelectJob}
                    showSearch
                    notFoundContent={getJob?.loading ? <Spin size="small" /> : null}
                    filterOption={false}
                    options={
                      getJob?.data?.map(res => ({
                        key: res.id,
                        label: res.title,
                        value: res.title
                      }))
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: 8 }}>
                <Form.Item 
                  label="Notes" 
                  name="notes"
                  rules={[
                    { max: 1000, message: '1000 characters only' },
                  ]}
                >
                  <Input.TextArea rows={3} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
        
        <Modal
          centered
          title="Applicant Profile"
          width={800}
          visible={visibleApplicant}
          destroyOnClose
          onCancel={() => this.handleCancel('profile')}
          footer={[
            <>
              <ReactToPrint
                key="print"
                trigger={() => <Button>Download CV</Button>}
              />
              <CButton key="back" type="primary" action={() => this.handleCancel('profile')} title="Okay"/>
            </>
          ]}
        >
          <Profile {...this.props} />
        </Modal>

        <Modal
          title={<Text strong>Update Candidate Status in Bulk</Text>}
          visible={visibleAction}
          onCancel={this.handleCancel}
          footer= {false}
          destroyOnClose
        >
          <Form ref={this.formBulkRef} onFinish={this.onFinishBulk}>
            <Row gutter={[0,8]}>
              <Col span={24}>
                <Text strong>Education</Text>
              </Col>
              <Col span={24}>
                <Form.Item name="educationLevelId" style={{ margin: 0 }}>
                  <Select onChange={this.handleBulkEducation} placeholder="Select Level" style={{ width: '100%', float: 'right' }}>
                    {
                      getEducationLevel?.data?.map(item => 
                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text strong>Gender</Text>
              </Col>
              <Col span={24}>
                <Form.Item name="gender" style={{ margin: 0 }} initialValue="ALL">
                  <Radio.Group>
                    <Space direction="vertical" size={0}>
                      <Radio key="1" value="ALL" style={{ marginLeft: 0 }}>All</Radio>
                      <Radio key="2" value="MALE" style={{ marginLeft: 0 }}>Man</Radio>
                      <Radio key="3" value="FEMALE" style={{ marginLeft: 0 }}>Woman</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text strong>Age</Text>
              </Col>
              <Col span={24}>
                <Form.Item name="age" style={{ margin: 0 }}>
                  <Select placeholder="Select Age" style={{ width: '100%', float: 'right' }}>
                    <Select.Option key="1" value="<17">{`<17`}</Select.Option>
                    <Select.Option key="2" value="17-21">17-21</Select.Option>
                    <Select.Option key="3" value="22-26">22-26</Select.Option>
                    <Select.Option key="4" value="27-31">27-31</Select.Option>
                    <Select.Option key="5" value="32-36">32-36</Select.Option>
                    <Select.Option key="6" value="37-41">37-41</Select.Option>
                    <Select.Option key="7" value="42-46">42-46</Select.Option>
                    <Select.Option key="8" value=">46">{`>46`}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text strong>Experiences</Text>
              </Col>
              <Col span={24}>
                <Form.Item name="experience" style={{ margin: 0 }}>
                  <Select placeholder="Select Level" style={{ width: '100%', float: 'right' }}>
                    <Select.Option key="1" value="<1">{`<1 years`}</Select.Option>
                    <Select.Option key="2" value="1-2">1-2 years</Select.Option>
                    <Select.Option key="3" value="3-5">3-5 years</Select.Option>
                    <Select.Option key="4" value=">5">{`>5 years`}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text strong>Candidates Status</Text>
              </Col>
              <Col span={24}>
                <Form.Item 
                  name="crmStatus" 
                  rules={[
                    { required: true, message: 'This is a required field' }
                  ]}
                  style={{ margin: 0 }}
                >
                  <Radio.Group>
                    <Space direction="vertical" size={0}>
                      <Radio key="1" value="ON_PROGRESS" style={{ marginLeft: 0 }}>On Progress</Radio>
                      <Radio key="2" value="READY_TO_ASSIGN" style={{ marginLeft: 0 }}>Ready to Assign</Radio>
                      <Radio key="3" value="REJECTED" style={{ marginLeft: 0 }}>Rejected</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: 8 }}>
                <Space style={{ float: 'right' }}>
                  <CButton type=" default" action={this.handleCancel} title="Cancel"/>
                  <CButton type="primary" htmlType='submit' title="Next"/>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title={<Text strong>Candidates data are selected</Text>}
          visible={visibleAction2}
          onCancel={this.handleCancel}
          footer= {false}
          destroyOnClose
        >
          <Text style={{ color: '#4273B9' }}>
            {
              `Filter by: 
              ${valuesBulk?.educationLevelId ? `Education(${educationLevel}), ` : ''}
              ${valuesBulk?.gender === 'MALE' ? `Gender(Man), ` : valuesBulk?.gender === 'FEMALE' ? `Gender(Women), ` : ''}
              ${valuesBulk?.age ? `Age(${valuesBulk?.age}), ` : ''}
              ${valuesBulk?.experience ? `Experiences(${valuesBulk?.experience} years), ` : ''}
              Candidate Status(${valuesBulk?.crmStatus === 'ON_PROGRESS' ? 'On Progress' : valuesBulk?.crmStatus === 'READY_TO_ASSIGN' ? 'Ready to Assign' : 'Rejected'})`
            }
          </Text>
          <Form ref={this.formBulkRef2} onFinish={this.onFinishBulk2} style={{ marginTop: 16 }}>
            <Row>
              <Col span={24}>
                <Form.Item 
                  name="newStatus" 
                  rules={[
                    { required: true, message: 'This is a required field' }
                  ]}
                >
                  <Radio.Group>
                    <Space direction="vertical" size={0}>
                      {
                        valuesBulk?.crmStatus === 'ON_PROGRESS' ?
                          <Radio key="1" value="READY_TO_ASSIGN" style={{ marginLeft: 0 }}>Change status to ‘‘<Text strong style={{ color: '#32AB6D' }}>Ready to Assign</Text>’’</Radio>
                        : null
                      }
                      {
                        valuesBulk?.crmStatus === 'REJECTED' ?
                          <Radio key="2" value="ON_PROGRESS" style={{ marginLeft: 0 }}>Re-invite to counsel</Radio>
                        : null

                      }
                      {
                        valuesBulk?.crmStatus !== 'REJECTED' ?
                          <Radio key="3" value="REJECTED" style={{ marginLeft: 0 }}>Change status to ‘‘<Text strong style={{ color: '#F58181' }}>Rejected</Text>’’</Radio>
                        : null
                      }
                      {
                        valuesBulk?.crmStatus === 'READY_TO_ASSIGN' ?
                          <Radio key="4" value="ASSIGN_TO_JOB" style={{ marginLeft: 0 }}>Assign to a Job</Radio>
                        : null
                      }
                      <Radio key="5" value="EXPORT" style={{ marginLeft: 0 }}>Export Data</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: 8 }}>
                <Space style={{ float: 'right' }}>
                  <CButton type="default" action={this.handleCancel} title="Cancel"/>
                  <CButton type="primary" htmlType='submit' loading={submitLoading} title="Next"/>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  
  componentWillUnmount() {
    const { unmountGetData, unmountMasterEducationLevel, unmountGetJob } = this.props
    unmountGetData()
    unmountMasterEducationLevel()
    unmountGetJob()
  }
}

const mapStateToProps = (state) => ({
  getData           : state.counselingProcess.list,
  getEducationLevel : state.educationLevel.master,
  getProfile        : state.candidate.detail,
  getJob            : state.jobs.list
})

const mapDispatchToProps = {
  actionGetData               : listCounselingProcess,
  actionGetEducationLevel     : masterEducationLevel,
  actionCreateNotes           : createNotes,
  actionUpdateStatus          : updateStatusCounseling,
  actionGetProfile            : detailCandidate,
  actionGetJob                : listJobs,
  actionAssignJob             : assignToJob,
  actionExportData            : exportData,
  actionUpdateStatusByFilter  : updateStatusByFilter,
  actionExportDataByFilter    : exportDataByFilter,
  actionAssignJobByFilter     : assignToJobByFilter,
  unmountGetData              : unmountListCounselingProcess,
  unmountMasterEducationLevel : unmountMasterEducationLevel,
  unmountGetProfile           : unmountDetailCandidate,
  unmountGetJob               : unmountListJobs
}

export default connect(mapStateToProps, mapDispatchToProps)(CounselingProcessList)
