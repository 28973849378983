import React from 'react'
import moment from 'moment'
import { Player, BigPlayButton } from 'video-react';
import { Row, Col, Card, Typography, Tag, Descriptions, Divider, Image, Space, Tooltip, Empty } from 'antd'
import { ExclamationCircleOutlined, EyeOutlined } from '@ant-design/icons'
import { jobType } from '../../../../../../utils/constant/job'
import ModalAboutCountry from './Modal/AboutCountry'
import ModalEventDetail from './Modal/Event'
import "video-react/dist/video-react.css";

const { Text } = Typography;

export default function Information(props) {
  const { data, showModal, showModalCountry } = props
  return (
    <React.Fragment>
      <Row gutter={[16, 16]} >
        <Col span={24}>
          <Card title="Information">
            <Row gutter={16}>
              <Col span={24}>
                <Text style={{ fontSize: 16 }}>Job Opening Information</Text>
              </Col>
              <Col span={24}>
                <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 20 }}>
                  <Descriptions.Item label={<Text type="secondary">Job Posting Title</Text>}>
                    {data.title ? data.title : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Job Placement</Text>}>
                    {data.jobDestinationName ? data.jobDestinationName : 'N/A'}, {data.jobCountryName ? data.jobCountryName : 'N/A'}
                    <Space style={{ marginLeft: 5 }}>
                      <ExclamationCircleOutlined onClick={showModalCountry} style={{ cursor: 'pointer' }} />
                    </Space>
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Display salary information</Text>}>
                    {data.isDisplaySalary ? "Yes" : "No"}
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Job Posting type</Text>}>
                    {data.jobPostingTypeName ? data.jobPostingTypeName : 'N/A'}
                  </Descriptions.Item>
                  {
                    data.externalLink ?
                      <Descriptions.Item label={<Text type="secondary">URL</Text>}>
                        {data.externalLink ? data.externalLink : 'N/A'}
                      </Descriptions.Item>
                    : null
                  }
                  <Descriptions.Item label={<Text type="secondary">Work from home (remote)</Text>}>
                    {data.isRemote ? 'Yes' : 'No'}
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Payment Type</Text>}>
                    {
                      data.paymentType ?
                        <React.Fragment>
                          {data.paymentType === "DAILY" ? "Per Day" : null}
                          {data.paymentType === "WEEKLY" ? "Per Week" : null}
                          {data.paymentType === "MONTHLY" ? "Per Month" : null}
                          {data.paymentType === "YEARLY" ? "Per Year" : null}
                          {data.paymentType === "PROJECT" ? "Per Project" : null}
                        </React.Fragment>
                        : 'N/A'
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Job Industry</Text>}>
                    {data.jobIndustryName ? data.jobIndustryName : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Experience Level</Text>}>
                    {
                      data.experienceLevel ?
                        <React.Fragment>
                          {data.experienceLevel === "ENTRY_LEVEL" ? "Entry Level" : null}
                          {data.experienceLevel === "ASSOCIATE" ? "Associate" : null}
                          {data.experienceLevel === "MID_SENIOR" ? "Mid-Senior Level" : null}
                          {data.experienceLevel === "DIRECTOR" ? "Director" : null}
                          {data.experienceLevel === "EXECUTIVE" ? "Executive" : null}
                        </React.Fragment>
                        : 'N/A'
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Salary Type</Text>}>
                    {data.isFixSalary ? "Fix Salary" : "Range"}
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Job Position</Text>}>
                    {data.jobPositionName ? data.jobPositionName : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Number of Position</Text>}>
                    {data.numberOfPosition ? data.numberOfPosition : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Payroll Currency</Text>}>
                    {data.currencyCode ? data.currencyCode : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Job Type</Text>}>
                    {
                      data.jobType ?
                        <React.Fragment>
                          {jobType.find(item => item.value === data.jobType)?.name}
                        </React.Fragment>
                        : 'N/A'
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Date Opened</Text>}>
                    {data.dateOpened ? moment(data.dateOpened, 'DD/MM/YYYY').format("DD MMMM YYYY") : 'N/A'}
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Salary</Text>}>
                    {
                      data.isFixSalary ?
                        <React.Fragment>
                          {data.salary ? data.salary.toLocaleString() : 'N/A'}
                        </React.Fragment>
                        :
                        <React.Fragment>
                          {data.salary ? data.salary.toLocaleString() : 'N/A'} - {data.maxSalary ? data.maxSalary.toLocaleString() : 'N/A'}
                        </React.Fragment>
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Placement Type</Text>}>
                    {
                      data.placementType ?
                        <React.Fragment>
                          {data.placementType === "DOMESTIC" ? "Domestic" : null}
                          {data.placementType === "INTERNATIONAL" ? "International" : null}
                        </React.Fragment>
                        : 'N/A'
                    }
                  </Descriptions.Item>
                  <Descriptions.Item label={<Text type="secondary">Close Application Date</Text>}>
                    {data.targetDate ? moment(data.targetDate, 'DD/MM/YYYY').format("DD MMMM YYYY") : 'N/A'}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Divider dashed={true} />
              <Col span={24}>
                <Text style={{ fontSize: 16 }}>Description Information</Text>
              </Col>
              <Col span={24}>
                <Descriptions layout="vertical" column={1} size="small" style={{ marginTop: 20 }}>
                  <Descriptions.Item label={<Text type="secondary">Job Description</Text>}>
                    {
                      data.jobDescription ?
                        <div dangerouslySetInnerHTML={{ __html: data.jobDescription }}></div>
                        :
                        'N/A'
                    }
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={24}>
                {
                  data.placementType === "DOMESTIC" ?
                    <React.Fragment>
                      <Descriptions layout="vertical" column={1} size="small">
                        <Descriptions.Item label={<Text type="secondary">Requirements</Text>}>
                          {
                            data.jobRequirement ?
                              <div dangerouslySetInnerHTML={{ __html: data.jobRequirement }}></div>
                              :
                              'N/A'
                          }
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Benefits</Text>}>
                          {
                            data.jobRequirement ?
                              <div dangerouslySetInnerHTML={{ __html: data.benefit }}></div>
                              :
                              'N/A'
                          }
                        </Descriptions.Item>
                      </Descriptions>
                    </React.Fragment>
                    : null
                }
                {
                  data.placementType === "INTERNATIONAL" ?
                    <React.Fragment>
                      <Divider dashed={true} />
                      <Text style={{ fontSize: 16 }}>Terms and Conditions</Text>
                      <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 20 }}>
                        <Descriptions.Item label={<Text type="secondary">Duration of contract</Text>}>
                          {data.durationOfContract ? data.durationOfContract : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Paid Annual Leave</Text>}>
                          {data.isPaidAnnualLeave ? "Yes" : 'No'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Probation Period (months)</Text>}>
                          {data.probationPeriod ? data.probationPeriod : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Contract is extendable</Text>}>
                          {data.isContractExtendable ? "Yes" : 'No'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Working Hours (per day)</Text>}>
                          {data.workingHour ? data.workingHour : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Working Overtime</Text>}>
                          {data.isWorkingOvertime ? "Yes" : 'No'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Annual Leave (days)</Text>}>
                          {data.annualLeave ? data.annualLeave : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Working Days (per week)</Text>}>
                          {data.workingDay ? data.workingDay : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Other Working System</Text>}>
                          {data.otherWorkingSystem ? data.otherWorkingSystem : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>
                      {
                        data.listBenefit.length > 0 ?
                          <React.Fragment>
                            <Divider dashed={true} />
                            <Text style={{ fontSize: 16 }}>Benefits</Text>
                            <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 20 }}>
                              {
                                data.listBenefit && data.listBenefit.map((item, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <Descriptions.Item label={<Text type="secondary">{item.name}</Text>}>
                                        {
                                          item.status ?
                                            <Text>
                                              Allowance, {data.currencyCode} {item.allowance.toLocaleString()}
                                            </Text>
                                            :
                                            <Text>
                                              Provided, no amount
                                            </Text>
                                        }
                                      </Descriptions.Item>
                                    </React.Fragment>
                                  )
                                })
                              }
                            </Descriptions>


                          </React.Fragment>
                          : null
                      }
                      <Divider dashed={true} />
                      <Text style={{ fontSize: 16 }}>Candidate Requirements</Text>
                      <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 20 }}>
                        <Descriptions.Item label={<Text type="secondary">Gender</Text>}>
                          {data.gender === "BOTH" ? "Male and Female" : null}
                          {data.gender === "MALE" ? "Male" : null}
                          {data.gender === "Female" ? "Female" : null}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Minimum Body Height (cm)</Text>}>
                          {data.minHeight ? data.minHeight : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Age Range</Text>}>
                          {data.ageStart ? data.ageStart : 'N/A'} - {data.ageEnd ? data.ageEnd : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Minimum Experience (years)</Text>}>
                          {data.minExperience ? data.minExperience : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Minimum Body Weight (kg)</Text>}>
                          {data.minWeight ? data.minWeight : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Minimum Education Level</Text>}>
                          {data.educationLevelName ? data.educationLevelName : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Certification</Text>}>
                          {data.certification ? data.certification : 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label={<Text type="secondary">Notes</Text>}>
                          {data.notes ? data.notes : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>

                      <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 10 }}>
                        <Descriptions.Item label={<Text type="secondary">Field of Study</Text>}>
                          {data.fieldOfStudy ? data.fieldOfStudy : 'N/A'}
                        </Descriptions.Item>
                      </Descriptions>

                      <Descriptions layout="vertical" column={3} size="small" style={{ marginTop: 10 }}>
                        <Descriptions.Item label={<Text type="secondary">Skills</Text>}>
                          {
                            data.skill && data.skill.length > 0 ?
                              <>
                                {
                                  data.skill.map((item, i) => (
                                    <Tag key={i}>{item}</Tag>
                                  ))
                                }
                              </>
                              : 'N/A'
                          }
                        </Descriptions.Item>
                      </Descriptions>
                    </React.Fragment>
                    : null
                }
              </Col>

              <Col span={24}>
                <Text style={{ fontSize: 16 }}>Job Story</Text>
              </Col>

              <Col span={24}>
                {
                  data.listJobStory.length > 0 ?
                    <Row gutter={[8, 8]} style={{ marginTop: 8 }}>
                      {
                        data.listJobStory.map((item, i) =>
                          <Col key={i} span={8}>
                            {
                              item.fileExtension === 'mp4' ?
                                <Player
                                  fluid={false}
                                  width={'100%'}
                                  height={200}
                                  style={{ objectFit: 'cover' }}
                                >
                                  <source src={item.fileUrl} />
                                  <BigPlayButton position='center' />
                                </Player>
                                :
                                <Image
                                  src={item.fileUrl}
                                  preview={false}
                                  width={'100%'}
                                  height={200}
                                  style={{ objectFit: 'cover' }}
                                />
                            }
                          </Col>
                        )
                      }
                    </Row>
                    :
                    'N/A'
                }
              </Col>

              <Col span={24} style={{ marginTop: 10, marginBottom: 10 }}>
                <Text style={{ fontSize: 16 }}>Job Event</Text>
              </Col>

              <Col span={24}>
                {
                  data.listJobEvent.length > 0 ?
                    data?.listJobEvent?.map((item, i) =>
                      <React.Fragment key={i}>
                        <Row justify="space-around" align="middle">
                          <Col span={20}>
                            <Space>
                              <Image
                                width={70}
                                height={70}
                                src={item.thumbnailImageUrl}
                                style={{ objectFit: 'cover' }}
                              />
                              <Space direction="vertical" size={0}>
                                <Text strong>{item.title}</Text>
                                <Text>{moment(item.eventDate, 'DD/MM/YYYY').format("DD MMMM YYYY")}, {moment(item.startTime, 'HH:mm:ss').format('HH:mm')} {moment(item.endTime, 'HH:mm:ss').format('HH:mm')}</Text>
                                <Text>{item.jobEventType}</Text>
                              </Space>
                            </Space>
                          </Col>
                          <Col span={4} style={{ float: 'right' }}>
                            <Space style={{ float: 'right' }}>
                              <Tooltip title="View Detail">
                                <EyeOutlined style={{ fontSize: 20, cursor: 'pointer', color: '#32ab6d' }} onClick={() => showModal(item)} />
                              </Tooltip>
                            </Space>
                          </Col>
                        </Row>
                        <Divider />
                      </React.Fragment>
                    )
                    :
                    <Empty description={<Text>No job event available</Text>} />
                }
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <ModalEventDetail {...props} />
      <ModalAboutCountry {...props} />
    </React.Fragment>
  )
}
