import React from 'react'
import { Col, Typography, Space, Divider } from 'antd'

const { Title, Text } = Typography

export default function Header(props) {
  const { data } = props
  return (
    <React.Fragment>
      <Col span={24} style={{ textAlign: 'center' }}>
        <Space direction='vertical' size={4}>
          <Title level={3} style={{ margin: 0 }}>{data.fullName ? data.fullName : 'N/A'}</Title>
          <Space split={'|'}>
            <Text>{data.currentCityName ? data.currentCityName : 'N/A'}, {data.currentCountryName ? data.currentCountryName : 'N/A'}</Text>
            <Text>{data.callingCode && data.mobilePhone ? `+${data.callingCode}${data.mobilePhone}` : 'N/A'}</Text>
            <a href={`mailto:${data.email}`} rel='noreferrer'>
              <Text underline style={{ color: '#0842a0' }}>{data.email ? data.email : 'N/A'}</Text>
            </a>
          </Space>
        </Space>
      </Col>
      <Col span={24}>
        <Space direction='vertical' size={16} style={{ width: '100%', textAlign: 'justify', textJustify: 'inter-word' }}>
          <Space direction='vertical' size={0} style={{ width: '100%' }}>
            <Title level={4} style={{ margin: 0 }}>ABOUT ME</Title>
            <Divider style={{ margin: 0, borderTop: '1px solid black' }} />
          </Space>
          <Text>
            {data.about ? data.about : 'N/A'}
          </Text>
        </Space>
      </Col>
    </React.Fragment>
  )
}
