import React, { Component } from 'react';
import { Card, Typography, Form, Row, Col, Input, message } from 'antd';
import CButton from '../../../../components/Button';
const { Text } = Typography;

export default class ChangePassword extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false
    }
  }
  
  onFinish = (values) => {
    const { actionUpdatePassword } = this.props;
    this.setState({ submitLoading: true })
    return actionUpdatePassword('employers', values, (response) => {
      this.setState({ submitLoading: false }, () => {
        if(response.code === '1000'){
          message.success("Password updated successfully")
          this.formRef.current.setFieldsValue({
            currentPassword: null,
            newPassword: null,
            retypeNewPassword: null,
          });
        }else{
          message.error(response.message)
        }
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => {
        message.error(err)
      })
    })
  }

  render() {
    const { submitLoading } = this.state;
    return (
      <React.Fragment>
        <Card 
          title={<Text style={{ color: '#9a9a9a', fontWeight: 'bold' }}>Change Password</Text>}
          style={{ border: 'none' }}
        >
          <Form layout="vertical" onFinish={this.onFinish} ref={this.formRef}>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item 
                  label="Old Password" 
                  name="currentPassword"
                  initialValue={null}
                  rules={[
                    { required: true, message: 'This is a required field' },
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  label="New Password"
                  name="newPassword"
                  validateFirst
                  rules={[
                    { required: true, message: 'This is a required field' },
                    { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: 'Your password isn’t strong enough' },
                    { min: 8, message: 'Password must be at least 8 characters long.' },
                  ]}
                >
                  <Input.Password />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                    label="Retype New Password"
                    name="retypeNewPassword"
                    validateFirst
                    dependencies={['newPassword']}
                    rules={[
                      { required: true, message: 'This is a required field'},
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('newPassword') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Passwords do not match!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
              </Col>
              <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CButton className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title="Save"/>
              </Col>
            </Row>
          </Form>
        </Card>
      </React.Fragment>
    )
  }
}
