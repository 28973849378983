import React, { Component } from 'react';
import moment from 'moment';
import EditBiodata from './Edit';
import ImgCrop from 'antd-img-crop';
import svg from '../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { masterCities, unmountMasterCities } from '../../../../redux/actions/master/cities/citiesAction';
import { detailCandidate, unmountDetailCandidate, updateAvatar, updateBiodata, enableCandidate } from '../../../../redux/actions/candidate/candidateAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/master/countries/countriesAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Space, Avatar, Typography, Button, Descriptions, Image, Upload, Modal, Tag, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CButton from '../../../Button';
import Menu from '../Menu';
const { confirm } = Modal;
const { Text } = Typography;
const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return <Component {...rest} />
};
class CandidateDetailBiodata extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      cityId: null,
      arrFamilyHistory: [],
    }
  }

  componentDidMount() {
    const { arrFamilyHistory } = this.state;
    const { actionGetDetail, actionGetCountry, actionGetCity, match: { params } } = this.props;
    actionGetDetail(params.id, (response) => {
      if(response.data.listFamilyHistory.length > 0){
        response.data.listFamilyHistory.forEach((item) => {
          arrFamilyHistory.push(
            {
              id: item.id, 
              medicalConditionId: item.medicalConditionId, 
              flagStatus: item.flagStatus
            }
          )
        })
        this.setState({ arrFamilyHistory })
      }
      return this.setState({ cityId: response.data.currentCityId })
    })
    actionGetCountry()
    actionGetCity()
  }

  showModal = () => {
    return this.setState({ visible: true })
  }

  onCancel = () => {
    const { actionGetDetail, match: { params } } = this.props;
    return this.setState({ visible: false }, () => {
      actionGetDetail(params.id)
    })
  }

  handleUpload(){
    const { actionUpdateAvatar, actionGetDetail, match: { params } } = this.props;
    return {
      showUploadList: false,
      withCredentials: true,
      accept:".png,.jpg,.jpeg",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          const value = {
            'profilePicFile': file,
            'id': Number(params.id)
          }
          return actionUpdateAvatar(value, () => {
            message.success('Successfully uploaded')
            actionGetDetail(params.id)
          })
        }
        return false;
      },
    }
  }

  handleCity = (value, option) => {
    const optionSelected = Number(option.key)
    this.setState({
      cityId: optionSelected
    })
  }

  onFinish = () => {
    const { cityId, arrFamilyHistory } = this.state;
    const { actionUpdateBiodata, actionGetDetail, match: { params } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.id = Number(params.id)
      values.currentCityId = cityId
      values.birthdate = values.birthdate ? moment(values.birthdate).format('DD/MM/YYYY') : null
      if(arrFamilyHistory.length > 0){
        values.listFamilyHistory = arrFamilyHistory
        delete values.heartTrouble
        delete values.diabetes
        delete values.convulsion
        delete values.cancer
        delete values.tubercolosis
        delete values.allergies
        delete values.commitedSuicide
      }

      return actionUpdateBiodata(values, () => {
        return this.setState({ visible: false }, () => {
          message.success('Data updated successfully')
          return actionGetDetail(params.id)
        })
      }, (err) => message.error(err))
    })
  }

  handleRadio = (e, key) => {
    const { arrFamilyHistory } = this.state
    arrFamilyHistory[key].flagStatus = e.target.value
    this.setState({ arrFamilyHistory })
  }
  
  showConfirmActive = () => {
    const { actionEnable, actionGetDetail ,match: { params } } = this.props;
    confirm({
      title: 'Unable to edit profile due to candidate status is inactive. Do you want to change candidate status to active?',
      icon: <ExclamationCircleOutlined />,
      width: 700,
      onOk() {
        return actionEnable(params.id, () => {
          message.success('Successfully changed to active')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
      okText: 'Change to Active',
      onCancel() {},
    });
  };

  render() {
    const { visible } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
        {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type={this.props.icon} />
                {
                  this.props.url.split('/')[1] === 'all-candidate' ?
                    ' All Candidates'
                  : this.props.url.split('/')[1] === 'new-candidate' ?
                    ' New Candidates'
                  : this.props.url.split('/')[1] === 'counseling-process' ?
                    ' Counseling Process'
                  : this.props.url.split('/')[1] === 'assigned-candidate' ?
                    ' Assigned Candidates'
                  :
                    ' Candidates'
                }
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to={`/${this.props.url.split('/')[1]}`}>
                  {
                    this.props.url.split('/')[1] === 'all-candidate' ?
                      'All Candidates List'
                    : this.props.url.split('/')[1] === 'new-candidate' ?
                      'New Candidates List'
                    : this.props.url.split('/')[1] === 'counseling-process' ?
                      'Counseling Process List'
                    : this.props.url.split('/')[1] === 'assigned-candidate' ?
                      'Assigned Candidates List'
                    :
                      'Candidates List'
                  }
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                      {
                        data.enabled ? 
                          <Tag color="success">Active</Tag>
                        :
                          <Tag color="error">Inactive</Tag>
                      }
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="biodata" id={data.id} email={data.email} />
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card 
              style={{ border: 'none' }}
              title={<Text strong>Biodata</Text>} 
              extra={<CButton key="1" className="btn-edit-candidate" type="primary-icon" icon="EditOutlined" action={data.enabled ? this.showModal : this.showConfirmActive} title="Edit"/>} 
            >
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Descriptions layout="vertical" column={1} size="small">
                    <Descriptions.Item label={<Text type="secondary">Pass Photo</Text>}>
                      {
                        data.enabled ?
                          <Space direction="vertical">
                            <Image width={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                            <ImgCrop>
                              <Upload {...this.handleUpload()}>
                                <Button type="primary" size="small" ghost style={{ borderRadius: 6 }}>{data.profilePicUrl ? 'Change Photo' : 'Upload Photo'}</Button>
                              </Upload>
                            </ImgCrop>
                          </Space>
                        :
                          <Button type="primary" size="small" ghost style={{ borderRadius: 6 }} onClick={this.showConfirmActive}>{data.profilePicUrl ? 'Change Photo' : 'Upload Photo'}</Button>
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">About</Text>}>
                      {data.about ? data.about : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={24}>
                  <Descriptions layout="vertical" column={2} size="small">
                    <Descriptions.Item label={<Text type="secondary">Full Name</Text>}>
                      {data.fullName ? data.fullName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Nationality</Text>}>
                      {data.nationality ? data.nationality : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Gender</Text>}>
                      {data.gender ? data.gender === "FEMALE" ? "Female" : "Male" : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Marital Status</Text>}>
                      {
                        data.marital ?
                        <Text>
                          {data.marital === 'SINGLE' && 'Single'}
                          {data.marital === 'MARRIED' && 'Married'}
                          {data.marital === 'PROTESTAN' && 'Protestan'}
                          {data.marital === 'DIVORCE' && 'Divorce'}
                          {data.marital === 'WIDOWED' && 'Widowed'}
                        </Text>
                        : 
                        'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Place, Date of Birth</Text>}>
                      {data.placeOfBirth ? data.placeOfBirth : 'N/A'}, {data.birthdate ? moment(data.birthdate, 'DD/MM/YYYY').format('DD MMM YYYY') : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Religion</Text>}>
                      {
                        data.religion ?
                        <Text>
                          {data.religion === 'ISLAM' && 'Islam'}
                          {data.religion === 'CATHOLIC' && 'Catholic'}
                          {data.religion === 'PROTESTAN' && 'Protestan'}
                          {data.religion === 'BUDDHA' && 'Buddha'}
                          {data.religion === 'HINDU' && 'Hindu'}
                          {data.religion === 'OTHERS' && 'Others'}
                        </Text>
                        : 
                        'N/A'
                      }
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Current Location</Text>}>
                      {data.currentCityName ? data.currentCityName : 'N/A'}, {data.currentCountryName ? data.currentCountryName : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Blood Type</Text>}>
                      {data.bloodType ? data.bloodType : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Height</Text>}>
                      {data.height ? `${data.height} cm` : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">ID Card Number (KTP)</Text>}>
                      {data.identificationId ? data.identificationId : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Weight</Text>}>
                      {data.weight ? `${data.weight} kg` : 'N/A'}
                    </Descriptions.Item>
                    {
                      data.isAviation ?
                        <>
                          <Descriptions.Item label={<Text type="secondary">Arm Reach</Text>}>
                            {data.armReach ? `${data.armReach} cm` : 'N/A'}
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">Heart Trouble</Text>}>
                            {
                              data.listFamilyHistory[0]?.flagStatus !== null ?
                                data.listFamilyHistory[0]?.flagStatus ? 'Yes' : 'No'
                              : '-'
                            }
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">Diabetes</Text>}>
                            {
                              data.listFamilyHistory[1]?.flagStatus !== null ?
                                data.listFamilyHistory[1]?.flagStatus ? 'Yes' : 'No'
                              : '-'
                            }
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">Convulsion</Text>}>
                            {
                              data.listFamilyHistory[3]?.flagStatus !== null ?
                                data.listFamilyHistory[3]?.flagStatus ? 'Yes' : 'No'
                              : '-'
                            }
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">Cancer</Text>}>
                            {
                              data.listFamilyHistory[4]?.flagStatus !== null ?
                                data.listFamilyHistory[4]?.flagStatus ? 'Yes' : 'No'
                              : '-'
                            }
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">Tubercolosis</Text>}>
                            {
                              data.listFamilyHistory[2]?.flagStatus !== null ?
                                data.listFamilyHistory[2]?.flagStatus ? 'Yes' : 'No'
                              : '-'
                            }
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">Allergies</Text>}>
                            {
                              data.listFamilyHistory[5]?.flagStatus !== null ?
                                data.listFamilyHistory[5]?.flagStatus ? 'Yes' : 'No'
                              : '-'
                            }
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">Commited Suicides</Text>}>
                            {
                              data.listFamilyHistory[6]?.flagStatus !== null ?
                                data.listFamilyHistory[6]?.flagStatus ? 'Yes' : 'No'
                              : '-'
                            }
                          </Descriptions.Item>
                        </>
                      : null
                    }
                  </Descriptions>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        
        <Modal
          centered
          width={1000}
          visible={visible}
          title={<Text strong>Biodata</Text>}
          onCancel={this.onCancel}
          footer={[
            <React.Fragment key="1">
              <CButton key="back" className="btn-save-primary" type="default" action={this.onCancel} title="Cancel"/>
              <CButton key="submit" className="btn-save-primary" type="primary" action={this.onFinish} title="Save"/>
            </React.Fragment>
          ]}
        >
          <div style={{ height: 500, overflowY: 'auto', overflowX: 'hidden' }}>
            <EditBiodata 
              {...this.props} 
              formRef={this.formRef} 
              onFinish={this.onFinish} 
              handleCity={this.handleCity}
              handleRadio={this.handleRadio}
            />
          </div>
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetCountry, unmountGetCity } = this.props;
    unmountGetDetail()
    unmountGetCountry()
    unmountGetCity()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
  getCountry          : state.countries.master,
  getCity             : state.cities.master
})

const mapDispatchToProps = {
  actionUpdateAvatar  : updateAvatar,
  actionUpdateBiodata : updateBiodata,
  actionGetDetail     : detailCandidate,
  actionEnable        : enableCandidate,
  actionGetCountry    : masterCountries,
  actionGetCity       : masterCities,
  unmountGetDetail    : unmountDetailCandidate,
  unmountGetCountry   : unmountMasterCountries,
  unmountGetCity      : unmountMasterCities
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailBiodata)
