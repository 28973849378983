import React, { Component } from 'react';
import svg from '../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { detailCandidate, unmountDetailCandidate, updatePassword, enableCandidate } from '../../../../redux/actions/candidate/candidateAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Space, Avatar, Typography, Form, Input, Modal, Tag, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Menu from '../Menu';
import CButton from '../../../Button';
const { confirm } = Modal;
const { Text } = Typography;
const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return <Component {...rest} />
};
class CandidateDetailResetPassword extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id)
  }

  onFinish = (values) => {
    const { actionUpdate, getDetailCandidate: { data } } = this.props;
    values.username = data.email
    this.setState({ submitLoading: true })
    return actionUpdate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success("Password updated successfully")
        this.formRef.current.setFieldsValue({
          newPassword: null,
          retypeNewPassword: null,
        });
      })
    }, (err) => message.error(err))
  }
  
  showConfirmActive = () => {
    const { actionEnable, actionGetDetail ,match: { params } } = this.props;
    confirm({
      title: 'Unable to edit profile due to candidate status is inactive. Do you want to change candidate status to active?',
      icon: <ExclamationCircleOutlined />,
      width: 700,
      onOk() {
        return actionEnable(params.id, () => {
          message.success('Successfully changed to active')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
      okText: 'Change to Active',
      onCancel() {},
    });
  };

  render() {
    const { submitLoading } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type={this.props.icon} />
                {
                  this.props.url.split('/')[1] === 'all-candidate' ?
                    ' All Candidates'
                  : this.props.url.split('/')[1] === 'new-candidate' ?
                    ' New Candidates'
                  : this.props.url.split('/')[1] === 'counseling-process' ?
                    ' Counseling Process'
                  : this.props.url.split('/')[1] === 'assigned-candidate' ?
                    ' Assigned Candidates'
                  :
                    ' Candidates'
                }
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to={`/${this.props.url.split('/')[1]}`}>
                  {
                    this.props.url.split('/')[1] === 'all-candidate' ?
                      'All Candidates List'
                    : this.props.url.split('/')[1] === 'new-candidate' ?
                      'New Candidates List'
                    : this.props.url.split('/')[1] === 'counseling-process' ?
                      'Counseling Process List'
                    : this.props.url.split('/')[1] === 'assigned-candidate' ?
                      'Assigned Candidates List'
                    :
                      'Candidates List'
                  }
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                      {
                        data.enabled ? 
                          <Tag color="success">Active</Tag>
                        :
                          <Tag color="error">Inactive</Tag>
                      }
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="resetPassword" id={data.id} email={data.email}/>
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card title={<Text strong>Reset Password</Text>} style={{ border: 'none' }}>
              <Form layout="vertical" onFinish={data.enabled ? this.onFinish : this.showConfirmActive} ref={this.formRef}>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      label="New Password"
                      name="newPassword"
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { pattern: /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)/, message: 'Your password isn’t strong enough' },
                        { min: 8, message: 'Password must be at least 8 characters long.' },
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                        label="Retype New Password"
                        name="retypeNewPassword"
                        validateFirst
                        dependencies={['newPassword']}
                        rules={[
                          { required: true, message: 'This is a required field'},
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('newPassword') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('Passwords do not match!'));
                            },
                          }),
                        ]}
                      >
                        <Input.Password />
                      </Form.Item>
                  </Col>
                  <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <CButton className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title="Update"/>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail } = this.props;
    unmountGetDetail()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
})

const mapDispatchToProps = {
  actionUpdate      : updatePassword,
  actionGetDetail   : detailCandidate,
  actionEnable      : enableCandidate,
  unmountGetDetail  : unmountDetailCandidate,
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailResetPassword)
