import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { InfoCircleOutlined, DatabaseOutlined } from '@ant-design/icons';
import { cityByProvince } from '../../../../../redux/actions/master/cities/citiesAction';
import { provinceByCountry } from '../../../../../redux/actions/master/provinces/provincesAction';
import { createSubDistrict } from '../../../../../redux/actions/master/subDistricts/subDistrictsAction';
import { Col, Form, Row, Input, Select, PageHeader, message, Card, Breadcrumb, Typography } from 'antd';
import CButton from '../../../../../components/Button';

class CreateMasterSubDistrict extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      submitLoading: false,
      dataProvince: [],
      dataCity: []
    }
  }

  componentDidMount() {
    const { actionGetProvince } = this.props;
    return actionGetProvince(99, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null
    });
    return actionGetCity(value, (response) => {
      this.setState({ dataCity: response.data })
    })
  }

  onFinish = (values) => {
    const { actionCreate, history } = this.props;
    this.setState({ submitLoading: true })
    return actionCreate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data created successfully')
        history.push('/master/sub-districts')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading, dataProvince, dataCity } = this.state;

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} ref={this.formRef} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/sub-districts">
                    Master Data List Sub Districts
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Sub-Districts</Breadcrumb.Item>
                <Breadcrumb.Item>Create</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader 
                className="site-page-header" 
                title="Create a Sub-District"
                extra={[
                  <CButton key="2" className="btn-save-primary" type="default" action={() => window.history.back()} title="Cancel"/>,
                  <CButton key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title="Create"/>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="Country">
                      <Typography.Title level={4}>Indonesia</Typography.Title>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      label="Province" 
                      name="provinceId"
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a province"
                        onChange={this.handleChangeProvince}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataProvince.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="City" 
                      name="cityId"
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a city"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataCity.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      label="Sub-District Name" 
                      name="name" 
                      validateFirst
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: '50 characters only' },
                        { pattern: new RegExp(/^([^0-9]*)$/), message: 'Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled" 
                      initialValue={true}
                      tooltip={{ 
                        title: 'Inactive means this sub district isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option value={true}>Active</Select.Option>
                        <Select.Option value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Description" 
                      name="description" 
                      initialValue=''
                      rules={[
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  actionCreate        : createSubDistrict,
  actionGetProvince   : provinceByCountry,
  actionGetCity       : cityByProvince
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateMasterSubDistrict)
