import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Form, Row, Col, Typography, Descriptions, Input, Space, Skeleton, Statistic, message } from 'antd';
import CButton from '../../../../components/Button';
const { Text } = Typography;
const { Countdown } = Statistic;

export default class EditEmail extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      valueEmail: null,
      coundown: Date.now() + 60 * 2000,
      isCoundown: false
    }
  }

  componentDidMount() {
    const { actionGetEmail } = this.props;
    return actionGetEmail('employers')
  }

  onFinishCoundown = () => {
    this.setState({ isCoundown: false })
  }

  checkEmail = (value) => {
    const { actionCheckEmail, getEmail: { data } } = this.props;
    const oldEmail = data.email
    if(oldEmail === value){
      return null
    }else{
      return actionCheckEmail(value, (response) => {
        if(response){
          message.error('Email already registered')
          this.formRef.current.setFieldsValue({
            email: null,
          });
        }
      })
    }
  }

  onFinish = (values) => {
   const { actionUpdateEmail } = this.props;
   return actionUpdateEmail('employers', values.email, (response) => {
     if(response.code === "1000"){
      this.setState({ 
        valueEmail: values.email, 
        isCoundown: true,
        coundown: Date.now() + 60 * 2000 }, () => {
        message.success('Enter the code we sent to verify your email before 2 minute', 10);
      })
     }else{
       return this.setState({ valueEmail: null }, () => {
        message.error(response.message)
       })
     }
   }, (err) => message.error(err))
  }

  onVerify = () => {
   const { actionActivationEmail, handleCancel } = this.props;
    this.formRef.current.validateFields().then(values => {
      const valueVerify = {
        'email': this.state.valueEmail,
        'activationCode': values.activationCode
      }
      return actionActivationEmail('employers', valueVerify, (response) => {
        if(response.code === "1000"){
          message.success('Email changed successfully')
          return handleCancel()
        }else{
          message.error("Invalid code")
        }
      }, (err) => message.error(err))
    })
  }

  resendEmail = () => {
    const { actionResendEmail } = this.props;
    const value = {
      'email': this.state.valueEmail
    }
    return actionResendEmail('employers', value, (response) => {
      if(response.code === "1000"){
        this.setState({ 
          coundown: Date.now() + 60 * 2000,
          isCoundown: true
        })
        message.success('Resend Email success, enter the code we sent to verify your email before 2 minute', 10);
      }else{
        message.error(response.message)
      }
    })
  }

  onCancel = () => {
    const { handleCancel } = this.props;
    return handleCancel()
  }

  render() {
    const { valueEmail, submitLoading, coundown, isCoundown } = this.state;
    const { getEmail: { loading, data } } = this.props;
    if(loading){
      return <Skeleton />
    }
    return (
      <React.Fragment>
        <Form layout="vertical" onFinish={this.onFinish} ref={this.formRef}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Descriptions layout="vertical" column={1} size="small">
                <Descriptions.Item label="Current Email">{data.email}</Descriptions.Item>
              </Descriptions>
            </Col>

            {
              valueEmail ?
              <React.Fragment>
                <Col span={24}>
                  <Descriptions layout="vertical" column={1} size="small">
                    <Descriptions.Item label="New Company Email">{valueEmail}</Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={24}>
                  <Form.Item 
                    label="Enter the code we sent to verify your email" 
                    name="activationCode" 
                    validateFirst
                    extra={
                      <React.Fragment>
                        {
                          isCoundown ?
                          <Space>
                            <Text type="secondary">
                              Didn’t get the code?
                            </Text>
                            <Countdown value={coundown} onFinish={this.onFinishCoundown} style={{ fontSize: 6 }} />
                          </Space>
                          :
                          <Text type="secondary">
                            Didn’t get the code? <Link to="#" onClick={this.resendEmail}>Send code again</Link> 
                          </Text>
                        }
                      </React.Fragment>
                    }
                    rules={[
                      { required: true, message: 'This is a required field' },
                      { max: 100, message: 'Max length of email address is 100 characters' },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </React.Fragment>
              :
              <Col span={24}>
                <Form.Item 
                  label="New Company Email" 
                  name="email" 
                  validateFirst
                  rules={[
                    { required: true, message: 'This is a required field' },
                    { type: 'email', message: 'Your email address is invalid' }, 
                  ]}
                >
                  <Input type="email" onBlur={(e) => this.checkEmail(e.target.value)} />
                </Form.Item>
              </Col>
            }

            {
              valueEmail ?
              <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <CButton className="btn-save-primary" type="primary" action={this.onVerify} loading={submitLoading} title="Verify"/>
              </Col>
              :
              <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Space>
                  <CButton className="btn-cancel-danger" type="default" action={this.onCancel} title="Cancel"/>
                  <CButton className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title="Update"/>
                </Space>
              </Col>
            }
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
