import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../components';
import { UploadOutlined, ContactsOutlined, DeleteOutlined } from '@ant-design/icons';
import { checkEmail, checkPhone } from '../../../../redux/actions/check/checkAction';
import { cityByProvince } from '../../../../redux/actions/master/cities/citiesAction';
import { provinceByCountry } from '../../../../redux/actions/master/provinces/provincesAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/master/countries/countriesAction';
import { Card, Row, Col, PageHeader, Form, Input, Select, Upload, Button, Space, message, Breadcrumb, Image } from 'antd';
import { detailAgencies, editAgency, masterAgencies, unmountDetailAgencies, unmountMasterAgencies } from '../../../../redux/actions/agencies/agenciesAction';
import CButton from '../../../../components/Button';

class EditAgency extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
    
    this.state = {
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null,
      submitLoading: false,
      dataCity: [],
      callingCode: "62",
      dataProvince: [],
    }
  }
  
  componentDidMount() {
    const { actionGetCountry, actionGetProvince, actionGetCity, actionGetAgency, actionGetDetail, match: { params } } = this.props;
    actionGetDetail(params.id, (response) => {
      this.setState({ callingCode: Number(response.data.callingCode) })
      actionGetProvince(response.data.countryId, (response) => {
        this.setState({ dataProvince: response.data })
      })
      if(response.data.provinceId){
        actionGetCity(response.data.provinceId, (response) => {
          this.setState({ dataCity: response.data })
        })
      }
    })
    actionGetAgency()
    actionGetCountry()
  }

  checkEmail = (value) => {
    const { actionCheckEmail, getData: { data } } = this.props;
    const oldEmail = data.email
    if(oldEmail === value){
      return null
    }else{
      return actionCheckEmail(value, (response) => {
        if(response){
          message.error('Email already exist')
          this.formRef.current.setFieldsValue({
            email: data.email,
          });
        }
      })
    }
  }

  checkPhone = (value) => {
    const { callingCode } = this.state;
    const { actionCheckPhone, getData: { data } } = this.props;
    const valuePhone = `${callingCode}${value.replace(/^0+/, '')}`
    const oldMobilePhone = `${data.callingCode}${data.mobilePhone}`
    this.formRef.current.setFieldsValue({
      mobilePhone: value.replace(/^0+/, ''),
    });
    if(oldMobilePhone === valuePhone){
      return null
    }else{
      return actionCheckPhone(valuePhone.replace(/^0+/, ''), (response) => {
        if(response){
          message.error('Mobile phone already exist')
          this.formRef.current.setFieldsValue({
            mobilePhone: data.mobilePhone,
          });
        }
      })
    }
  }

  handleChangeCallingCode = (_, index) => {
    this.setState({ callingCode: index.children.replace(/^[+]+/, '') })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    });
  }
  
  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      provinceId: null,
      cityId: null,
    });
    return actionGetProvince(value, (response) => {
      this.setState({ dataProvince: response.data })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null
    });
    if(value !== undefined){
      return actionGetCity(value, (response) => {
        this.setState({ dataCity: response.data })
      })
    }else {
      this.setState({ dataCity: [] })
    }
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/*",
      beforeUpload: file => {
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      },
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  onFinish = (values) => {
    const { callingCode, editAvatar, avatar } = this.state;
    const { actionUpdate, history, getData: { data } } = this.props;
    values.mobilePhone = `${callingCode}${values.mobilePhone.replace(/^0+/, '')}`
    values.provinceId = values.provinceId ? values.provinceId : ''
    values.cityId = values.cityId ? values.cityId : ''
    values.userNo = data.userNo
    values.id = data.id
    if(editAvatar){
      values.logoFile = avatar
    }
    this.setState({ submitLoading: true })
    return actionUpdate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        history.push('/agencies')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { getCountry, getAgency, getData: { data, loading } } = this.props;
    const { submitLoading, editAvatar, onPreviewAvatar, dataProvince, dataCity } = this.state;
    
    if(loading || getCountry.loading || getAgency.loading){
      return <Loading />
    }

    const mobileNumber = (
      <Form.Item 
        noStyle
        name="callingCodeId" 
        initialValue={data.callingCodeId ? data.callingCodeId : 99} 
        rules={[ { required: true, message: 'Please input your calling code' } ]}
      >
        <Select 
          showSearch
          className="prefix-selector"
          optionFilterProp="children"
          onChange={this.handleChangeCallingCode}
        >
          {
            getCountry?.data.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {`+${item.callingCode}`}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>
    );

    return (
      <React.Fragment>
        <Form layout="vertical" ref={this.formRef} onFinish={this.onFinish} scrollToFirstError={true}>
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><ContactsOutlined /> Agencies</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/agencies">Agencies List</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{data.name ? data.name : '-'}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>

            { /* Page Header */}
            <Col span={24}>
              <PageHeader
                className="site-page-header"
                title='Update an Agency'
                extra={[
                  <CButton key="2" className="btn-save-primary" type="default" action={() => window.history.back()} title="Cancel"/>,
                  <CButton key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title="Update"/>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {/* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>

                  <Col span={12}>
                    <Form.Item 
                      label="Agency Name" 
                      name="name" 
                      validateFirst
                      initialValue={data.name ? data.name : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: 'Agency Name is too long. Maximum is 50 characters' },
                        // { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Agency Email" 
                      name="email" 
                      validateFirst
                      initialValue={data.email ? data.email : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { type: 'email', message: 'Your email address is invalid' }, 
                      ]}
                    >
                      <Input type="email" onBlur={(e) => this.checkEmail(e.target.value)} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person" 
                      name="picName"
                      validateFirst
                      initialValue={data.picName ? data.picName : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },               
                        { max: 50, message: 'Agency Name is too long. Maximum is 50 characters' },
                        { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person Mobile Number" 
                      name="mobilePhone"
                      validateFirst
                      initialValue={data.mobilePhone ? data.mobilePhone : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 4, message: 'Your mobile number is invalid' },
                        { max: 13, message: 'Your mobile number is invalid' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input addonBefore={mobileNumber} onBlur={(e) => this.checkPhone(e.target.value)} />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Agency Type" 
                      name="agencyTypeId" 
                      initialValue={data.agencyTypeId ? data.agencyTypeId : ''}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getAgency?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Contact Person Email" 
                      name="picEmail" 
                      initialValue={data.picEmail ? data.picEmail : ''}
                      rules={[
                        { type: 'email', message: 'Your email address is invalid' }
                      ]}
                    >
                      <Input type="email" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Office Phone Number" 
                      name="officePhone" 
                      validateFirst
                      initialValue={data.officePhone ? data.officePhone : ''}
                      rules={[
                        { min: 4, message: 'Your mobile number is invalid' },
                        { max: 13, message: 'Your mobile number is invalid' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },  
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Country" 
                      name="countryId"
                      initialValue={data.countryId ? data.countryId : ''}
                    >
                      <Select
                        showSearch
                        placeholder="Select a country"
                        optionFilterProp="children"
                        onChange={this.handleChangeCountry}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          getCountry?.data.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Province" 
                      name="provinceId"
                      initialValue={data.provinceId ? data.provinceId : null}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a province"
                        onChange={this.handleChangeProvince}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataProvince.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="City" 
                      name="cityId"
                      initialValue={data.cityId ? data.cityId : null}
                    >
                      <Select
                        showSearch
                        allowClear
                        placeholder="Select a city"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {
                          dataCity.map(item => (
                            <Select.Option key={item.id} value={item.id}>
                              {item.name}
                            </Select.Option>
                          ))
                        }
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Street Address" 
                      name="address"
                      initialValue={data.address ? data.address : ''}
                      rules={[
                        { max: 100, message: 'Address is too long. Maximum is 100 characters' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Postal Code" 
                      name="zipCode"
                      validateFirst
                      initialValue={data.zipCode ? data.zipCode : ''}
                      rules={[
                        { max: 11, message: 'Maximum postal code is 11 characters' },
                        { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item label="Logo">
                      {
                        data.logoUrl ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : data.logoUrl ? data.logoUrl : null} />
                        </Form.Item>
                        :
                        editAvatar ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                        </Form.Item>
                        :
                        null
                      }
                      <Space>
                        <Upload {...this.handleUpload()}>
                          <Button className="btn-radius" icon={<UploadOutlined />}>{data.logoUrl ? 'Change File' : editAvatar ? 'Change File' : 'Upload a File'}</Button>
                        </Upload>
                        {
                          editAvatar ? 
                          <Button className="btn-radius" icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                          : null
                        }
                      </Space>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Remarks" 
                      name="remarks"
                      initialValue={data.remarks ? data.remarks : ''}
                      rules={[
                        { max: 50, message: 'Maximum is 50 characters' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
  componentWillUnmount(){
    const { unmountGetCountry, unmountGetAgency, unmountGetDetail } = this.props;
    unmountGetDetail()
    unmountGetAgency()
    unmountGetCountry()
  }
}

const mapStateToProps = (state) => ({
  getData     : state.agencies.detail,
  getCountry  : state.countries.master,
  getAgency   : state.agencies.master
})

const mapDispatchToProps = {
  actionUpdate        : editAgency,
  actionCheckEmail    : checkEmail,
  actionCheckPhone    : checkPhone,
  actionGetDetail     : detailAgencies,
  actionGetCountry    : masterCountries,
  actionGetProvince   : provinceByCountry,
  actionGetCity       : cityByProvince,
  actionGetAgency     : masterAgencies,
  unmountGetCountry   : unmountMasterCountries,
  unmountGetAgency    : unmountMasterAgencies,
  unmountGetDetail    : unmountDetailAgencies
}

export default connect(mapStateToProps, mapDispatchToProps)(EditAgency)