import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Loading } from '../../../../../components';
import { InfoCircleOutlined, DatabaseOutlined, UploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Col, Form, Row, Input, Button, Space, Select, PageHeader, message, Card, Breadcrumb, Image, Upload } from 'antd';
import { detailJobIndustry, editJobIndustry, unmountDetailJobIndustries } from '../../../../../redux/actions/master/jobIndustries/jobIndustriesAction';
import CButton from '../../../../../components/Button';

class EditMasterJobIndustries extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null,
      isDisplay: false
    }
  }

  componentDidMount() {
    const { actionGetDetail, match: { params } } = this.props;
    return actionGetDetail(params.id, (response) => {
      this.setState({ isDisplay: response.data.isDisplay })
    })
  }

  handleIsDisplay = value => {
    this.setState({ isDisplay: value })
  }

  handleUpload(){
    return {
      showUploadList: false,
      withCredentials: true,
      accept:"image/svg+xml",
      beforeUpload: file => {
        const validateFile = 'image/svg+xml'
        const validateSize = file.size >= 500000;
        if (validateSize) {
          message.error('Max file size is 500 KB!');
          return false
        }
        if (!validateFile) {
          message.error('SVG Only!');
          return false
        }
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          this.setState({ 
            avatar: file, 
            editAvatar: true,
            onPreviewAvatar: e.target.result, 
          })
        }
        return false;
      }
    }
  }

  handleDeleteFile = () => {
    this.setState({
      avatar: null,
      editAvatar: false,
      onPreviewAvatar: null
    })
  }

  onFinish = (values) => {
    const { actionUpdate, history, getData: { data } } = this.props;
    if(this.state.editAvatar){
      values.iconFile = this.state.avatar
    }
    values.iconSvg = ''
    values.id = data.id
    values.sort = values.sort ? Number(values.sort) : ''
    this.setState({ submitLoading: true })
    return actionUpdate(values, () => {
      this.setState({ submitLoading: false }, () => {
        message.success('Data updated successfully')
        history.push('/master/job-industries')
      })
    }, (err) => {
      this.setState({ submitLoading: false }, () => message.error(err))
    })
  }

  render() {
    const { submitLoading, editAvatar, onPreviewAvatar, isDisplay } = this.state;
    const { getData: { loading, data } } = this.props;

    if(loading){
      return <Loading />
    }

    return (
      <React.Fragment>
        <Form onFinish={this.onFinish} layout="vertical">
          <Row style={{ position: 'sticky', zIndex: 1, width: '100%', top: 62, right: 0, marginTop: -20, marginBottom: 8, paddingBottom: 12, paddingTop: 16, backgroundColor: '#f2f2f2' }}>
            {/* Breadcrumb */}
            <Col span={24}>
              <Breadcrumb>
                <Breadcrumb.Item><DatabaseOutlined /> Master Data</Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link className="link" to="/master/job-industries">
                    Master Data List Job Industries
                  </Link> 
                </Breadcrumb.Item>
                <Breadcrumb.Item>Job Industries</Breadcrumb.Item>
                <Breadcrumb.Item>{data.title}</Breadcrumb.Item>
              </Breadcrumb>
            </Col>
            { /* Page Header */}
            <Col span={24}>
              <PageHeader className="site-page-header" title="Update Job Industry Data" />
              <PageHeader 
                className="site-page-header" 
                title="Update Job Industry Data"
                extra={[
                  <CButton key="2" className="btn-save-primary" type="default" action={() => window.history.back()} title="Cancel"/>,
                  <CButton key="1" className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title="Update"/>
                ]}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            { /* Form */}
            <Col span={24}>
              <Card>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item 
                      label="Job Industry Name" 
                      name="title" 
                      validateFirst
                      initialValue={data.title}
                      rules={[
                        { required: true, message: 'This is a required field' },
                        { min: 3, message: 'Name must be at least 3 characters long' },
                        { max: 50, message: '50 characters only' },
                        { pattern: new RegExp("^[a-zA-Z &]+$"), message: 'Number and special character are not allowed' },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Will it be displayed in the candidate's side?" 
                      name="isDisplay" 
                      initialValue={data.isDisplay}
                      tooltip={{ 
                        title: 'If you choose yes, this job industry will be displayed in the Job Preferences option', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select onChange={this.handleIsDisplay}>
                        <Select.Option value={true}>Yes</Select.Option>
                        <Select.Option value={false}>No</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Status" 
                      name="enabled" 
                      initialValue={data.enabled}
                      tooltip={{ 
                        title: 'Inactive means this job industry isn’t used in master data', 
                        icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                      }}
                      rules={[
                        { required: true, message: 'This is a required field' },
                      ]}
                    >
                      <Select>
                        <Select.Option key="1" value={true}>Active</Select.Option>
                        <Select.Option key="2" value={false}>Inactive</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Description" 
                      name="description"
                      initialValue={data.description ? data.description : ''}
                      rules={[
                        { max: 50, message: '50 characters only' },
                      ]}
                    >
                      <Input placeholder="Description" />
                    </Form.Item>
                  </Col>

                  <Col span={12}>
                    <Form.Item 
                      label="Icon (SVG Only)" 
                      name="iconSvg"
                      initialValue={editAvatar ? this.state.avatar : data.iconUrl}
                      rules={[
                        { required: isDisplay ? true ? editAvatar ? false : true : true : false , message: 'Please upload your icon' }
                      ]}
                    >
                      {
                        data.iconUrl ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : data.iconUrl ? data.iconUrl : null} />
                        </Form.Item>
                        :
                        editAvatar ?
                        <Form.Item>
                          <Image width={100} src={editAvatar ? onPreviewAvatar : null} />
                        </Form.Item>
                        :
                        null
                      }
                      <Space>
                        <Upload {...this.handleUpload()}>
                          <Button icon={<UploadOutlined />}>{data.iconUrl ? 'Change File' : editAvatar ? 'Change File' : 'Upload a File'}</Button>
                        </Upload>
                        {
                          editAvatar ? 
                          <Button icon={<DeleteOutlined />} type="danger" ghost onClick={this.handleDeleteFile}>Delete</Button>
                          : null
                        }
                      </Space>
                    </Form.Item>
                  </Col>

                  {
                    isDisplay ?
                    <Col span={12}>
                      <Form.Item 
                        label="Sort" 
                        name="sort"
                        tooltip={{ 
                          title: 'the sort number will match the order in which it is displayed', 
                          icon: <InfoCircleOutlined style={{ color: '#2586eb' }} />
                        }}
                        initialValue={data.sort ? data.sort : ''}
                        rules={[
                          { pattern: new RegExp("^[0-9]+$"), message: 'Please enter on numerics only' },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    :
                    null
                  }
                </Row>
              </Card>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  getData: state.jobIndustries.detail
})

const mapDispatchToProps = {
  actionUpdate      : editJobIndustry,
  actionGetDetail   : detailJobIndustry,
  unmountGetDetail  : unmountDetailJobIndustries
}

export default connect(mapStateToProps, mapDispatchToProps)(EditMasterJobIndustries)
