import React, { Component } from 'react';
import EditSocial from './EditSocial';
import EditContact from './EditContact';
import svg from '../../../../assets/img/profile.svg';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkEmail, checkPhone } from '../../../../redux/actions/check/checkAction';
import { cityByProvince } from '../../../../redux/actions/master/cities/citiesAction';
import { provinceByCountry } from '../../../../redux/actions/master/provinces/provincesAction';
import { villageBySubDistrict } from '../../../../redux/actions/master/villages/villagesAction';
import { subDistrictByCity } from '../../../../redux/actions/master/subDistricts/subDistrictsAction';
import { masterCountries, unmountMasterCountries } from '../../../../redux/actions/master/countries/countriesAction';
import { updateContact, addSocial, updateAddress, createAddressStudent, updateAddressStudent, deleteAddressStudent, deleteSocial, detailCandidate, unmountDetailCandidate, enableCandidate } from '../../../../redux/actions/candidate/candidateAction';
import { Skeleton, Row, Col, Breadcrumb, PageHeader, Card, Space, Avatar, Typography, Descriptions, Divider, Modal, Tag, message } from 'antd';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Menu from '../Menu';
import EditAddress from './EditAddress';
import CButton from '../../../Button';
const { Title, Text } = Typography;
const { confirm } = Modal;
const Icon = ({ type, ...rest }) => {
  const icons = require(`@ant-design/icons`);
  const Component = icons[type];
  return <Component {...rest} />
};
class CandidateDetailContactInformation extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      visibleContact: false,
      visibleSocial: false,
      visibleAddress: false,
      callingCode: "62",
      type: null,
      dataEdit: null,
      dataProvince: [],
      dataCity: [],
      dataSubDistrict: [],
      dataVillage: [],
      name: null,
      socialMedia: null,
      url: null
    }
  }

  componentDidMount() {
    const { 
      actionGetDetail, 
      actionGetCountry, 
      actionGetProvince, 
      actionGetCity, 
      actionGetSubDistrict, 
      actionGetVillage, 
      match: { params } 
    } = this.props;
    actionGetDetail(params.id, (response) => {
      this.setState({ 
        callingCode: Number(response.data.callingCode) 
      })
      if(response.data.countryId){
        actionGetProvince(response.data.countryId, (response) => {
          this.setState({ dataProvince: response.data })
        })
      }
      if(response.data.provinceId){
        actionGetCity(response.data.provinceId, (response) => {
          this.setState({ dataCity: response.data })
        })
      }
      if(response.data.cityId){
        actionGetSubDistrict(response.data.cityId, (response) => {
          this.setState({ dataSubDistrict: response.data })
        })
      }
      if(response.data.subDistrictId){
        actionGetVillage(response.data.subDistrictId, (response) => {
          this.setState({ dataVillage: response.data })
        })
      }
    })
    actionGetCountry()
  }

  showModal = (type, val) => {
    const { 
      actionGetProvince, 
      actionGetCity, 
      actionGetSubDistrict, 
      actionGetVillage,
    } = this.props;
    if(type === "contact"){
      return this.setState({ visibleContact: true })
    }
    if(type === "social"){
      return this.setState({ visibleSocial: true })
    }
    if(type === "address"){
      return this.setState({ visibleAddress: true })
    }
    if(type === "addressStudent"){
      if(val.countryId){
        actionGetProvince(val.countryId, (response) => {
          this.setState({ dataProvince: response.data })
        })
      }
      if(val.provinceId){
        actionGetCity(val.provinceId, (response) => {
          this.setState({ dataCity: response.data })
        })
      }
      if(val.cityId){
        actionGetSubDistrict(val.cityId, (response) => {
          this.setState({ dataSubDistrict: response.data })
        })
      }
      if(val.subDistrictId){
        actionGetVillage(val.subDistrictId, (response) => {
          this.setState({ dataVillage: response.data })
        })
      }
      return this.setState({ visibleAddress: true, dataEdit: val, type: val.type })
    }
  }

  onCancel = (type) => {
    if(type === "contact"){
      return this.setState({ visibleContact: false })
    }
    if(type === "social"){
      return this.setState({ 
        visibleSocial: false, 
        name: null,
        socialMedia: null,
        url: null
      })
    }
    if(type === "address"){
      return this.setState({ visibleAddress: false, dataEdit: null, type: null })
    }
  }

  checkEmail = (value) => {
    const { actionCheckEmail, getDetailCandidate: { data } } = this.props;
    const oldEmail = data.email
    if(oldEmail === value){
      return null
    }else{
      return actionCheckEmail(value, (response) => {
        if(response){
          message.error('Email already exist')
          this.formRef.current.setFieldsValue({
            email: data.email,
          });
        }
      })
    }
  }

  checkPhone = (value) => {
    const { actionCheckPhone, getDetailCandidate: { data } } = this.props;
    const valuePhone = `${this.state.callingCode}${value.replace(/^0+/, '')}`
    const oldMobilePhone = `${data.callingCode}${data.mobilePhone}`
    this.formRef.current.setFieldsValue({
      mobilePhone: value.replace(/^0+/, ''),
    });
    if(oldMobilePhone === valuePhone){
      return null
    }else{
      return actionCheckPhone(valuePhone.replace(/^0+/, ''), (response) => {
        if(response){
          message.error('Mobile phone already exist')
          this.formRef.current.setFieldsValue({
            mobilePhone: data.mobilePhone,
          });
        }
      })
    }
  }

  handleChangeCallingCode = (_, index) => {
    this.setState({ callingCode: index.children.replace(/^[+]+/, '') })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    });
  }

  handleType = (value) => {
    this.formRef.current.setFieldsValue({ typeOther: null })
    this.setState({ type: value })
  }

  handleChangeCountry = (value) => {
    const { actionGetProvince } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null,
      provinceId: null,
      subDistrictId: null,
      villageId: null
    });
    return actionGetProvince(value, (response) => {
      this.setState({ 
        dataProvince: response.data, 
        dataCity: [], 
        dataSubDistrict: [], 
        dataVillage: [] 
      })
    })
  }

  handleChangeProvince = (value) => {
    const { actionGetCity } = this.props;
    this.formRef.current.setFieldsValue({
      cityId: null,
      subDistrictId: null,
      villageId: null
    });
    return actionGetCity(value, (response) => {
      this.setState({ 
        dataCity: response.data,
        dataSubDistrict: [], 
        dataVillage: []
      })
    })
  }

  handleChangeCity = (value) => {
    const { actionGetSubDistrict } = this.props;
    this.formRef.current.setFieldsValue({
      subDistrictId: null,
      villageId: null
    });
    return actionGetSubDistrict(value, (response) => {
      this.setState({ 
        dataSubDistrict: response.data,
        dataVillage: []
      })
    })
  }

  handleChangeSubDistrict = (value) => {
    const { actionGetVillage } = this.props;
    this.formRef.current.setFieldsValue({
      villageId: null
    });
    return actionGetVillage(value, (response) => {
      this.setState({ dataVillage: response.data })
    })
  }

  onChangeSelect = (e) => {
    const socialMedia =
      e === 'linkedin' ? 
        'www.linkedin.com/in/'
      : e === 'facebook' ? 
        'www.facebook.com/'
      : e === 'twitter' ? 
        'www.twitter.com/'
      : e === 'instagram' ? 
        'www.instagram.com/'
      :
        'medium.com'
    this.setState({ name: e, socialMedia: socialMedia })
  }

  onChangeInput = (e) => {
    this.setState({ url: e })
  }

  handleDelete = (id) => {
    const { actionDeleteSocial, actionGetDetail, match: { params } } = this.props;
    return actionDeleteSocial(id, () => {
      message.success('Data deleted successfully')
      return actionGetDetail(params.id)
    }, (err) => message.error(err))
  }

  onSubmitContact = () => {
    const { actionUpdateContact, actionGetDetail, match: { params } } = this.props;
    this.formRef.current.validateFields().then(values => {
      values.id = Number(params.id)
      return actionUpdateContact(values, () => {
        return this.setState({ visibleContact: false }, () => {
          message.success('Data updated successfully')
          return actionGetDetail(params.id)
        })
      })
    })
  }

  onSubmitSocial = () => {
    const { socialMedia } = this.state;
    const { actionAddSocial, actionGetDetail, match: { params } } = this.props
    this.formRef.current.validateFields().then(values => {
      values.candidateId = params.id
      values.url = values.name === 'medium' ? `${values.url}.${socialMedia}` : `${socialMedia}${values.url}`
      return actionAddSocial(values, () => {
        this.formRef.current.setFieldsValue({
          name: null,
          url: null,
        });
        this.setState({ name: null, socialMedia: null }, () => {
          message.success('Data updated successfully')
          return actionGetDetail(params.id)
        })
      }, (err) => message.error(err))
    })
  }

  onSubmitAddress = () => {
    const { actionUpdateAddress, actionCreateAddressStudent, actionUpdateAddressStudent, actionGetDetail, match: { params }, getDetailCandidate } = this.props;
    const { dataEdit } = this.state
    this.formRef.current.validateFields().then(values => {
      if(getDetailCandidate.data.email.split("@")[1] === 'student.binawan.ac.id'){
        values.candidateId = Number(params.id)
        if(dataEdit?.id){
          values.id = Number(dataEdit?.id)
          return actionUpdateAddressStudent(values, () => {
            return this.setState({ visibleAddress: false, dataEdit: null, type: null }, () => {
              message.success('Data updated successfully')
              return actionGetDetail(params.id)
            })
          })
        }else{
          return actionCreateAddressStudent(values, () => {
            return this.setState({ visibleAddress: false }, () => {
              message.success('Data created successfully')
              return actionGetDetail(params.id)
            })
          })
        }
      }else{
        values.id = Number(params.id)
        return actionUpdateAddress(values, () => {
          return this.setState({ visibleAddress: false, dataEdit: null, type: null }, () => {
            message.success('Data updated successfully')
            return actionGetDetail(params.id)
          })
        })
      }
    })
  }

  handleDeleteAddressStudent = (id) => {
    const { actionDeleteAddressStudent, actionGetDetail, match: { params } } = this.props;
    confirm({
      title: 'Remove Data',
      okText: 'Yes',
      cancelText: 'No',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to remove this data?',
      onOk() {
        return actionDeleteAddressStudent(id, () => {
          message.success('Data deleted successfully')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
    });
  }

  showConfirmActive = () => {
    const { actionEnable, actionGetDetail ,match: { params } } = this.props;
    confirm({
      title: 'Unable to edit profile due to candidate status is inactive. Do you want to change candidate status to active?',
      icon: <ExclamationCircleOutlined />,
      width: 700,
      onOk() {
        return actionEnable(params.id, () => {
          message.success('Successfully changed to active')
          return actionGetDetail(params.id)
        }, (err) => message.error(err))
      },
      okText: 'Change to Active',
      onCancel() {},
    });
  };

  render() {
    const { visibleContact, visibleSocial, visibleAddress } = this.state;
    const { getDetailCandidate: { loading, data } } = this.props;
    if(loading){
      return <Skeleton avatar />
    }
    return (
      <React.Fragment>
        <Row gutter={[16, 16]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Icon type={this.props.icon} />
                {
                  this.props.url.split('/')[1] === 'all-candidate' ?
                    ' All Candidates'
                  : this.props.url.split('/')[1] === 'new-candidate' ?
                    ' New Candidates'
                  : this.props.url.split('/')[1] === 'counseling-process' ?
                    ' Counseling Process'
                  : this.props.url.split('/')[1] === 'assigned-candidate' ?
                    ' Assigned Candidates'
                  :
                    ' Candidates'
                }
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link className="link" to={`/${this.props.url.split('/')[1]}`}>
                  {
                    this.props.url.split('/')[1] === 'all-candidate' ?
                      'All Candidates List'
                    : this.props.url.split('/')[1] === 'new-candidate' ?
                      'New Candidates List'
                    : this.props.url.split('/')[1] === 'counseling-process' ?
                      'Counseling Process List'
                    : this.props.url.split('/')[1] === 'assigned-candidate' ?
                      'Assigned Candidates List'
                    :
                      'Candidates List'
                  }
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>Candidates Detail</Breadcrumb.Item>
              <Breadcrumb.Item>
                {data.fullName}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='Candidate Detail' />
          </Col>
          {/* Menu */}
          <Col span={7}>
            <Card style={{ border: 'none' }}>
              <Row gutter={[16, 24]}>
                <Col span={24}>
                  <Space direction="horizontal">
                    <Avatar size={100} src={data.profilePicUrl ? data.profilePicUrl : svg} />
                    <Space direction="vertical" size="small">
                      <Text style={{ color: '#4d4d4d', fontSize: 18 }} strong>{data.fullName}</Text>
                      <Text type="secondary">{data.candidateNumber}</Text>
                      {
                        data.enabled ? 
                          <Tag color="success">Active</Tag>
                        :
                          <Tag color="error">Inactive</Tag>
                      }
                    </Space>
                  </Space>
                </Col>
                <Col span={24}>
                  <Menu rowKey="contactInformation" id={data.id} email={data.email} />
                </Col>
              </Row>
            </Card>
          </Col>
          {/* Content */}
          <Col span={17}>
            <Card title={<Text strong>Contact Information</Text>} style={{ border: 'none' }}>
              <Row gutter={[16, 16]}>
                <Col span={21}>
                  <Descriptions column={2} layout="vertical" size="small">
                    <Descriptions.Item label={<Text type="secondary">Email</Text>}>
                      {data.email ? data.email : 'N/A'}
                    </Descriptions.Item>
                    <Descriptions.Item label={<Text type="secondary">Mobile Number</Text>}>
                      {data.mobilePhone ? `+${data.callingCode}${data.mobilePhone}` : 'N/A'}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CButton className="btn-edit-candidate" type="primary-icon" icon='EditOutlined' action={data.enabled ?  () => this.showModal('contact') : this.showConfirmActive} title="Edit"/>
                </Col>
                <Divider dashed />
                <Col span={21}>
                  <Descriptions title="Social Media" column={2} layout="vertical" size="small">
                    {
                      data.listSocialMedia.length > 0 ?
                      <React.Fragment>
                        {
                          data.listSocialMedia.map((item, i) => (
                            <Descriptions.Item key={i} label={<Text type="secondary">{item.name}</Text>}>
                              <a href={`https://${item.url}`} target="_blank" rel='noreferrer' style={{ color: '#0076de' }}>
                                {item.url}
                              </a>
                            </Descriptions.Item>
                          ))
                        }
                      </React.Fragment>
                      :
                      <Descriptions.Item>
                        N/A
                      </Descriptions.Item>
                    }
                  </Descriptions>
                </Col>
                <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <CButton className="btn-edit-candidate" type="primary-icon" icon='EditOutlined' action={data.enabled ? () => this.showModal('social') : this.showConfirmActive} title="Edit"/>
                </Col>
                <Divider dashed />
                {
                  data.email.split("@")[1] === 'student.binawan.ac.id' ?
                    <>
                      <Col span={24}>
                        <Title level={5}>Address</Title>
                      </Col>
                      {
                        data.listAddress.length > 0 ?
                          data.listAddress.map((item,i) =>
                            <React.Fragment key={i}>
                              <Col span={21}>
                                <Descriptions title={<Text style={{ fontWeight: 'normal', color: '#32ab6d' }}>{item.type === "OTHER" ? item.typeOther : item.type === "HOME" ? "Home" : "Work" }</Text>} column={2} layout="vertical" size="small">
                                  <Descriptions.Item label={<Text type="secondary">Country</Text>}>
                                    {item.countryName ? item.countryName : 'N/A'}
                                  </Descriptions.Item>
                                  <Descriptions.Item label={<Text type="secondary">Province</Text>}>
                                    {item.provinceName ? item.provinceName : 'N/A'}
                                  </Descriptions.Item>
                                  <Descriptions.Item label={<Text type="secondary">City</Text>}>
                                    {item.cityName ? item.cityName : 'N/A'}
                                  </Descriptions.Item>
                                  <Descriptions.Item label={<Text type="secondary">Sub-district</Text>}>
                                    {item.subDistrictName ? item.subDistrictName : 'N/A'}
                                  </Descriptions.Item>
                                  <Descriptions.Item label={<Text type="secondary">Village</Text>}>
                                    {item.villageName ? item.villageName : 'N/A'}
                                  </Descriptions.Item>
                                  <Descriptions.Item label={<Text type="secondary">Postal Code</Text>}>
                                    {item.zipCode ? item.zipCode : 'N/A'}
                                  </Descriptions.Item>
                                  <Descriptions.Item label={<Text type="secondary">Street Address</Text>}>
                                    {item.address ? item.address : 'N/A'}
                                  </Descriptions.Item>
                                </Descriptions>
                              </Col>
                              <Col span={3}>
                                <Space style={{ float: 'right' }}>
                                  <EditOutlined onClick={() => this.showModal('addressStudent',item)} style={{ color: '#32ab6d' }} />
                                  <DeleteOutlined onClick={() => this.handleDeleteAddressStudent(item.id)} style={{ color: '#ff3e3e' }} />
                                </Space>
                              </Col>
                              <Col span={24}>
                                <Divider style={{ margin: 0 }} />
                              </Col>
                            </React.Fragment>
                          )
                        : 'N/A'
                      }
                      <Col span={24}>
                        <CButton type="primary-icon" action={() => this.showModal('address')}  icon="PlusOutlined" ghost block title="Add Address"/>
                      </Col>
                    </>
                  :
                    <>
                      <Col span={21}>
                        <Descriptions title="Address" column={2} layout="vertical" size="small">
                          <Descriptions.Item label={<Text type="secondary">Country</Text>}>
                            {data.countryName ? data.countryName : 'N/A'}
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">Province</Text>}>
                            {data.provinceName ? data.provinceName : 'N/A'}
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">City</Text>}>
                            {data.cityName ? data.cityName : 'N/A'}
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">Sub-district</Text>}>
                            {data.subDistrictName ? data.subDistrictName : 'N/A'}
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">Village</Text>}>
                            {data.villageName ? data.villageName : 'N/A'}
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">Postal Code</Text>}>
                            {data.zipCode ? data.zipCode : 'N/A'}
                          </Descriptions.Item>
                          <Descriptions.Item label={<Text type="secondary">Street Address</Text>}>
                            {data.address ? data.address : 'N/A'}
                          </Descriptions.Item>
                        </Descriptions>
                      </Col>
                      <Col span={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CButton className="btn-edit-candidate" type="primary-icon" icon="EditOutlined" action={data.enabled ? () => this.showModal('address') : this.showConfirmActive} title="Edit"/>
                      </Col>
                    </>
                }
              </Row>
            </Card>
          </Col>
        </Row>
        <Modal
          key="1"
          centered
          width={1000}
          destroyOnClose
          visible={visibleContact}
          onCancel={() => this.onCancel('contact')}
          title={<Text strong>Contact Information</Text>}
          footer={[
            <React.Fragment key="1">
              <CButton className="btn-save-primary" type="default" key="back" action={() => this.onCancel('contact')} title="Cancel"/>
              <CButton className="btn-save-primary" key="submit" type="primary" action={this.onSubmitContact} title="Save"/>
            </React.Fragment>
          ]}
        >
          <EditContact 
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            checkEmail={this.checkEmail}
            checkPhone={this.checkPhone}
            onSubmitContact={this.onSubmitContact}
            handleChangeCallingCode={this.handleChangeCallingCode}
          />
        </Modal>
        <Modal
          key="2"
          width={1000}
          destroyOnClose
          footer={false}
          visible={visibleSocial}
          onCancel={() => this.onCancel('social')}
          title={<Text strong>Social Media</Text>}
        >
          <EditSocial 
            {...this.props}
            {...this.state}
            formRef={this.formRef} 
            onChangeSelect={this.onChangeSelect}
            onChangeInput={this.onChangeInput}
            handleDelete={this.handleDelete}
            onSubmitSocial={this.onSubmitSocial}
          />
        </Modal>
        <Modal
          key="3"
          centered
          width={1000}
          destroyOnClose
          visible={visibleAddress}
          onCancel={() => this.onCancel('address')}
          title={<Text strong>Address</Text>}
          footer={[
            <React.Fragment key="1">
              <CButton className="btn-save-primary" type="default" key="back" action={() => this.onCancel('address')} title="Cancel"/>
              <CButton className="btn-save-primary" key="submit" type="primary" action={this.onSubmitAddress} title="Save"/>
            </React.Fragment>
          ]}
        >
          <EditAddress
            {...this.props} 
            {...this.state}
            formRef={this.formRef} 
            handleType={this.handleType}
            handleChangeCountry={this.handleChangeCountry}
            handleChangeProvince={this.handleChangeProvince}
            handleChangeCity={this.handleChangeCity}
            handleChangeSubDistrict={this.handleChangeSubDistrict}
            onSubmitAddress={this.onSubmitAddress}
          />
        </Modal>
      </React.Fragment>
    )
  }
  componentWillUnmount() {
    const { unmountGetDetail, unmountGetCountry } = this.props;
    unmountGetDetail()
    unmountGetCountry()
  }
}

const mapStateToProps = (state) => ({
  getDetailCandidate  : state.candidate.detail,
  getCountry          : state.countries.master,
})

const mapDispatchToProps = {
  actionUpdateContact     : updateContact,
  actionAddSocial         : addSocial,
  actionUpdateAddress     : updateAddress,
  actionCreateAddressStudent: createAddressStudent,
  actionUpdateAddressStudent: updateAddressStudent,
  actionDeleteAddressStudent: deleteAddressStudent,
  actionDeleteSocial      : deleteSocial,
  actionCheckEmail        : checkEmail,
  actionCheckPhone        : checkPhone,
  actionGetCountry        : masterCountries,
  actionGetProvince       : provinceByCountry,
  actionGetCity           : cityByProvince,
  actionGetSubDistrict    : subDistrictByCity,
  actionGetVillage        : villageBySubDistrict,
  actionGetDetail         : detailCandidate,
  actionEnable            : enableCandidate,
  unmountGetDetail        : unmountDetailCandidate,
  unmountGetCountry       : unmountMasterCountries
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateDetailContactInformation)
