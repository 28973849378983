import React from 'react'
import moment from 'moment'
import { Col, Typography, Space, Divider } from 'antd'

const { Title, Text } = Typography

export default function WorkExperience(props) {
  const { data } = props
  return (
    <React.Fragment>
      <Col span={24}>
        <Space direction='vertical' size={16} style={{ width: '100%', textAlign: 'justify', textJustify: 'inter-word' }}>
          <Space direction='vertical' size={0} style={{ width: '100%' }}>
            <Title level={4} style={{ margin: 0 }}>WORK EXPERIENCE</Title>
            <Divider style={{ margin: 0, borderTop: '1px solid black' }} />
          </Space>
        </Space>
      </Col>
      {
        data.listWorkExperience.length > 0 ?
          data.listWorkExperience.map((item, i) => (
            <Col key={i} span={24}>
              <Space direction='vertical' size={0} style={{ width: '100%', textAlign: 'justify', textJustify: 'inter-word' }}>
                <Text>{moment(item.startDate, 'YYYY-MM').format('MMMM YYYY')} - {item.endDate ? moment(item.endDate, 'YYYY-MM').format('MMMM YYYY') : 'Present'}</Text>
                <Space size={4}>
                  <Text strong>{item.title}</Text>
                  {
                    item.employmentType ?
                      <Text italic>
                        {item.employmentType === "FULL_TIME" && '(Full Time)'}
                        {item.employmentType === "PART_TIME" && '(Part Time)'}
                        {item.employmentType === "FREELANCE" && '(Freelance)'}
                        {item.employmentType === "INTERNSHIP" && '(Internship)'}
                      </Text>
                    :
                      null
                  }
                </Space>
                <Text>{item.company ? item.company : null } {item.cityName ? item.cityName : null }</Text>
                <Text>{item.jobDescription ? item.jobDescription : null }</Text>
                </Space>
              </Col>
          ))
        :
          <Col span={24}>
            <Text>N/A</Text>
          </Col>
      }
    </React.Fragment>
  )
}
