import React, { Component } from 'react';
import { Form, Row, Col, Skeleton, Select, Input, Space, message } from 'antd'
import CButton from '../../../../components/Button';

export default class EditAbout extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props)
  
    this.state = {
      submitLoading: false,
      callingCode: "62",
    }
  }

  componentDidMount() {
    const { actionGetCountry, actionGetContact } = this.props;
    actionGetContact('agency', (response) => {
      this.setState({ callingCode: Number(response.data.callingCode) })
    })
    actionGetCountry()
  }

  checkPhone = (value) => {
    const { actionCheckPhone, getContact: { data } } = this.props;
    const valuePhone = `${this.state.callingCode}${value.replace(/^0+/, '')}`
    const oldMobilePhone = `${data.callingCode}${data.mobilePhone}`
    this.formRef.current.setFieldsValue({
      mobilePhone: value.replace(/^0+/, ''),
    });
    if(oldMobilePhone === valuePhone){
      return null
    }else{
      return actionCheckPhone(valuePhone.replace(/^0+/, ''), (response) => {
        if(response){
          message.error('Mobile phone already exist')
          this.formRef.current.setFieldsValue({
            mobilePhone: data.mobilePhone,
          });
        }
      })
    }
  }
  
  onFinish = (values) => {
    const { actionUpdateContact, handleCancel } = this.props;
    values.mobilePhone = `${this.state.callingCode}${values.mobilePhone.replace(/^0+/, '')}`
    return actionUpdateContact('agency', values, (response) => {
      if(response.code === "1000"){
        message.success('Data updated successfully')
        return handleCancel()
      }else{
        message.error(response.message)
      }
    })
  }

  onCancel = () => {
    const { handleCancel } = this.props;
    return handleCancel()
  }

  handleChangeCallingCode = (_, index) => {
    this.setState({ callingCode: index.children.replace(/^[+]+/, '') })
    this.formRef.current.setFieldsValue({
      mobilePhone: null
    });
  }
  
  render() {
    const { submitLoading } = this.state;
    const { getCountry, getContact: { loading, data } } = this.props
    if(loading || getCountry.loading){
      return <Skeleton />
    }
    const mobileNumber = (
      <Form.Item 
        noStyle
        name="callingCodeId" 
        initialValue={data.callingCodeId ? data.callingCodeId : 99} 
        rules={[ {required: true, message: 'Please input your calling code'} ]}
      >
        <Select 
          showSearch
          className="prefix-selector"
          optionFilterProp="children"
          onChange={this.handleChangeCallingCode}
        >
          {
            getCountry?.data.map(item => (
              <Select.Option key={item.id} value={item.id}>
                {`+${item.callingCode}`}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>
    );
    return (
      <React.Fragment>
        <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish}>
          <Row gutter={16}>

            <Col span={12}>
              <Form.Item 
                label="Company Name" 
                name="name"
                validateFirst
                initialValue={data.name ? data.name : null}
                rules={[
                  { required: true, message: 'This is a required field' },
                  { min: 3, message: 'Name must be at least 3 characters long' },
                  { max: 50, message: 'Company Name is too long. Maximum is 50 characters' },
                  { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed' },
                ]}
                >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                label="Contact Person" 
                name="picName"
                validateFirst
                initialValue={data.picName ? data.picName : null}
                rules={[
                  { required: true, message: 'This is a required field' },
                  { min: 3, message: 'Name must be at least 3 characters long' },               
                  { max: 50, message: 'Contact Person is too long. Maximum is 50 characters' },
                  { pattern: new RegExp("^[a-zA-Z ]+$"), message: 'Special Characters and Number are not allowed!' },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                label="Contact Person Mobile Number" 
                name="mobilePhone"
                validateFirst
                initialValue={data.mobilePhone ? data.mobilePhone : null}
                rules={[
                  { required: true, message: 'This is a required field' },
                  { min: 4, message: 'Your mobile number is invalid' },
                  { max: 13, message: 'Your mobile number is invalid' },
                  { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },                
                ]}
              >
                <Input addonBefore={mobileNumber} onBlur={(e) => this.checkPhone(e.target.value)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                label="Contact Person Email" 
                name="picEmail" 
                initialValue={data.picEmail ? data.picEmail : null}
                rules={[
                  { type: 'email', message: 'Your email address is invalid' }
                ]}
              >
                <Input type="email" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item 
                label="Office Phone Number" 
                name="officePhone" 
                validateFirst
                initialValue={data.officePhone ? data.officePhone : null}
                rules={[
                  { min: 4, message: 'Your mobile number is invalid' },
                  { max: 13, message: 'Your mobile number is invalid' },
                  { pattern: /^[0-9]*$/, message: 'This entry can only contain numbers' },  
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Space>
                <CButton className="btn-cancel-danger" type="default" action={this.onCancel} title="Cancel"/>
                <CButton className="btn-save-primary" type="primary" htmlType="submit" loading={submitLoading} title="Update"/>
              </Space>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    )
  }
}
