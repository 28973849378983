import React, { Component } from 'react';
import debounce from 'lodash/debounce';
import { columns } from './columns';
import { connect } from 'react-redux';
import CreateCandidate from './Form';
import { SolutionOutlined, SearchOutlined, SoundOutlined, FilterOutlined } from '@ant-design/icons';
import { PageHeader, Row, Col, Table, Breadcrumb, Pagination, Button, Modal, Form , Space, Timeline, Empty, Select, AutoComplete, Input, Popover, Checkbox, Tooltip, DatePicker, Typography, Tag, Divider, Radio, message } from 'antd';
import { listNewCandidate, unmountListNewCandidate, createNotes, updateStatusNewCandidate, addCounselingProcess, exportData, updateStatusByFilter, addCounselingProcessByFilter, exportDataByFilter } from '../../../redux/actions/newCandidate/newCandidateAction';
import { masterEducationLevel, unmountMasterEducationLevel } from '../../../redux/actions/master/educationLevel/educationLevelAction';
import moment from 'moment';
import { download } from '../../../utils/document';
import CButton from '../../../components/Button';
const { RangePicker } = DatePicker;
const { Text } = Typography;
const { confirm, warning } = Modal;
const { TextArea } = Input;

class NewCandidateList extends Component {
  formRef = React.createRef()
  formBulkRef = React.createRef()
  formBulkRef2 = React.createRef()
  formBulkRef3 = React.createRef()
  constructor(props) {
    super(props)
  
    this.state = {
      visible: false,
      visibleNotes : false,
      visibleAction: false,
      visibleAction2: false,
      visibleAction3: false,
      selectedRows: [],
      selectedRowKeys: [],
      submitLoading: false,
      meta: {
        page: 1,
        perpage: 20
      },
      candidateNotes: [],
      candidateId: null,
      valuesBulk: null,
      educationLevel: null,
      optionExport: false
    }
    this.onSearch = debounce(this.onSearch.bind(this), 900)
  }

  componentDidMount() {
    const { meta } = this.state
    const { actionGetData, actionGetEducationLevel } = this.props
    actionGetData(meta)
    actionGetEducationLevel()
  }

  onRegistered = value => {
    const { actionGetData } = this.props
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.startDate = value.startDate
    meta.endDate = value.endDate
    return actionGetData(meta)
  }

  handleRangePicker = (dates, dateStrings) => {
    const date = {
      'startDate': dateStrings[0],
      'endDate': dateStrings[1]
    }
    return this.onRegistered(date)
  }

  handleFilterClear = () => {
    const { actionGetData } = this.props
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    delete meta.educationLevelId
    delete meta.gender
    delete meta.age
    delete meta.candidateSource
    delete meta.experience
    delete meta.candidateCrmStatus
    this.formRef.current.setFieldsValue({
      educationLevelId: null,
      gender: null,
      age: null,
      candidateSource: null,
      experience: null,
      candidateCrmStatus: null
    })
    this.setState({ meta })
    return actionGetData(meta)
  }

  handleFilterEducation = value => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.educationLevelId = value
  }

  handleFilterGender = value => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.gender = value.toString()
  }

  handleFilterAge = value => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.age = value
  }

  handleFilterSource = value => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.candidateSource = value.toString()
  }

  handleFilterExperience = value => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.experience = value
  }

  handleFilterStatus = value => {
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    meta.candidateCrmStatus = value.toString()
  }

  handleAddFilter = () => {
    const { actionGetData } = this.props
    const { meta } = this.state
    this.setState({ visible: false })
    return actionGetData(meta)
  }

  handleOpenChange = (newOpen) => {
    this.setState ({visible: newOpen});
  };

  handleCloseTag = (value) => {
    const { actionGetData } = this.props
    const { meta } = this.state
    meta.page = 1
    meta.perpage = 20
    delete meta[value]
    this.formRef.current.setFieldsValue({
      [value]: null
    })
    this.setState({ meta })
    return actionGetData(meta)
  }

  onSearch = value => {
    const { meta } = this.state
    const { actionGetData } = this.props
    meta.page = 1
    meta.perpage = 20
    meta.search = value === '' ? null : value
    return actionGetData(meta)
  }

  pagination = (page, perpage) => {
    const { actionGetData } = this.props
    const { meta } = this.state
    meta.page = page
    meta.perpage = perpage
    return actionGetData(meta)
  }

  onClickRow = (record) => {
    this.props.history.push(`/new-candidate/detail/${record.id}/biodata`)
    localStorage.setItem("status", "new-candidate")
  }

  onChangeCheck = (selectedRowKeys_) => {
    const { selectedRowKeys } = this.state
    const data = selectedRowKeys.concat(selectedRowKeys_)
    const uniqueData = data.filter((val,id,array) => array.indexOf(val) === id)
    this.setState({ selectedRowKeys: uniqueData })
  }

  onSelectCheck = (record, selected) => {
    const { selectedRows, selectedRowKeys } = this.state
    if(selected){
      selectedRows.push({ id: record.id, status: record.candidateCrmStatus })
    }else{
      const objWithIdIndex = selectedRows.findIndex((obj) => obj.id === record.id)
      if(objWithIdIndex > -1) {
        selectedRows.splice(objWithIdIndex, 1)
        selectedRowKeys.splice(objWithIdIndex, 1)
      }
    }
    this.setState({ selectedRows, selectedRowKeys })
  }

  onSelectCheckAll = (selected, selectedRows_, changeRows) => {
    const { selectedRows, selectedRowKeys } = this.state
    if(selected){
      changeRows.forEach((item) => {
        selectedRows.push({ id: item.id, status: item.candidateCrmStatus })
      })
    }else{
      changeRows.forEach((item) => {
        const objWithIdIndex = selectedRows.findIndex((obj) => obj.id === item.id)
        if(objWithIdIndex > -1) {
          selectedRows.splice(objWithIdIndex, 1)
          selectedRowKeys.splice(objWithIdIndex, 1)
        }
      })
    }
    this.setState({ selectedRows, selectedRowKeys })
  }
  
  showModalNotes = (val, candidateId) => {
    this.setState({ visibleNotes: true, candidateNotes: val, candidateId: candidateId })
  };

  showModalAction = () => {
    this.setState({ visibleAction: true })
  };

  handleCancel = () => {
    this.setState({ visibleNotes: false, visibleAction: false, visibleAction2: false, visibleAction3: false, valuesBulk: null })
  }

  onFinishNotes = (value) => {
    const { actionCreateNotes, actionGetData } = this.props
    const { candidateId, meta} = this.state
    value.candidateId = candidateId
    return this.setState({ submitLoading: true }, () => {
      return actionCreateNotes(value, response => {
        if(response.code === '1000'){
          setTimeout(() => {
            this.setState({ submitLoading: false, visibleNotes: false })
            message.success('successfully added notes!')
          }, actionGetData(meta, response => {
          }), 1000)
        }else{
          return this.setState({ submitLoading: false, visibleNotes: false }, () => message.error(response.message))
        }
      }, (err) => {
        return this.setState({ submitLoading: false, visibleNotes: false }, () => message.error(err))
      })
    })
  }

  modalSetNotContacted = (id) => {
    const self = this;
    const { meta, selectedRowKeys } = this.state;
    const { actionUpdateStatus, actionGetData } = this.props;
    confirm({
      title: 
        id ? 
          <Text>The candidate will be changed to ‘<Text strong style={{ color: '#32AB6D' }}>Not Contacted</Text>’</Text> 
        : 
          <Text>The status will be changed to ‘<Text strong style={{ color: '#32AB6D' }}>Not Contacted</Text>’ for the data you selected.</Text>,
      content: 'Are you sure?',
      width: id ? 500 : 480,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        const value = {
          candidateCrmStatus: 'NOT_CONTACTED',
          candidateId: id ? [id] : selectedRowKeys
        }
        return actionUpdateStatus(value, () => {
          message.success('Successfully update status to Not Contacted')
          actionGetData(meta)
          self.setState({ selectedRowKeys: [], selectedRows: [] })
        }, (err) => message.error(err))
      },
    });
  }

  modalSetContacted = (id) => {
    const self = this;
    const { meta, selectedRowKeys } = this.state;
    const { actionUpdateStatus, actionGetData } = this.props;
    confirm({
      title: 
        id ? 
          <Text>The candidate will be changed to ‘<Text strong style={{ color: '#4273B9' }}>Contacted</Text>’</Text> 
        : 
          <Text>The status will be changed to ‘<Text strong style={{ color: '#4273B9' }}>Contacted</Text>’ for the data you selected.</Text>,
      content: 'Are you sure?',
      width: id ? 500 : 480,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        const value = {
          candidateCrmStatus: 'CONTACTED',
          candidateId: id ? [id] : selectedRowKeys
        }
        return actionUpdateStatus(value, () => {
          message.success('Successfully update status to Contacted')
          actionGetData(meta)
          self.setState({ selectedRowKeys: [], selectedRows: [] })
        }, (err) => message.error(err))
      },
    });
  }

  modalSetRejected = (id) => {
    const self = this;
    const { meta, selectedRowKeys } = this.state;
    const { actionUpdateStatus, actionGetData } = this.props;
    confirm({
      title: 
        id ? 
          <Text>The candidate will be changed to ‘<Text strong style={{ color: '#F58181' }}>Rejected</Text>’</Text> 
        : 
          <Text>The status will be changed to ‘<Text strong style={{ color: '#F58181' }}>Rejected</Text>’ for the data you selected.</Text>,
      content: 'Are you sure?',
      width: id ? 500 : 480,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        const value = {
          candidateCrmStatus: 'REJECTED',
          candidateId: id ? [id] : selectedRowKeys
        }
        return actionUpdateStatus(value, () => {
          message.success('Successfully update status to Rejected')
          actionGetData(meta)
          self.setState({ selectedRowKeys: [], selectedRows: [] })
        }, (err) => message.error(err))
      },
    });
  }

  modalMoveToCounseling = (id) => {
    const self = this;
    const { meta, selectedRowKeys } = this.state;
    const { actionAddCounseling, actionGetData } = this.props;
    confirm({
      title: <Text>Candidate will be moved to ‘<Text strong style={{ color: '#4273B9' }}>Counseling</Text>’ stage for the data you selected.</Text>,
      content: 'Are you sure?',
      width: 480,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        const value = {
          counselingDate: moment().format('DD/MM/YYYY'),
          candidateId: id ? [id] : selectedRowKeys
        }
        return actionAddCounseling(value, () => {
          message.success('Successfully move to Counseling Process')
          actionGetData(meta)
          self.setState({ selectedRowKeys: [], selectedRows: [] })
        }, (err) => message.error(err))
      },
    });
  }

  modalExportData = (id) => {
    const self = this;
    const { selectedRowKeys } = this.state;
    const { actionExportData } = this.props;
    confirm({
      title: <Text>Candidates data will be exported to excel</Text>,
      content: 'Are you sure?',
      width: 480,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        const value = {
          candidateId: id ? id : selectedRowKeys.toString()
        }
        actionExportData(value, (response) => {
          return download(response, 'New Candidate Data.xls')
        })
        self.setState({ selectedRowKeys: [], selectedRows: [] })
      },
    });
  }

  warningNoSelected = () => {
    warning({
      title: 'Please select a record',
    });
  };

  warningMoveToNotContacted = () => {
    warning({
      title: <Text>Please select record without status ‘<Text strong style={{ color: '#32AB6D' }}>Not Contacted</Text>’</Text>
    })
  };

  warningMoveToContacted = () => {
    warning({
      title: <Text>Please select record without status ‘<Text strong style={{ color: '#4273B9' }}>Contacted</Text>’</Text>
    })
  };
  
  warningMoveToRejected = () => {
    warning({
      title: <Text>Please select record without status ‘<Text strong style={{ color: '#F58181' }}>Rejected</Text>’</Text>
    })
  };

  warningMoveCounseling = () => {
    warning({
      title: <Text>Only ‘<Text strong style={{ color: '#4273B9' }}>Contacted</Text>’ status can be moved to ‘<Text strong style={{ color: '#4273B9' }}>Counseling</Text>’</Text>,
    });
  };

  onFinishBulk = (values) => {
    this.setState({ visibleAction: false, visibleAction2: true, valuesBulk: values })
  }

  handleBulkEducation = (_,res) => {
    this.setState({ educationLevel: res.children })
  }

  onFinishBulk2 = (values) => {
    const { actionUpdateStatusByFilter, actionAddCounselingByFilter, actionGetData } = this.props
    const { meta, valuesBulk } = this.state
    if(valuesBulk.gender === 'ALL'){
      delete valuesBulk.gender
    }
    if(values.newStatus === 'EXPORT'){
      this.setState({ visibleAction2: false, visibleAction3: true })
    }else if(values.newStatus === 'COUNSELING'){
      return this.setState({ submitLoading: true }, () => {
        return actionAddCounselingByFilter(valuesBulk, (response) => {
          if(response.code === '1000'){
            message.success(response.data)
            actionGetData(meta)
            this.setState({ submitLoading: false, visibleAction2: false, valuesBulk: null, educationLevel: null })
          }else{
            message.error(response.message)
            this.setState({ submitLoading: false, visibleAction2: false, valuesBulk: null, educationLevel: null })
          }
        }, (err) => this.setState({ submitLoading: false }, () => { 
          message.error(err)
        }))
      })
    }else{
      const mergedValues = {...valuesBulk, ...values}
      return this.setState({ submitLoading: true }, () => {
        return actionUpdateStatusByFilter(mergedValues, (response) => {
          if(response.code === '1000'){
            message.success(response.data)
            actionGetData(meta)
            this.setState({ submitLoading: false, visibleAction2: false, valuesBulk: null, educationLevel: null })
          }else{
            message.error(response.message)
            this.setState({ submitLoading: false, visibleAction2: false, valuesBulk: null, educationLevel: null })
          }
        }, (err) => this.setState({ submitLoading: false }, () => { 
          message.error(err)
        }))
      })
    }
  }

  handleOptionExport = (e) => {
    this.setState({ optionExport: e.target.value })
  }

  onFinishBulk3 = (values) => {
    const { actionUpdateStatusByFilter, actionExportDataByFilter, actionGetData } = this.props
    const { meta, valuesBulk } = this.state
    if(valuesBulk.gender === 'ALL'){
      delete valuesBulk.gender
    }
    if(values.optionStatus === true){
      delete values.optionStatus
      const mergedValues = {...valuesBulk, ...values}
      return this.setState({ submitLoading: true }, () => {
        actionExportDataByFilter(valuesBulk, (response) => {
          return download(response, 'New Candidate Data by Filter.xls')
        })
        return actionUpdateStatusByFilter(mergedValues, (response) => {
          if(response.code === '1000'){
            message.success(response.data)
            actionGetData(meta)
            this.setState({ submitLoading: false, visibleAction3: false, valuesBulk: null, educationLevel: null, optionExport: false })
          }else{
            message.error(response.message)
            this.setState({ submitLoading: false, visibleAction2: false, valuesBulk: null, educationLevel: null })
          }
        }, (err) => this.setState({ submitLoading: false }, () => { 
          message.error(err)
        }))
      })
    }else{
      return this.setState({ visibleAction3: false, valuesBulk: null, educationLevel: null, optionExport: false }, () => {
        return actionExportDataByFilter(valuesBulk, (response) => {
          return download(response, 'New Candidate Data by Filter.xls')
        })
      })
    }
  }

  render() {
    const { visibleNotes, visibleAction, visibleAction2, visibleAction3, selectedRowKeys, selectedRows, candidateNotes, valuesBulk, educationLevel, optionExport, meta, submitLoading, visible } = this.state
    const { getData: { data, loading , pagination }, getEducationLevel, tooltipDate } = this.props
    
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onChangeCheck,
      onSelect: this.onSelectCheck,
      onSelectAll: this.onSelectCheckAll
    }

    return (
      <React.Fragment>
        <Row gutter={[8, 0]}>
          {/* Breadcrumb */}
          <Col span={24}>
            <Breadcrumb>
              <Breadcrumb.Item><SolutionOutlined /> New Candidates</Breadcrumb.Item>
              <Breadcrumb.Item>New Candidates List</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          {/* Page Header */}
          <Col span={24}>
            <PageHeader className="site-page-header" title='New Candidate List' />
          </Col>
          {/* Filter */}
          <Col span={5}>
            <Tooltip title={tooltipDate}>
              <RangePicker
                onChange={this.handleRangePicker}
                format={'DD-MM-YYYY'}
                style={{ width: '100%' }}
                ranges={{
                  Today: [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                  'This Week': [moment().startOf('week'), moment().endOf('week')],
                  'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                }}
              />
            </Tooltip>
          </Col>
          <Col span={3}>
            <Popover
              placement="rightTop" 
              trigger="click"
              open={visible}
              onOpenChange={this.handleOpenChange}
              content={
                <Form ref={this.formRef}>
                  <Row gutter={[0,8]} style={{ width: 300 }}>
                    <Col span={24}>
                      <Text className="link row-pointer" onClick={this.handleFilterClear} style={{ float: 'right' }}>Clear All</Text>
                    </Col>
                    <Col span={24}>
                      <Text strong>Education</Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="educationLevelId" style={{ margin: 0 }}>
                        <Select onChange={this.handleFilterEducation} placeholder="Select Level" style={{ width: '100%', float: 'right' }}>
                          {
                            getEducationLevel?.data?.map(item => 
                              <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                              )
                          }
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text strong>Gender</Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="gender" style={{ margin: 0 }}>
                        <Checkbox.Group onChange={(checkedValues) => this.handleFilterGender(checkedValues)} style={{ display: 'grid' }}>
                          <Checkbox key="1" value="MALE" style={{ marginLeft: 0 }}>Man</Checkbox>
                          <Checkbox key="2" value="FEMALE" style={{ marginLeft: 0 }}>Woman</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text strong>Age</Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="age" style={{ margin: 0 }}>
                        <Select onChange={this.handleFilterAge} placeholder="Select Age" style={{ width: '100%', float: 'right' }}>
                          <Select.Option key="1" value="<17">{`<17`}</Select.Option>
                          <Select.Option key="2" value="17-21">17-21</Select.Option>
                          <Select.Option key="3" value="22-26">22-26</Select.Option>
                          <Select.Option key="4" value="27-31">27-31</Select.Option>
                          <Select.Option key="5" value="32-36">32-36</Select.Option>
                          <Select.Option key="6" value="37-41">37-41</Select.Option>
                          <Select.Option key="7" value="42-46">42-46</Select.Option>
                          <Select.Option key="8" value=">46">{`>46`}</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text strong>Register Owner</Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="candidateSource" style={{ margin: 0 }}>
                        <Checkbox.Group onChange={(checkedValues) => this.handleFilterSource(checkedValues)} style={{ display: 'grid' }}>
                          <Checkbox key="1" value="SELF_REGISTER" style={{ marginLeft: 0 }}>Self Register</Checkbox>
                          <Checkbox key="2" value="INJECTED" style={{ marginLeft: 0 }}>Injected</Checkbox>
                          <Checkbox key="3" value="REFERRAL" style={{ marginLeft: 0 }}>Referral</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text strong>Experiences</Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="experience" style={{ margin: 0 }}>
                        <Select onChange={this.handleFilterExperience} placeholder="Select Level" style={{ width: '100%', float: 'right' }}>
                          <Select.Option key="1" value="<1">{`<1 years`}</Select.Option>
                          <Select.Option key="2" value="1-2">1-2 years</Select.Option>
                          <Select.Option key="3" value="3-5">3-5 years</Select.Option>
                          <Select.Option key="4" value=">5">{`>5 years`}</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Text strong>Candidates Status</Text>
                    </Col>
                    <Col span={24}>
                      <Form.Item name="candidateCrmStatus" style={{ margin: 0 }}>
                        <Checkbox.Group onChange={(checkedValues) => this.handleFilterStatus(checkedValues)} style={{ display: 'grid' }}>
                          <Checkbox key="1" value="NOT_CONTACTED" style={{ marginLeft: 0 }}>Not Contacted</Checkbox>
                          <Checkbox key="2" value="CONTACTED" style={{ marginLeft: 0 }}>Contacted</Checkbox>
                          <Checkbox key="3" value="REJECTED" style={{ marginLeft: 0 }}>Rejected</Checkbox>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{ marginTop: 8 }}>
                      <CButton type="primary" action={this.handleAddFilter} style={{ width: '100%' }} title="Add Filter"/>
                    </Col>
                  </Row>
                </Form>
              }
            >
              <Button style={{ width: '100%', borderRadius: 6 }}>
                Filter <FilterOutlined />
              </Button>
            </Popover>
          </Col>
          <Col span={7}>
            <AutoComplete 
              onSearch={this.onSearch} 
              style={{ width: '100%' }} 
              allowClear
            >
              <Input prefix={<SearchOutlined />} placeholder="Search by Name, Email"  />
            </AutoComplete>
          </Col>
          <Col span={1} style={{ textAlign: 'center' }}>
            <Divider size="large" type="vertical" style={{ height: '100%' }} />
          </Col>
          <Col span={3}>
            <Button type="primary" onClick={this.showModalAction} style={{ width: '100%', borderRadius: 6 }}>Action</Button>
          </Col>
          <Col span={5}>
            <CreateCandidate {...this.props} />
          </Col>

          {/* Button */}
          {
            selectedRowKeys.length > 0 ? 
              <React.Fragment>
                <Col span={3} style={{ marginTop: 8 }}>
                  <Text>Total Data: <Text strong>{selectedRowKeys?.length}</Text></Text>
                </Col>
                <Col span={21} style={{ marginTop: 8 }}>
                  <Space>
                    <Button 
                      className="btn-color-green" 
                      size='small'
                      onClick={
                        selectedRowKeys.length > 0 ? 
                          selectedRows.find(res => res.status === 'NOT_CONTACTED') ?
                            this.warningMoveToNotContacted
                          :
                            () => this.modalSetNotContacted(null) 
                        :
                          this.warningNoSelected}
                    >
                      Set to “Not Contacted”
                    </Button>
                    <Button 
                      className="btn-color-blue" 
                      size='small'
                      onClick={
                        selectedRowKeys.length > 0 ?
                          selectedRows.find(res => res.status === 'CONTACTED') ?
                            this.warningMoveToContacted
                          :
                            () => this.modalSetContacted(null) 
                        :
                          this.warningNoSelected}
                    >
                      Set to “Contacted”
                    </Button>
                    <Button 
                      className="btn-color-red" 
                      size='small'
                      onClick={
                        selectedRowKeys.length > 0 ?
                          selectedRows.find(res => res.status === 'REJECTED') ?
                            this.warningMoveToRejected
                          :
                            () => this.modalSetRejected(null)
                        :
                          this.warningNoSelected}
                    >
                      Set to “Rejected”
                    </Button>

                    <Button 
                      className="btn-create-primary" 
                      size='small'
                      type="primary" 
                      ghost
                      onClick={
                        selectedRowKeys.length > 0 ? 
                          selectedRows.find(res => res.status === 'NOT_CONTACTED') || selectedRows.find(res => res.status === 'REJECTED') ?
                            this.warningMoveCounseling
                          :
                            () => this.modalMoveToCounseling(null) 
                        : 
                          this.warningNoSelected}
                    >
                      Move to Counseling Process <SoundOutlined />
                    </Button>
                    <CButton 
                      className="btn-create-primary" 
                      type="primary" 
                      size='small'
                      ghost
                      action={selectedRowKeys.length > 0 ? () => this.modalExportData(null) : this.warningNoSelected}
                      icon="FileExcelOutlined"
                      title="Export Data"
                    />
                  </Space>
                </Col>
              </React.Fragment>
            : null
          }
          
          {/* Value Filter */}
          {
            meta.hasOwnProperty('educationLevelId') || meta.hasOwnProperty('gender') || meta.hasOwnProperty('age') || meta.hasOwnProperty('candidateSource') || meta.hasOwnProperty('experience') || meta.hasOwnProperty('candidateCrmStatus') ?
              <Col span={24} style={{ paddingTop: 8 }}>
                <Space>
                  <Text>Filter Active:</Text>
                  <Space size={0}>
                    {
                      meta.hasOwnProperty('educationLevelId') ? 
                        <Tag
                          color="green"
                          closable
                          onClose={() => this.handleCloseTag('educationLevelId')}
                          style={{ padding: '4px 16px', borderRadius: 32 }}
                        >
                          Education
                        </Tag>
                      : null
                    }
                    {
                      meta.hasOwnProperty('gender') ? 
                        <Tag
                          color="green"
                          closable
                          onClose={() => this.handleCloseTag('gender')}
                          style={{ padding: '4px 16px', borderRadius: 32 }}
                        >
                          Gender
                        </Tag>
                      : null
                    }
                    {
                      meta.hasOwnProperty('age') ? 
                        <Tag
                          color="green"
                          closable
                          onClose={() => this.handleCloseTag('age')}
                          style={{ padding: '4px 16px', borderRadius: 32 }}
                        >
                          Age
                        </Tag>
                      : null
                    }
                    {
                      meta.hasOwnProperty('candidateSource') ? 
                        <Tag
                          color="green"
                          closable
                          onClose={() => this.handleCloseTag('candidateSource')}
                          style={{ padding: '4px 16px', borderRadius: 32 }}
                        >
                          Candidates Created
                        </Tag>
                      : null
                    }
                    {
                      meta.hasOwnProperty('experience') ? 
                        <Tag
                          color="green"
                          closable
                          onClose={() => this.handleCloseTag('experience')}
                          style={{ padding: '4px 16px', borderRadius: 32 }}
                        >
                          Experiences
                        </Tag>
                      : null
                    }
                    {
                      meta.hasOwnProperty('candidateCrmStatus') ? 
                        <Tag
                          color="green"
                          closable
                          onClose={() => this.handleCloseTag('candidateCrmStatus')}
                          style={{ padding: '4px 16px', borderRadius: 32 }}
                        >
                          Candidate Status
                        </Tag>
                      : null
                    }
                  </Space>
                  <Button type="text" onClick={this.handleFilterClear}>Clear All</Button>
                </Space>
              </Col>
            : null
          }

          {/* Table */}
          <Col span={24} style={{ marginTop: 8 }}>
            <Table 
              size="small" 
              rowKey={(i) => i.id} 
              pagination={false}
              loading={loading} 
              scroll={{ x: 1500 }}
              rowSelection={rowSelection}
              dataSource={data} columns={columns(this.onClickRow, this.showModalNotes, this.modalSetNotContacted, this.modalSetContacted, this.modalSetRejected, this.modalMoveToCounseling, this.modalExportData, selectedRowKeys)}
            />
          </Col>
          {/* Pagination */}
          <Col span={24} style={{ marginTop: 8 }}>
            <Pagination
              total={pagination.total}
              onChange={this.pagination}
              current={pagination.page}
              pageSize={pagination.perpage}
              pageSizeOptions={[20,50,100,200]}
              showTotal={(total, range) => `Showing ${range[0]}-${range[1]} of ${total} Data`}
              size='small'
            />
          </Col>
        </Row>

        <Modal
          title= {`Recruitment Notes`}
          visible={visibleNotes}
          width={600}
          onCancel={this.handleCancel}
          footer= {false}
          destroyOnClose
        >
          <Row>
            <Col span={24}>
              <Form layout='vertical' onFinish={this.onFinishNotes}>
                <Form.Item
                  name="note"
                  rules={[
                    { required: true, message: 'This is a required field' },
                    { max: 200, message: '200 characters only' },
                  ]}
                >
                  <TextArea rows={2} placeholder='write a notes'
                >
                  </TextArea>
                </Form.Item>
                
                <CButton 
                  className="btn-create-primary" 
                  type="primary" 
                  ghost 
                  style={{float : 'right'}}
                  htmlType = "submit"
                  title="Submit"
                />
              </Form>
            </Col>

            <Col span={24} style={{marginTop: 32}}>
              {
                candidateNotes?.length > 0 ? 
                  <Timeline mode='left' style={{ paddingRight: 264 }}>
                    {
                      candidateNotes.map((item,i) => 
                        <Timeline.Item key={i} label={<Text style={{ fontSize: 12 }}>{item.createdDate}</Text>}>
                          <Space direction="vertical" size={0} style={{ width: 388 }}>
                            <Text style={{ fontSize: 12 }}>{item.note}</Text>
                            <Text style={{ color: '#0076de', fontSize: 12 }}>{item.stage}</Text>
                          </Space>
                        </Timeline.Item>
                      )
                    }
                  </Timeline>
                : 
                  <Empty />
              }
            </Col>
          </Row>
        </Modal>

        <Modal
          title={<Text strong>Update Candidate Status in Bulk</Text>}
          visible={visibleAction}
          onCancel={this.handleCancel}
          footer= {false}
          destroyOnClose
        >
          <Form ref={this.formBulkRef} onFinish={this.onFinishBulk}>
            <Row gutter={[0,8]}>
              <Col span={24}>
                <Text strong>Education</Text>
              </Col>
              <Col span={24}>
                <Form.Item name="educationLevelId" style={{ margin: 0 }}>
                  <Select onChange={this.handleBulkEducation} placeholder="Select Level" style={{ width: '100%', float: 'right' }}>
                    {
                      getEducationLevel?.data?.map(item => 
                        <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                        )
                    }
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text strong>Gender</Text>
              </Col>
              <Col span={24}>
                <Form.Item name="gender" style={{ margin: 0 }} initialValue="ALL">
                  <Radio.Group>
                    <Space direction="vertical" size={0}>
                      <Radio key="1" value="ALL" style={{ marginLeft: 0 }}>All</Radio>
                      <Radio key="2" value="MALE" style={{ marginLeft: 0 }}>Man</Radio>
                      <Radio key="3" value="FEMALE" style={{ marginLeft: 0 }}>Woman</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text strong>Age</Text>
              </Col>
              <Col span={24}>
                <Form.Item name="age" style={{ margin: 0 }}>
                  <Select placeholder="Select Age" style={{ width: '100%', float: 'right' }}>
                    <Select.Option key="1" value="<17">{`<17`}</Select.Option>
                    <Select.Option key="2" value="17-21">17-21</Select.Option>
                    <Select.Option key="3" value="22-26">22-26</Select.Option>
                    <Select.Option key="4" value="27-31">27-31</Select.Option>
                    <Select.Option key="5" value="32-36">32-36</Select.Option>
                    <Select.Option key="6" value="37-41">37-41</Select.Option>
                    <Select.Option key="7" value="42-46">42-46</Select.Option>
                    <Select.Option key="8" value=">46">{`>46`}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text strong>Register Owner</Text>
              </Col>
              <Col span={24}>
                <Form.Item name="candidateSource" style={{ margin: 0 }}>
                  <Checkbox.Group style={{ display: 'grid' }}>
                    <Checkbox key="1" value="SELF_REGISTER" style={{ marginLeft: 0 }}>Self Register</Checkbox>
                    <Checkbox key="2" value="INJECTED" style={{ marginLeft: 0 }}>Injected</Checkbox>
                    <Checkbox key="3" value="REFERRAL" style={{ marginLeft: 0 }}>Referral</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text strong>Experiences</Text>
              </Col>
              <Col span={24}>
                <Form.Item name="experience" style={{ margin: 0 }}>
                  <Select placeholder="Select Level" style={{ width: '100%', float: 'right' }}>
                    <Select.Option key="1" value="<1">{`<1 years`}</Select.Option>
                    <Select.Option key="2" value="1-2">1-2 years</Select.Option>
                    <Select.Option key="3" value="3-5">3-5 years</Select.Option>
                    <Select.Option key="4" value=">5">{`>5 years`}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Text strong>Candidates Status</Text>
              </Col>
              <Col span={24}>
                <Form.Item 
                  name="crmStatus" 
                  rules={[
                    { required: true, message: 'This is a required field' }
                  ]}
                  style={{ margin: 0 }}
                >
                  <Radio.Group>
                    <Space direction="vertical" size={0}>
                      <Radio key="1" value="NOT_CONTACTED" style={{ marginLeft: 0 }}>Not Contacted</Radio>
                      <Radio key="2" value="CONTACTED" style={{ marginLeft: 0 }}>Contacted</Radio>
                      <Radio key="3" value="REJECTED" style={{ marginLeft: 0 }}>Rejected</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: 8 }}>
                <Space style={{ float: 'right' }}>
                  <CButton type="default" action={this.handleCancel} title="Cancel"/>
                  <CButton type="primary" htmlType='submit' title="Next"/>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title={<Text strong>Candidates data are selected</Text>}
          visible={visibleAction2}
          onCancel={this.handleCancel}
          footer= {false}
          destroyOnClose
        >
          <Text style={{ color: '#4273B9' }}>
            {
              `Filter by: 
              ${valuesBulk?.educationLevelId ? `Education(${educationLevel}), ` : ''}
              ${valuesBulk?.gender === 'MALE' ? `Gender(Man), ` : valuesBulk?.gender === 'FEMALE' ? `Gender(Women), ` : ''}
              ${valuesBulk?.age ? `Age(${valuesBulk?.age}), ` : ''}
              ${valuesBulk?.candidateSource ? `Register Owner(${valuesBulk?.candidateSource?.toString()}), ` : ''}
              ${valuesBulk?.experience ? `Experiences(${valuesBulk?.experience} years), ` : ''}
              Candidate Status(${valuesBulk?.crmStatus === 'CONTACTED' ? 'Contacted' : valuesBulk?.crmStatus === 'NOT_CONTACTED' ? 'Not Contacted' : 'Rejected'})`
            }
          </Text>
          <Form ref={this.formBulkRef2} onFinish={this.onFinishBulk2} style={{ marginTop: 16 }}>
            <Row>
              <Col span={24}>
                <Form.Item 
                  name="newStatus" 
                  rules={[
                    { required: true, message: 'This is a required field' }
                  ]}
                >
                  <Radio.Group>
                    <Space direction="vertical" size={0}>
                      {
                        valuesBulk?.crmStatus !== 'NOT_CONTACTED' ?
                          <Radio key="1" value="NOT_CONTACTED" style={{ marginLeft: 0 }}>Change status to ‘‘<Text strong style={{ color: '#32AB6D' }}>Not Contacted</Text>’’</Radio>
                        : null
                      }
                      {
                        valuesBulk?.crmStatus !== 'CONTACTED' ?
                          <Radio key="2" value="CONTACTED" style={{ marginLeft: 0 }}>Change status to ‘‘<Text strong style={{ color: '#4273B9' }}>Contacted</Text>’’</Radio>
                        : null

                      }
                      {
                        valuesBulk?.crmStatus !== 'REJECTED' ?
                          <Radio key="3" value="REJECTED" style={{ marginLeft: 0 }}>Change status to ‘‘<Text strong style={{ color: '#F58181' }}>Rejected</Text>’’</Radio>
                        : null
                      }
                      {
                        valuesBulk?.crmStatus === 'CONTACTED' ?
                          <Radio key="4" value="COUNSELING" style={{ marginLeft: 0 }}>Move to ‘‘Counseling’’</Radio>
                        : null
                      }
                      <Radio key="5" value="EXPORT" style={{ marginLeft: 0 }}>Export Data</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={24} style={{ marginTop: 8 }}>
                <Space style={{ float: 'right' }}>
                  <CButton type="default" action={this.handleCancel} title="Cancel"/>
                  <CButton type="primary" htmlType='submit' loading={submitLoading} title="Next"/>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>

        <Modal
          title={<Text strong>Candidates data will be exported to excel</Text>}
          visible={visibleAction3}
          onCancel={this.handleCancel}
          footer= {false}
          destroyOnClose
        >
          <Form ref={this.formBulkRef3} layout='vertical' onFinish={this.onFinishBulk3} style={{ marginTop: 16 }}>
            <Row>
              <Col span={24}>
                <Form.Item 
                  name="optionStatus" 
                  label='Do you wish to update the status'
                  rules={[
                    { required: true, message: 'This is a required field' }
                  ]}
                  initialValue={optionExport}
                >
                  <Radio.Group onChange={(checkedValues) => this.handleOptionExport(checkedValues)}>
                    <Space direction="vertical" size={0}>
                      <Radio key="1" value={false} style={{ marginLeft: 0 }}>No</Radio>
                      <Radio key="2" value={true} style={{ marginLeft: 0 }}>Yes</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {
                optionExport ?
                  <Form.Item 
                    name="newStatus" 
                    label='Please choose the new status for the exported data'
                    rules={[
                      { required: true, message: 'This is a required field' }
                    ]}
                  >
                    <Radio.Group onChange={(checkedValues) => this.handleOptionExport(checkedValues)}>
                      <Space direction="vertical" size={0}>
                        {
                          valuesBulk?.crmStatus !== 'NOT_CONTACTED' ?
                            <Radio key="1" value="NOT_CONTACTED" style={{ marginLeft: 0 }}>Not Contacted</Radio>
                          : null
                        }
                        {
                          valuesBulk?.crmStatus !== 'CONTACTED' ?
                            <Radio key="2" value="CONTACTED" style={{ marginLeft: 0 }}>Contacted</Radio>
                          : null
        
                        }
                        {
                          valuesBulk?.crmStatus !== 'REJECTED' ?
                            <Radio key="3" value="REJECTED" style={{ marginLeft: 0 }}>Rejected</Radio>
                          : null
                        }
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                : null
              }
              <Col span={24} style={{ marginTop: 8 }}>
                <Space style={{ float: 'right' }}>
                  <CButton type="default" action={this.handleCancel} title="Cancel"/>
                  <CButton type="primary" htmlType='submit' title="Next"/>
                </Space>
              </Col>
            </Row>
          </Form>
        </Modal>
      </React.Fragment>
    )
  }
  
  componentWillUnmount() {
    const { unmountGetData, unmountMasterEducationLevel } = this.props
    unmountGetData()
    unmountMasterEducationLevel()
  }
}

const mapStateToProps = (state) => ({
  getData : state.newCandidate.list,
  getEducationLevel : state.educationLevel.master
})

const mapDispatchToProps = {
  actionGetData               : listNewCandidate,
  actionGetEducationLevel     : masterEducationLevel,
  actionCreateNotes           : createNotes,
  actionUpdateStatus          : updateStatusNewCandidate,
  actionAddCounseling         : addCounselingProcess,
  actionExportData            : exportData,
  actionUpdateStatusByFilter  : updateStatusByFilter,
  actionExportDataByFilter    : exportDataByFilter,
  actionAddCounselingByFilter : addCounselingProcessByFilter,
  unmountGetData              : unmountListNewCandidate,
  unmountMasterEducationLevel : unmountMasterEducationLevel,
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCandidateList)
